import React, { useState, useEffect, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  company_common_name: "",
  virtual_account: "",
  va_ifsc: "",
  action: "",
  remitter_accounts: [
    {
      remitter_account_number: "",
      remitter_ifsc_code: "",
    },
  ],
  remitter_codes: [
    {
      remitter_code:""
    }
  ]
};

const { accountNumberRegex, ifscRegex } = REGEXP;
const validationSchema = Yup.object({
  company_common_name: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  virtual_account: Yup.string().matches(accountNumberRegex).required("Account Number is required"),
  va_ifsc: Yup.string().matches(ifscRegex).required("IFSC Code is required"),
  action: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  remitter_accounts: Yup.array().when('action', {
    is: (action) => action?.label === "ADD",
    then: () => Yup.array().of(
      Yup.object().shape({
        remitter_account_number: Yup.string()
          .required("Account number is required")
          .matches(accountNumberRegex),
        remitter_ifsc_code: Yup.string()
          .required("IFSC code is required")
          .matches(ifscRegex),
      })
    ).min(1).required(),
    otherwise: () => Yup.array().notRequired(),
  }),
  remitter_codes : Yup.array().when('action', {
    is: (action) => action?.label === "DISABLE",
    then: () => Yup.array().of(
      Yup.object().shape({
        remitter_code: Yup.string().required("Remitter Code is required")
      })
    ).min(1).required(),
    otherwise: () => Yup.array().notRequired()
  })
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleRemitterWhitelist = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);
  
  const handleClick = ()=>{
    setShowResponseDetails(false);
    setResponseDetails({})
 }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        action: values.action.label,
        company_id: values.company_common_name.value,
        virtual_account: values.virtual_account,
        va_ifsc: values.va_ifsc
      };

      if (values.action.label === "ADD") {
        payloadData["configure_remitter_accounts"] = values.remitter_accounts;
      }

      if (values.action.label === "DISABLE") {
        payloadData["configure_remitter_accounts"] = values.remitter_codes;
      }

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.REMITTER_WHITELIST.baseUrl +
          apiEndpointList.REMITTER_WHITELIST.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);         
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);        
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
  }, []);

  useEffect(() => {
    if (formik.values.action?.label === "ADD") {
      formik.setFieldValue("remitter_accounts", [
        { remitter_account_number: "", remitter_ifsc_code: "" },
      ]);
      formik.setFieldTouched("remitter_accounts", false, false);
    }
    if (formik.values.action?.label === "DISABLE") {
      formik.setFieldValue("remitter_codes", [{ remitter_code: "" }]);
      formik.setFieldTouched("remitter_codes", false, false);
    }
  }, [formik.values.action?.label]);

  const addAccount = () => {
    formik.setFieldValue("remitter_accounts", [
      ...formik.values.remitter_accounts,
      { remitter_account_number: "", remitter_ifsc_code: "" },
    ]);
  };

  const removeAccount = (index) => {
    const newAccounts = [...formik.values.remitter_accounts];
    newAccounts.splice(index, 1);
    formik.setFieldValue("remitter_accounts", newAccounts);
    formik.setFieldTouched(`remitter_accounts.${index}`, false, false)
    formik.setFieldTouched(`remitter_accounts.${index}.remitter_account_number`, false, false);
    formik.setFieldTouched(`remitter_accounts.${index}.remitter_ifsc_code`, false, false);
  };

  const addRemitterCode = () => {
    formik.setFieldValue("remitter_codes", [
      ...formik.values.remitter_codes,
      { remitter_code: "" },
    ]);
  };

  const removeRemitterCode = (index) => {
    const newRemitterCodes = [...formik.values.remitter_codes];
    newRemitterCodes.splice(index, 1);
    formik.setFieldValue("remitter_codes", newRemitterCodes);
    formik.setFieldTouched(`remitter_codes.${index}`, false, false)
    formik.setFieldTouched(`remitter_codes.${index}.remitter_code`, false, false);
  };

  const actions = [
    { value: 1, label: "ADD" },
    { value: 2, label: "DISABLE" },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Fill account details to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="virtual_account"
                name="virtual_account"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.virtual_account}
                touched={formik.touched.virtual_account}
                error={formik.errors.virtual_account}
                placeholder="Enter Virtual Account Number"
                label="Virtual Account Number"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="va_ifsc"
                name="va_ifsc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.va_ifsc}
                touched={formik.touched.va_ifsc}
                error={formik.errors.va_ifsc}
                placeholder="Enter IFSC Code"
                label="Virtual Account IFSC Code"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_common_name"
                name="company_common_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_common_name", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_common_name", true)
                }
                value={formik.values.company_common_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company Common Name"
                required={true}
                isLoading={isLoading}
                placeholder="Select Common Name"
                isformatOptionLabel={true}
              />
              <TextFieldSelect
                id="action"
                name="action"
                onChange={(selectedOption) =>
                  formik.setFieldValue("action", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("action", true)}
                value={formik.values.action}
                options={actions}
                noOptionsMessage={() => "No such action exists"}
                label="Action"
                required={true}
                placeholder="Select Action"
              />
            </div>
            <div className="divider"></div>
            {formik.values.action && <div>Remitter Account Details</div>}
            {formik.values.action?.label === "ADD" &&
              formik.values.remitter_accounts.length > 0 &&
              formik.values.remitter_accounts.map((account, index) => (
                <div className="ui-form-inputs-section" key={index}>
                  <TextFieldInput
                    id={`remitter_accounts.${index}.remitter_account_number`}
                    name={`remitter_accounts.${index}.remitter_account_number`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={account.remitter_account_number}
                    touched={
                      formik.touched.remitter_accounts &&
                      formik.touched.remitter_accounts[index] &&
                      formik.touched.remitter_accounts[index]
                        .remitter_account_number
                    }
                    error={
                      formik.errors.remitter_accounts &&
                      formik.errors.remitter_accounts[index] &&
                      formik.errors.remitter_accounts[index]
                        .remitter_account_number
                    }
                    placeholder="Enter Account Number"
                    label="Account Number"
                    required={true}
                    disabled={false}
                  />
                  <TextFieldInput
                    id={`remitter_accounts.${index}.remitter_ifsc_code`}
                    name={`remitter_accounts.${index}.remitter_ifsc_code`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={account.remitter_ifsc_code}
                    touched={
                      formik.touched.remitter_accounts &&
                      formik.touched.remitter_accounts[index] &&
                      formik.touched.remitter_accounts[index].remitter_ifsc_code
                    }
                    error={
                      formik.errors.remitter_accounts &&
                      formik.errors.remitter_accounts[index] &&
                      formik.errors.remitter_accounts[index].remitter_ifsc_code
                    }
                    placeholder="Enter IFSC Code"
                    label="Account IFSC Code"
                    required={true}
                    disabled={false}
                  />
                  <div className="ui-remove-button-container">
                    <button type="button" onClick={() => removeAccount(index)}>
                      <img src="/images/close.svg" alt="remove" />
                    </button>
                  </div>
                </div>
              ))}
            {formik.values.action?.label === "DISABLE" &&
              formik.values.remitter_codes.length > 0 &&
              formik.values.remitter_codes.map((code, index) => (
                <div className="ui-form-inputs-section" key={index}>
                  <TextFieldInput
                    id={`remitter_codes.${index}.remitter_code`}
                    name={`remitter_codes.${index}.remitter_code`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={code.remitter_code}
                    touched={
                      formik.touched.remitter_codes &&
                      formik.touched.remitter_codes[index] &&
                      formik.touched.remitter_codes[index].remitter_code
                    }
                    error={
                      formik.errors.remitter_codes &&
                      formik.errors.remitter_codes[index] &&
                      formik.errors.remitter_codes[index].remitter_code
                    }
                    placeholder="Enter Remitter Code"
                    label="Remitter Code"
                    required={true}
                    disabled={false}
                  />
                  <div className="ui-remove-button-container">
                    <button
                      type="button"
                      onClick={() => removeRemitterCode(index)}
                    >
                      <img src="/images/close.svg" alt="remove" />
                    </button>
                  </div>
                </div>
              ))}
            {formik.values.action && (
              <div className="ui-add-another-button-container">
                <button
                  type="button"
                  onClick={
                    formik.values.action?.label === "ADD"
                      ? addAccount
                      : addRemitterCode
                  }
                >
                  {formik.values.action?.label === "ADD"
                    ? "Add Account"
                    : "Add Remitter Code"}
                </button>
              </div>
            )}
            <div className="account-details-submit ui-button-container">
              <button
                className={`account-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default SingleRemitterWhitelist;
