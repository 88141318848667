import React from "react";
import "./PlanMapping.scss";
import "./PlanMapping.scss";
import { APIConfig } from "../../services/apiConfiguration";
import axios from "axios";
import APIEndpointList from "../../config/modules/customer_management/endpoint";
import { randomUUID } from "../../services/randomUUID";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
function PlanMapping(props) {
  const apiMeterInitialData = props.apiMeterData.map((item, idx) => {
    return {
      ...item,
      maxUsableCredits: "",
      renewalThresholdPercentage: "90",
      planType: "PREPAID_CREDITS",
      planPricingType: "STARTER",
      taxRate: "18",
    };
  });

  const [apiMeterInputData, setApiMeterInputData] =
    React.useState(apiMeterInitialData);
  const [btnVerify, setBtnVerify] = React.useState(1);

  React.useEffect(() => {
    // console.log(apiMeterInputData);
    for (let i = 0; i < apiMeterInputData.length; i++) {
      if (
        apiMeterInputData[i].renewalThresholdPercentage !== "" &&
        apiMeterInputData[i].planPricingType !== "" &&
        apiMeterInputData[i].taxRate !== "" &&
        apiMeterInputData[i].maxUsableCredits !== "" &&
        apiMeterInputData[i].planType !== ""
      ) {
        setBtnVerify(0);
      } else {
        setBtnVerify(1);
      }
    }
  }, [apiMeterInputData]);

  //^ MVA configure API call
  const configureMVA = (commonCompanyId) => {
    // & MVA hardcoded payload data

    // This payload data need to be configured as per the environment
    let payloadData = {
      // Staging Axis Nodal Account Details
      company_id: commonCompanyId,
      provider_code: "yesb",
      ifsc: "YESB0CMSNOC",
      // account_number: "12345678911",
      account_number: "016190100002195",
      designated_prefix: "462510",
    };

    APIConfig.API_Client.defaults.headers.post["provider_code"] =
      payloadData.provider_code;

    APIConfig.API_Client.post(
      apiEndpointList.CONFIGURE_MVA.baseUrl +
        apiEndpointList.CONFIGURE_MVA.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.CONFIGURE_MVA,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        // ^ setting response data to parent component
        props.setMvaResponseDetails({
          ...response.data,
          mva_account_number: payloadData.account_number,
        });

        // & condition to render va
        Boolean(+process.env.REACT_APP_TRIGGER_CVA_BOOL)
          ? createVirtualAccount()
          : addDummyMoney(response.data.data.commissionDebitAccountNumber);
      })
      .catch((error) => {
        // & move to summary screen
        props.companyDetailsSummary();
      });
  };

  let newMobileNumber = props.companyDetails.mobileNumber;

  let lastDigit = (
    (+newMobileNumber.slice(newMobileNumber.length - 1) + 1) %
    10
  ).toString();
  let beforeDigits = newMobileNumber.slice(0, newMobileNumber.length - 1);
  let mobileResult = beforeDigits + lastDigit;

  const createVirtualAccount = () => {
    let payloadData = {
      bank_codes: ["YESB"],
      name: props.companyDetails.commonName,
      pan: "ETEPM2976W",
      email: props.companyDetails.emailId,
      mobile: mobileResult,
      address: "Bengaluru",
      kyc_verified: 1,
      kyc_check_decentro: 1,
      minimum_balance: 0,
      transaction_limit: 9999999,
      customer_id:
        "decentro_treasure_account_" + props.companySecretInputData.client_id,
      upi_onboarding: 0,
      master_account: {
        number: "016190100002195",
        // number: "016190100002195",
        ifsc: "YESB0CMSNOC",
        // ifsc: "YESB0CMSNOC"
      },
    };
    let vaDetailsResponse;

    // ^ CUSTOM API CALL
    let API_CVA_CLIENT = axios.create();
    API_CVA_CLIENT.post(
      apiEndpointList.CREATE_VA.baseUrl + apiEndpointList.CREATE_VA.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.CREATE_VA.endpoint,
      payloadData,
      {
        headers: {
          "Content-Type": "application/json",
          client_id: props.companySecretInputData.client_id,
          client_secret: props.companySecretInputData.client_secret,
          module_secret:
            document.getElementById("customerHiddenData").dataset
              .coreBankingModuleSecret,
        },
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        vaDetailsResponse = response.data.data[0].accountNumber;
        addDummyMoney(vaDetailsResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addDummyMoney = (beneficiaryAccountNumber) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    const currentTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    let payloadData = {
      otp: "",
      transfer_type: "IMPS",
      remitter_name: "Decentro",
      remitter_account_number: "475639467953650794",
      remitter_ifsc: "UTIB0CCH274",
      bank_reference_number: randomUUID(),
      beneficiary_account_number: beneficiaryAccountNumber,
      beneficiary_provider_code: "yesb",
      remitter_provider_code: "yesb",
      decentro_api: "CORE_BANKING_MONEY_TRANSFER_AXIS_E_COLLECT_NOTIFY",
      amount: 9999999,
      purpose_message: "Credit",
      transaction_timestamp: currentTimestamp,
    };

    // & setting the beneficiary Account Number
    document.getElementById(
      "customerHiddenData"
    ).dataset.beneficiaryAccountNumberDummyMoney = beneficiaryAccountNumber;

    APIConfig.API_Client.post(
      apiEndpointList.ADD_DUMMY_MONEY.baseUrl +
        apiEndpointList.ADD_DUMMY_MONEY.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.ADD_DUMMY_MONEY.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        // & move to summary screen
        props.companyDetailsSummary();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitApiMeterDetails = () => {
    const apiMeterObj = apiMeterInputData.map((el, index) => {
      return {
        module_code: el.moduleCode,
        max_usable_credits: +el.maxUsableCredits,
        renewal_threshold_percentage: +el.renewalThresholdPercentage,
        plan_type: el.planType,
        plan_pricing_type: el.planPricingType,
        tax_rate: +el.taxRate,
      };
    });
    props.addApiMeterDetails(apiMeterObj);
    document.getElementById("customerHiddenData").dataset.planMapping =
      JSON.stringify(apiMeterObj);
    // ^ Mva creation onSubmit of planMapping
    // Only Create MVA if either the Core Banking or Payments modules are present else donot create
    // !!props.commonCompanyId && configureMVA(props.commonCompanyId);
    let tempModulesArray = [];
    for (let i = 0; i < apiMeterObj.length; i++) {
      tempModulesArray.push(apiMeterObj[i].module_code);
    }
    if (
      !!props.commonCompanyId &&
      (tempModulesArray.includes("core_banking") ||
        tempModulesArray.includes("payments"))
    ) {
      configureMVA(props.commonCompanyId);
    } else {
      props.companyDetailsSummary();
    }
  };
  return (
    <React.Fragment>
      <div className="api-meter-form">
        <h2>Select plan for each module</h2>
        <div className="form-details">
          <div className="company-details row">
            <div className="label_main">Company</div>
            <div className="label_main_value company-name">
              {props.companyDetails.fullName}
            </div>
          </div>
          <div className="available-plans">
            <div className="form-content">
              <div className="api-meter-details">
                {apiMeterInputData.map((data, index) => {
                  return (
                    <div className="api-meter-details-container">
                      <div className="api-meter-details-heading">
                        <p>{data.moduleName}</p>
                      </div>
                      <div className="api-meter-details-box">
                        <div className="input-container">
                          <div className="form-field_select">
                            <div className="select">
                              <select
                                onChange={({ target }) => {
                                  data.planType = target.value;
                                  setApiMeterInputData([...apiMeterInputData]);
                                }}
                                className="select-text"
                                required
                                disabled
                              >
                                <option defaultValue hidden>
                                  Select plan..
                                </option>
                                {props.planOptions.map((elem, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={elem}
                                      selected={index == 1 ? true : false}
                                    >
                                      {elem}
                                    </option>
                                  );
                                })}
                              </select>
                              <label className="select-label">
                                Plan <span className="required-field">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="form-field">
                            <div className="form-field__control">
                              <input
                                id={`threshold-${index}`}
                                type="number"
                                step=".01"
                                className="form-field__input"
                                placeholder=" "
                                autoComplete="off"
                                onChange={(e) => {
                                  if (
                                    (parseInt(e.target.value) > 0 &&
                                      parseInt(e.target.value) <= 100) ||
                                    e.target.value === ""
                                  ) {
                                    data.renewalThresholdPercentage =
                                      e.target.value;
                                    setApiMeterInputData([
                                      ...apiMeterInputData,
                                    ]);
                                  } else {
                                    e.preventDefault();
                                    e.target.value = "";
                                  }
                                }}
                                value={data.renewalThresholdPercentage}
                                required
                                spellCheck="false"
                              />
                              <label
                                className="form-field__label"
                                htmlFor={`threshold-${index}`}
                              >
                                Threshold(%){" "}
                                <span className="required-field">*</span>
                              </label>
                            </div>
                          </div>

                          <div className="form-field">
                            <div className="form-field__control">
                              <input
                                id={`credits-${index}`}
                                type="number"
                                step=".01"
                                className="form-field__input"
                                placeholder=" "
                                autoComplete="off"
                                onChange={(e) => {
                                  if (parseInt(e.target.value) < 0) {
                                    e.preventDefault();
                                    e.target.value = "";
                                  }
                                  data.maxUsableCredits = e.target.value;
                                  setApiMeterInputData([...apiMeterInputData]);
                                }}
                                value={data.maxUsableCredits}
                                required
                                spellCheck="false"
                              />
                              <label
                                className="form-field__label"
                                htmlFor={`credits-${index}`}
                              >
                                Credits{" "}
                                <span className="required-field">*</span>
                              </label>
                            </div>
                          </div>

                          {/* <div className="form-field">
                                                            <div className="form-field__control">
                                                                <input
                                                                    type="text"
                                                                    className="form-field__input"
                                                                    placeholder=" "
                                                                    autoComplete="off"
                                                                    id={`tax-${index}`}
                                                                    onChange={(e) => {
                                                                        data.taxRate = e.target.value;
                                                                        setApiMeterInputData([...apiMeterInputData]);
                                                                    }}
                                                                    value={data.taxRate}
                                                                    required
                                                                    spellCheck="false"
                                                                />
                                                                <label className="form-field__label" htmlFor={`tax-${index}`}>
                                                                    Tax
                                                                </label>
                                                            </div>
                                                        </div> */}
                        </div>
                        <div className="dropdown-container">
                          {/* <div className="form-field_select">
                                                            <div className="select">
                                                                <select
                                                                    onChange={({ target }) => {
                                                                        data.planType = target.value;
                                                                        setApiMeterInputData([...apiMeterInputData]);
                                                                    }}
                                                                    className="select-text"
                                                                    required
                                                                >
                                                                    <option defaultValue hidden>
                                                                        Select plan..
                                                                    </option>
                                                                    {props.planOptions.map((elem, index) => {
                                                                        return (
                                                                            <option key={index} value={elem}>
                                                                                {elem}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <label className="select-label">Plan <span className="required-field">*</span></label>
                                                            </div>
                                                        </div> */}

                          {/*Pricing dropdown */}
                          <div className="form-field_select">
                            <div className="select">
                              <select
                                onChange={({ target }) => {
                                  data.planPricingType = target.value;
                                  setApiMeterInputData([...apiMeterInputData]);
                                }}
                                className="select-text"
                                required
                                disabled
                              >
                                <option defaultValue hidden>
                                  Select pricing..
                                </option>
                                {props.planPricing.map((elem, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={elem}
                                      selected={index == 0 ? true : false}
                                    >
                                      {elem}
                                    </option>
                                  );
                                })}
                              </select>
                              <label className="select-label">
                                Pricing{" "}
                                <span className="required-field">*</span>
                              </label>
                            </div>
                          </div>
                          <div className="form-field">
                            <div className="form-field__control">
                              <input
                                type="number"
                                step=".01"
                                className="form-field__input"
                                placeholder=" "
                                autoComplete="off"
                                id={`tax-${index}`}
                                onChange={(e) => {
                                  if (parseInt(e.target.value) < 0) {
                                    e.preventDefault();
                                    e.target.value = "";
                                  }
                                  data.taxRate = e.target.value;
                                  setApiMeterInputData([...apiMeterInputData]);
                                }}
                                value={data.taxRate}
                                required
                                spellCheck="false"
                              />
                              <label
                                className="form-field__label"
                                htmlFor={`tax-${index}`}
                              >
                                Tax <span className="required-field">*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="company-details-submit button-container">
          <button
            className={`company-details-submit ${
              btnVerify === 1 ? "" : "active"
            }`}
            // disabled={btnVerify > 0 && true}
            disabled={false}
            onClick={submitApiMeterDetails}
          >
            Save & Continue
            <span id="apiMeter-customer-loader"></span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PlanMapping;
