import React, { useState } from "react";
import ReactDOM from "react-dom";
// api config imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
// component imports
import CompanySearchMva from "../CompanySearchMva/CompanySearchMva";
import MvaProviderDropdown from "../MvaProviderDropdown/MvaProviderDropdown";
import ConfigureMvaSuccessDailog from "../ConfigureMvaSuccessDailog/ConfigureMvaSuccessDailog";
import FailureDialog from "../../Shared/FailureDialog/FailureDialog";
import Loader from "../../Loader/Loader";
// validations imports
import {
  accountNumberValidation,
  ifscValidation,
} from "../../../utilities/validators/inputValidators";
// styles imports
import "./ConfigureMvaDetails.scss";
// Utils
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import { randomUUID } from "../../../services/randomUUID";
import { Modal } from "./Modal/Modal";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const ConfigureMvaDetails = () => {
  //^ Input field state's
  const [provider, setProvider] = React.useState("");
  const [getCompanyID, setGetCompanyId] = React.useState("");
  const [resetInputFields, setResetInputFields] = useState(false);

  const [customerDetails, setCustomerDetails] = React.useState({
    accountNumberInput: "",
    ifscInput: "",
    prefixInput: "",
  });

  let [inputData, setInputData] = React.useState({
    account_number: "",
    ifsc_code: "",
    prefix_code: "",
  });

  const [inputValidation, setInputValidation] = React.useState({
    accountNumberError: "",
    ifscCodeError: "",
  });

  //^ Input field on Change Handler
  const handleContactDetails = (e) => {
    let { name, value } = e.target;

    if (name === "account_number") {
      setCustomerDetails({ ...customerDetails, accountNumberInput: value });

      if (accountNumberValidation(value)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, accountNumberError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          accountNumberError: "Invalid format",
        });
      }
    }

    if (name === "ifsc_code") {
      setCustomerDetails({ ...customerDetails, ifscInput: value });

      if (ifscValidation(value)) {
        setInputData({ ...inputData, [name]: value });
        setInputValidation({ ...inputValidation, ifscCodeError: "" });
      } else {
        setInputData({ ...inputData, [name]: "" });
        setInputValidation({
          ...inputValidation,
          ifscCodeError: "Invalid format",
        });
      }
    }

    if (name === "prefix_code") {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const resetInputs = () => {
    //^ Reset input fields
    setProvider("");
    setCustomerDetails({
      accountNumberInput: "",
      ifscInput: "",
      prefixInput: "",
    });
    setInputData({
      account_number: "",
      ifsc_code: "",
      prefix_code: "",
    });
    setInputValidation({
      accountNumberError: "",
      ifscCodeError: "",
    });

    setResetInputFields(!resetInputFields);
  };

  //^ MVA configure API call
  const customerConfigAPI = (payloadData) => {
    ReactDOM.render(
      <Loader />,
      document.getElementById("customer-config-loader")
    );

    APIConfig.API_Client.defaults.headers.post["provider_code"] =
      payloadData.provider_code;

    APIConfig.API_Client.post(
      apiEndpointList.CONFIGURE_MVA.baseUrl +
        apiEndpointList.CONFIGURE_MVA.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.CONFIGURE_MVA,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("customer-config-loader")
        );

        ReactDOM.render(
          <ConfigureMvaSuccessDailog
            accountNumber={payloadData.account_number}
            data={response.data}
          />,
          document.getElementById("add-customer-components")
        );

        resetInputs();
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("customer-config-loader")
        );

        ReactDOM.render(
          <FailureDialog
            header={"Configure MVA"}
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("failure-popup")
        );
      });
  };

  //^ On submit company detail
  const submitAccountDetails = () => {
    let companyConfigDetail = {
      company_id: getCompanyID,
      provider_code: provider,
      ifsc: inputData.ifsc_code,
      account_number: inputData.account_number,
      designated_prefix: inputData.prefix_code,
    };
    customerConfigAPI(companyConfigDetail);
  };

  //^ Button validation
  const [buttonValidation, setButtonValidation] = React.useState(true);

  //^ Validation side effect
  React.useEffect(() => {
    if (
      inputData.account_number &&
      inputData.ifsc_code &&
      getCompanyID &&
      provider &&
      inputData.prefix_code
    ) {
      setButtonValidation(false);
    } else {
      setButtonValidation(true);
    }
  }, [inputData, provider, getCompanyID]);

  //manage modal state
  const [openModal, setOpenModal] = useState(false);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = () => {
    const companyInput = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      companyInput
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: companyInput.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        setOpenModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="configure-mva-details">
        <div className="configure-mva-divider"></div>

        <CompanySearchMva
          setGetCompanyId={setGetCompanyId}
          resetInputFields={resetInputFields}
        />

        <div className="configure-mva-divider"></div>

        <div className="configure-mva-input-container">
          <div className="input-wrapper">
            <div className="input-group__upper">
              <MvaProviderDropdown
                setProvider={setProvider}
                resetInputFields={resetInputFields}
              />
              <div className="text-input-wrapper">
                <div className="label-wrapper">
                  <div className="text-input-label">Account</div>
                </div>
                <div
                  className={`add-configure-mva-input ${
                    !!inputValidation.accountNumberError && "error-border"
                  }`}
                >
                  <input
                    id="va-account-number"
                    name="account_number"
                    type="text"
                    placeholder="Enter Account number"
                    value={customerDetails.accountNumberInput}
                    onChange={handleContactDetails}
                    className={`configure-mva-field-input`}
                  />
                </div>
              </div>
            </div>

            <div className="input-group__lower">
              <div className="text-input-wrapper">
                <div className="label-wrapper">
                  <div className="text-input-label">IFSC</div>
                </div>
                <div
                  className={`add-configure-mva-input ${
                    !!inputValidation.ifscCodeError && "error-border"
                  }  `}
                >
                  <input
                    id="ifsc-number"
                    name="ifsc_code"
                    type="text"
                    placeholder="Enter IFSC"
                    value={customerDetails.ifscInput}
                    onChange={handleContactDetails}
                    className="configure-mva-field-input"
                  />
                </div>
              </div>

              <div className="text-input-wrapper">
                <div className="text-input-label">Designated prefix</div>
                <div className={`add-configure-mva-input`}>
                  <input
                    id="prefix-number"
                    name="prefix_code"
                    type="text"
                    placeholder="Enter designated prefix"
                    value={inputData.prefix_code}
                    maxLength="6"
                    onChange={handleContactDetails}
                    className="configure-mva-field-input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="button-wrapper">
            <button
              className={`submit-configure-mva-details ${
                !buttonValidation && !isLoading && "active-configure-mva-btn"
              }`}
              disabled={buttonValidation || isLoading}
              onClick={submitHandler}
              type="button"
            >
              {isLoading ? "Loading" : "Submit"}
              <span id="customer-config-loader"></span>
            </button>
          </div>
        </div>
      </div>
      {openModal ? (
        <Modal
          setOpenModal={setOpenModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitAccountDetails={submitAccountDetails}
        />
      ) : null}
    </>
  );
};

export default ConfigureMvaDetails;
