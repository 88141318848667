import React from "react";

//styles-import
import "./ResponseDetails.scss";
import CopyButton from "../../../UI/CopyButton/CopyButton";

//components-import

const ResponseDetails = ({ data, onClick }) => {

  const status =
    data.message === "User updated" || "User registered"
      ? "success"
      : "failure";
  const imgSrc =
    status === "failure"
      ? "/images/onfailure-error.svg"
      : "/images/onsuccess-tick.svg";

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${status}-status-icon`}
            alt="status-icon"
          />
          {status === "failure" ? "Failure" : "Success"} Response Details
          <img
            src="/images/close.svg"
            className="close-response-details"
            alt="close"
            onClick={onClick}
          />
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          {status === "success" ? (
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Company ID: </span>
                <span className="value">
                  {data.renderData.company_id || "-"}
                </span>
                {data.renderData.company_id && (
                  <CopyButton text={data.renderData.company_id || "-"} />
                )}
              </li>
              <li className="response-list-item">
                <span className="field">User Name: </span>
                <span className="value">{data.renderData.name || "-"}</span>
                {data.renderData.name && (
                  <CopyButton text={data.renderData.name || "-"} />
                )}
              </li>
              <li className="response-list-item">
                <span className="field">User Type: </span>
                <span className="value">{data.userType || "-"}</span>
                {data?.userType && <CopyButton text={data.userType || "-"} />}
              </li>
              <li className="response-list-item">
                <span className="field">Email: </span>
                <span className="value">{data.renderData.email || "-"}</span>
                {data.renderData.email && (
                  <CopyButton text={data.renderData.email || "-"} />
                )}
              </li>
              <li className="response-list-item">
                <span className="field">Mobile: </span>
                <span className="value">{data.renderData.mobile || "-"}</span>
                {data.renderData.mobile && (
                  <CopyButton text={data.renderData.mobile || "-"} />
                )}
              </li>
              <li className="response-list-item">
                <span className="field">Status: </span>
                <div
                  className={`value ${data.renderData.set_active == true
                      ? "status-active"
                      : "status-inactive"
                    }`}
                >
                  {data.renderData.set_active == true
                    ? "Active"
                    : "Inactive" || "-"}
                </div>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
