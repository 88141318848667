import React, { useContext, useState } from "react";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";

// component imports
import { TotpModal } from "../TotpModal/TotpModal";

//  context imports
import CallbackContext from "../../../../contexts/CallbackContext";

// utils imports
import { randomUUID } from "../../../../services/randomUUID";
import { APIConfig } from "../../../../services/apiConfiguration";

const DeleteCallback = ({ item, searchCompanyID }) => {
  // ^ context
  const { deleteCallbackApi } = useContext(CallbackContext);

  // & Delete callback Key
  const [deleteCallbackKey, setDeleteCallbackKey] = useState(null);

  //&  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  //   & TOTP Modal state
  const [showModal, setShowModal] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);

  //   & Open's UP Modal
  const openTotpModal = () => {
    setShowTotpModal(true);
  };

  // * DELETE callback handler
  const handleDeleteCallback = (callbackType) => {
    let payload = {
      reference_id: randomUUID(),
      company_id: searchCompanyID,
      callback_type: callbackType,
    };

    // delete's callback
    deleteCallbackApi(payload);
  };

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = (deleteKey) => {
    setDeleteCallbackKey(deleteKey);
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        openTotpModal();
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="delete-btn-wrapper">
      <button
        onClick={() => submitHandler(item.callbackType)}
        className="callback-delete-btn"
      >
        Delete
      </button>

      {/* TOTP modal */}
      {showTotpModal ? (
        <TotpModal
          setShowTotpModal={setShowTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          deleteKey={deleteCallbackKey}
          deleteCallback={handleDeleteCallback}
        />
      ) : null}
    </div>
  );
};

export default DeleteCallback;
