import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import OnboardProdContext from "../../../contexts/OnboardProdContext/OnboardProdContext";
import CompanyDetailsSummary from "./CompanyDetailsSummary";
import CompanySecretsSummary from "./CompanySecretsSummary";
import CreateUserSummary from "./CreateUserSummary";
import ModuleSecretSummary from "./ModuleSecretSummary";
import MVADetailsSummary from "./MVADetailsSummary";
import ProviderSecretSummary from "./ProviderSecretSummary";
import { APIConfig } from "../../../services/apiConfiguration";
import { randomUUID } from "../../../services/randomUUID";
import axios from "axios";

// styles import
import "./OnBoardSummaryProd.scss";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OnBoardSummaryProd = () => {
  // ^ context
  const { goToCompanyDetails, companyID } = useContext(OnboardProdContext);
  // & State
  const [disableSendCredentials, setDisableSendCredentials] = useState(false);

  //* Send Credentials API
  // const sendCredentialsAPI = () => {
  //   setDisableSendCredentials(true);

  //   APIConfig.API_Client.post(
  //     apiEndpointList.SEND_ONBOARDING_CREDENTIALS.baseUrl +
  //       apiEndpointList.SEND_ONBOARDING_CREDENTIALS.endpoint,
  //     {
  //       reference_id: randomUUID(),
  //       company_id: companyID,
  //     },
  //     { cancelToken: source.token }
  //   )
  //     .then((response) => {
  //       setDisableSendCredentials(true);

  //       ReactDOM.render(
  //         <SnackbarMessage
  //           msgtype="success"
  //           msg={response?.data?.message || "Credentials sent Successfully"}
  //         />,
  //         document.getElementById("snackbar")
  //       );
  //     })
  //     .catch((error) => {
  //       setDisableSendCredentials(false);

  //       // * snackbar for error
  //       ReactDOM.render(
  //         <SnackbarMessage
  //           msgtype="Error"
  //           msg={error?.response?.data?.message || "Something went wrong !"}
  //         />,
  //         document.getElementById("snackbar")
  //       );
  //     });
  // };

  return (
    <div className="onboard-summary-details">
      {/* Generate Credentials */}

      {/* <div className="summary-action-wrapper">
        <p className="summary-action-label">
          Company has been onboarded successfully.
        </p>
        <div className="summary-button-container">
          <button
            // className={`summary-btn-submit`}
            className={`formik-btn-submit ${
              !disableSendCredentials ? "active" : "disabled"
            }`}
            disabled={disableSendCredentials}
            onClick={sendCredentialsAPI}
          >
            {"Generate Credentials"}
          </button>
        </div>
      </div> */}

      {/* Generate Credentials ends */}
      <h2 className="form-heading">Review your company details</h2>
      <CompanyDetailsSummary />
      <CompanySecretsSummary />
      <ModuleSecretSummary />
      <ProviderSecretSummary />
      <MVADetailsSummary />
      <CreateUserSummary />

      <div className="button-container">
        <button className={`active`} onClick={goToCompanyDetails}>
          Done
        </button>
      </div>
    </div>
  );
};

export default OnBoardSummaryProd;
