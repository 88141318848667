export const moduleMapping = [
  { code: "kyc_and_onboarding", kyc_and_onboarding: "KYC and Onboarding" },
  { code: "core_banking", core_banking: "Accounts" },
  { code: "lending", lending: "Lending" },
  { code: "financial_services", financial_services: "Financial Services" },
  { code: "payments", payments: "Payments" },
  { code: "ppi_wallet", ppi_wallet: "Prepaid" },
  { code: "ppi_full_kyc", ppi_full_kyc: "PPI Full KYC" },
  { code: "aml_and_compliance", aml_and_compliance: "AML and Compliance" },
];
