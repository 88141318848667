import React, { useEffect, useState } from "react";
//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

// components import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementDetailsView = () => {
  //   & state
  const [settlementUrnOptions, setSettlementUrnOptions] = useState([""]);
  const [settlementViewDetails, setSettlementViewDetail] = useState([]);
  const [settlementList, setSettlementList] = useState([]);
  const [isURNLoading, setIsURNLoading] = useState(true);

  const [settlementCycleUrn, setSettlementCycleUrn] = useState({});
  const getSettlementCycleUrn = () => {
    setIsURNLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.SETTLEMENT_CYCLE_URN.baseUrl +
        apiEndpointList.SETTLEMENT_CYCLE_URN.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setSettlementViewDetail(response.data);

        setSettlementList(response.data);

        const options = response.data.map((item) => ({
          value: item.settlement_cycle_urn,
          label: item.name,
        }));

        // options.push({ label: "All", value: "all" });

        setSettlementUrnOptions(options);
        setIsURNLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setSettlementUrnOptions([]);
        setIsURNLoading(false);
      });
  };
  useEffect(() => {
    getSettlementCycleUrn();
  }, []);

  useEffect(() => {
    if (settlementCycleUrn) {
      const filterDetails = (arr, filterOption) => {
        return arr.filter((item) => item.name === filterOption);
      };

      setSettlementList(
        filterDetails(settlementViewDetails, settlementCycleUrn.label)
      );
    }
  }, [settlementCycleUrn]);

  return (
    <div className="ui-form-details">
      <h2 className="ui-form-title">Fill transaction details to continue</h2>

      <div className="ui-form-content">
        <div className="ui-form-inputs-section">
          {/* urn options */}
          <TextFieldSelect
            id="settlement_cycle_urn"
            name="settlement_cycle_urn"
            onChange={(selectedOption) => setSettlementCycleUrn(selectedOption)}
            options={settlementUrnOptions}
            noOptionsMessage={() => "No such cycle exists"}
            label="Settlement Cycle"
            required={true}
            isLoading={isURNLoading}
            placeholder="Select settlement cycle"
            isformatOptionLabel={false}
            isClearable={false}
          />
        </div>
      </div>

      {/* Details List */}

      {settlementList.map((details, index) => (
        <div className="response-details-container" key={index}>
          <h4 className="response-details-heading">
          {details?.name || "-"}
          </h4>
          <div className="response-details-divider"></div>
          <div className="response-details">
            <ul className="response-list">
              <li className="response-list-item">
                <span className="field">Settlement Cycle URN: </span>
                <span className="value">
                  {details?.settlement_cycle_urn || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Provider Code: </span>
                <span className="value">{details?.provider_code || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">
                  Underlying Account Consumer Account ID:{" "}
                </span>
                <span className="value">
                  {details?.underlying_account_consumer_bank_account_id || "-"}
                </span>
              </li>
              <li className="response-list-item">
                <span className="field">Description: </span>
                <span className="value">{details?.description || "-"}</span>
              </li>
              <li className="response-list-item">
                <span className="field">Company List: </span>
                <span className="value">
                  {details?.company_list?.join(",  ") || "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettlementDetailsView;
