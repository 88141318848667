import React, { useContext, useEffect, useState } from "react";
// api config imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// context imports
import CreditsActionContext from "../../../contexts/CreditsActionContext";
import CreditManagementContext from "../../../contexts/CreditManagementContext";
// component imports
import EditCreditTypeDropdown from "../EditCreditTypeDropdown/EditCreditTypeDropdown";
// styles imports
import "./ModulesList.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const ModulesList = ({
  submitEditCreditDetails,
  subscribedModules,
  setSubscribedModules,
}) => {
  // ^ credits context
  const { tableEditData } = useContext(CreditsActionContext);
  const { refreshModuleList, editType } = useContext(CreditManagementContext);

  const [isLoading, setIsLoading] = useState("Loader");
  const handleChange = (e, i) => {
    const { value, name } = e.target;

    const newState = [...subscribedModules];
    newState[i] = {
      ...newState[i],
      max_usable_credits: value,
      module_code: name,
      creditInputError: value.match(/^[1-9][0-9]{0,7}?(\.[0-9]{1,2})?$/)
        ? false
        : true,
    };
    setSubscribedModules(newState);
  };

  function getAvailableCreditsByCompany() {
    setIsLoading("Loader");
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_CREDITS_DETAILS.baseUrl +
        apiEndpointList.FETCH_CREDITS_DETAILS.endpoint,
      {
        company_id: tableEditData.id,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.length === 0) {
          setIsLoading("noData");
        } else {
          setSubscribedModules(
            response.data.map((module) => {
              return {
                ...module,
                max_usable_credits: "",
                module_code: "",
                creditInputError: false,
                operationFunction: "add",
              };
            })
          );
          setIsLoading("showModules");
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    getAvailableCreditsByCompany();
  }, [refreshModuleList]);

  if (isLoading === "Loader") {
    return (
      <div className="credits-loader-container">
        <img src="/images/loader.gif" alt="loader" />
      </div>
    );
  }

  if (isLoading === "noData") {
    return <h3 className="no-modules-heading">No Modules Found</h3>;
  }

  if (isLoading === "showModules") {
    return (
      <div className="modules-scrollable-container">
        <div className="edit-card-list-container">
          <h3 className="available-modules-heading">Available Modules</h3>
          {subscribedModules.map(
            (
              {
                displayName,
                moduleCode,
                availableCredits,
                max_usable_credits,
                creditInputError,
                module_code,
              },
              index
            ) => {
              return (
                <div key={index} className="edit-credit-card">
                  <h4 className="card-module-heading">{displayName}</h4>
                  <div className="card-divider"></div>

                  <div className="credit-details-container">
                    <div className="current-credits">
                      <p className="credits-label">Available Credits</p>
                      <p className="credits-value">{availableCredits}</p>
                    </div>
                    <div class="edit-credits-container">
                      <EditCreditTypeDropdown
                        moduleDisplayName={displayName}
                        subscribedModules={subscribedModules}
                        setSubscribedModules={setSubscribedModules}
                      />

                      <div className="edit-credits-input">
                        <div
                          className={`edit-input-container ${
                            !!creditInputError && "error-border"
                          }`}
                        >
                          <input
                            type="number"
                            placeholder="Enter credits"
                            name={moduleCode}
                            value={max_usable_credits}
                            className="credits-field-input"
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }
};

export default ModulesList;
