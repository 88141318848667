import React from "react";

//component-import
import UploadStatement from "./UploadStatement/UploadStatement";
import ComponentWrapper from "../../../UI/ComponentWrapper/ComponentWrapper";

const UploadProviderStatement = () => {
  return (
    <React.Fragment>
      <ComponentWrapper
        heading="Upload Provider Statement"
        component={<UploadStatement />}
      />
    </React.Fragment>
  );
};

export default UploadProviderStatement;
