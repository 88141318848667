import React from "react";
import ComponentWrapper from "../../../UI/ComponentWrapper/ComponentWrapper";
import ReportForm from "../ReportForm/ReportForm";

const GenerateReports = () => {
  return (
    <>
      <ComponentWrapper heading="Generate Reports" component={<ReportForm />} />
    </>
  );
};

export default GenerateReports;
