import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//component-import
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  company: [],
  master_consumer_bank_account_alias: "",
};

const validationSchema = Yup.object({
  company: Yup.array()
  .of(
    Yup.object().shape({
      value: Yup.number().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    })
  )
  .min(1)
  .required(),
  master_consumer_bank_account_alias: Yup.object().shape({
      value: Yup.number().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CompanyInvoke = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [cbaAliasList, setCBAAliasList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [isError, setIsError] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const extractCompanyIdValues = (arr) => {
        return arr.map((item) => item.value);
      };

      const payloadData = {
        reference_id: randomUUID(),
        batch_process_name: "AUTO_EXTERNAL_BALANCE_SETTLEMENT",
      };

    payloadData["batch_data"] = {
        provider_code:
        values.master_consumer_bank_account_alias.provider_code,
        company_ids: extractCompanyIdValues(values.company),
        master_consumer_bank_account_alias:
        values.master_consumer_bank_account_alias.label,
    };

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.INVOKE_SETTLEMENT_BATCH_JOB.baseUrl +
          apiEndpointList.INVOKE_SETTLEMENT_BATCH_JOB.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  const getMasterCBAAliasList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_MASTER_CBA_ALIAS.baseUrl +
        apiEndpointList.FETCH_MASTER_CBA_ALIAS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item, i) => ({
          value: i,
          label: item.alias,
          provider_code: item.Provider__four_character_bank_code,
        }));
        setCBAAliasList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        setCBAAliasList([]);
        setIsLoading(false);
        setIsError(true);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to fetch Master CBA Alias list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getMasterCBAAliasList();
  }, []);


  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
              <div className="ui-form-inputs-section">
                <TextFieldSelect
                  id="company"
                  name="company"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("company", selectedOption)
                  }
                  onBlur={() => formik.setFieldTouched("company", true)}
                  value={formik.values.company}
                  options={companyOptions}
                  noOptionsMessage={() => "No such company exists"}
                  label="Company"
                  required={true}
                  isLoading={isLoading}
                  isError={isError}
                  placeholder="Select Company(s)"
                  isformatOptionLabel={true}
                  isMulti={true}
                />
                <TextFieldSelect
                  id="master_consumer_bank_account_alias"
                  name="master_consumer_bank_account_alias"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "master_consumer_bank_account_alias",
                      selectedOption
                    )
                  }
                  onBlur={() =>
                    formik.setFieldTouched(
                      "master_consumer_bank_account_alias",
                      true
                    )
                  }
                  value={formik.values.master_consumer_bank_account_alias}
                  options={cbaAliasList}
                  noOptionsMessage={() => "No such alias exists"}
                  label="Master CBA Alias"
                  required={true}
                  isLoading={isLoading}
                  isError={isError}
                  placeholder="Select Master CBA Alias"
                  isformatOptionLabel={false}
                />
            </div>
            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default CompanyInvoke;
