import React from "react";

//styles-import
import "./ResponseDetails.scss";

//components-import
import convertKeysToCamelCase from "../../utilities/convertKeysToCamelCase";
import CopyButton from "../CopyButton/CopyButton";


const ResponseDetails = ({ data, onClick }) => {
  const details = convertKeysToCamelCase(data);
  const decentroTransactionId = details.decentroTxnId;
  const message = details?.error?.message || details.message;
  const status = (details?.error?.status || details.status).toLowerCase();
  const imgSrc =
    status === "failure"
      ? "/images/onfailure-error.svg"
      : "/images/onsuccess-tick.svg";

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${status}-status-icon`}
            alt="status-icon"
          />
          {status === "failure" ? "Failure" : "Success"} Response Details
          <img
            src="/images/close.svg"
            className="close-response-details"
            alt="close"
            onClick={onClick}
          />
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          <ul className="response-list">
            <li className="response-list-item">
              <span className="field">Decentro Transaction ID: </span>
              <span className="value">{decentroTransactionId || "-"}</span>
              {decentroTransactionId && <CopyButton text={decentroTransactionId || "-"} />}
            </li>
            <li className="response-list-item">
              <span className="field">Message: </span>
              <span className="value">{message || "-"}</span>
              {message && <CopyButton text={message || "-"} />}
            </li>
            <li className="response-list-item">
              <span className="field">Status: </span>
              <span className="value">{status.toUpperCase() || "-"}</span>
              <img src={imgSrc} className="status-icon" alt="status-icon" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
