import React, { useEffect } from "react";
// component imports
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
// context imports
// api config imports
import { APIConfig } from "../../../services/apiConfiguration";
// styles imports
import "./CompanySearchMva.scss";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

const CompanySearchMva = ({ setGetCompanyId, resetInputFields }) => {
  // All Company details
  const [companyDetailList, setCompanyDetailList] = React.useState("");
  // Search Handles
  const [filteredData, setFilteredData] = React.useState([]);
  const [wordEntered, setWordEntered] = React.useState("");
  const [idSelected, setIdSelected] = React.useState("");
  const [input, setInput] = React.useState("open");

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getCompanyDetail = () => {
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl + apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint, {
      cancelToken: source.token,
    })
      .then((response) => {
        setCompanyDetailList(response.data.data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getCompanyDetail();
  }, []);

  // ^ onsubmit clear search
  useEffect(() => {
    clearInput();
  }, [resetInputFields]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    setInput("open");
    const newFilter = companyDetailList.filter((value) => {
      return value.commonName.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    setIdSelected("");
    setGetCompanyId("");
  };

  const idDataHandler = (data) => {
    setWordEntered(data.commonName);
    setIdSelected(data.companyId);
    setGetCompanyId(data.companyId);
    setInput("close");
  };
  if (!companyDetailList) {
    return <p>Loading...</p>;
  }

  return (
    <div className="configure-mva-field">
      <div className="configure-mva-input-label">Company Name</div>
      <div
        className={`searchInputs ${filteredData.length !== 0 && input === "open" && "change-border"
          }`}
      >
        <input
          type="text"
          placeholder="Enter company name"
          value={wordEntered}
          className="configure-mva-input"
          onChange={handleFilter}
        />
        <p className="company-select-id">{idSelected}</p>
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon id="searchBtn" />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && input === "open" && (
        <div className="dataResult">
          {filteredData.map((value) => {
            return (
              <div
                key={value.companyId}
                onClick={() => idDataHandler(value)}
                className="dataItem"
              >
                <p className="company-name">{value.commonName}</p>
                <p className="company-id">{value.companyId}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CompanySearchMva;
