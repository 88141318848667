// Bank providers for common env
const accountBalanceData = [
  {
    bank: "Yes Bank",
    accountType: "Current Account",
    account: "016190100002195",
    provider: "yesb",
  },
  // {
  //   bank: "Axis Bank",
  //   accountType: "Common Escrow",  
  //   account: "922020047762308",
  //   provider: "utib",
  // },
  // {
  //   bank: "Axis Bank",
  //   accountType: "Nodal Account",
  //   account: "921020054939507",
  //   provider: "utib",
  // },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Lender Escrow Account",
  //   account: "",
  //   provider: "yesb",
  // },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Vendor Account",
  //   account: "",
  //   provider: "yesb",
  // },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Nodal Account",
  //   account: "",
  //   provider: "yesb",
  // },
];

export default accountBalanceData;
