import React from "react";
import TabComponentWrapper from "../../UI/TabComponentWrapper/TabComponentWrapper";
import SettlementForm from "./SettlementForm";
import SettlementDetailsView from "./SettlementDetailsView";

const Settlement = () => {
  return (
    <>
      <TabComponentWrapper
        heading="Settlement Cycle"
        tabs={[
          {
            label: "Settlement View",
            value: "bulk",
            component: <SettlementDetailsView />,
          },
          {
            label: "Settlement config",
            value: "single",
            component: <SettlementForm />,
          },
        ]}
      />
    </>
  );
};

export default Settlement;
