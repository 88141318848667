import React from "react";
import AvailableModulesList from "../AvailableModulesList/AvailableModulesList";
import { ManageCustomerProvider } from "../../../contexts/ManageCustomersContext";
// styles imports
import "./EditCustomerData.scss";

const EditCustomerData = () => {
  return (
    <React.Fragment>
      <div className="edit-customer-page">
        <div className="edit-customer-content">
          <div className="edit-customer-card">
            <ManageCustomerProvider>
              <AvailableModulesList />
            </ManageCustomerProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditCustomerData;
