import React from 'react'
import Tabs from '../Tabs/Tabs'
import './TabPanel.scss';

const TabPanel = () => {
    return (
        <div className="menu-container-dev">
            <Tabs />
        </div>
    )
}

export default TabPanel;

