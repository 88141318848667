import React from "react";

//component-import
import SingleIncomingTransaction from "./IncomingTransaction/SingleIncomingTransaction";
import BulkIncomingTransaction from "./IncomingTransaction/BulkIncomingTransaction";
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";

const RecordTransaction = () => {
  return (
    <React.Fragment>
      <TabComponentWrapper
        heading="Record Transaction"
        tabs={[
          {
            className: "single-record",
            label: "Single",
            value: "single",
            component: <SingleIncomingTransaction />,
          },
          {
            className: "bulk-record",
            label: "Bulk",
            value: "bulk",
            component: <BulkIncomingTransaction />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default RecordTransaction;
