import React, { useContext, useState } from "react";
// component imports
import DeleteCallback from "./DeleteCallback/DeleteCallback";
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// context imports
import CallbackContext from "../../../contexts/CallbackContext";
// utilities
import copyToClipboard from "../../../utilities/copyToClipboard";
import { randomUUID } from "../../../services/randomUUID";
import { wrapText } from "../../../utilities/textWrap";
// styles imports
import "./ListCallback.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ListCallback = () => {
  // & All callback state
  const [callBackValue, setCallBackValue] = useState("");

  // ^ context
  const {
    listIds,
    resetCallback,
    callbackEditItem,
    openModal,
    deleteCallbackApi,
    searchCompanyID,
  } = useContext(CallbackContext);

  // * list callbacks
  const callbackExistCheckApi = () => {
    APIConfig.API_Client.get(
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint,
      // + "/" + `${listIds?.companyId}`
      { cancelToken: source.token }
    )
      .then((response) => {
        setCallBackValue(response.data.data);
      })
      .catch((error) => {});
  };

  React.useEffect(() => {
    callbackExistCheckApi();
  }, [listIds, resetCallback]);

  let mappedResult;
  if (callBackValue.length > 0) {
    mappedResult = callBackValue

      .map((item) => {
        return {
          ...item,
        };
      })
      .filter((company) => company.companyName === listIds.commonName)
      .reverse();
  }

  if (!callBackValue) {
    return (
      <div className="callback-loader-container">
        <img src="/images/loader.gif" alt="loader" />
      </div>
    );
  }

  // * copy header details
  const copyResponseDetail = (headerData) => {
    copyToClipboard(headerData);
  };

  // * DELETE callback handler
  const handleDeleteCallback = (callbackType) => {
    let payload = {
      reference_id: randomUUID(),
      company_id: searchCompanyID,
      callback_type: callbackType,
    };

    // delete's callback
    deleteCallbackApi(payload);
  };

  return (
    <div className="added-callbacks">
      {/* <h1>List Callbacks</h1> */}
      <h2 className="edit-list-heading">
        {mappedResult.length === 0 ? "No Callbacks Found" : "Added Callbacks"}
      </h2>
      {mappedResult &&
        mappedResult.map((item) => {
          return (
            <div key={item.subscriberUrl} className="callback-list__container">
              <div className="field-details">
                <div className="list-row__left">
                  <div className="response">
                    <span className="response-label">Callback</span>
                    <span className="response-value">
                      {item?.callbackType?.replaceAll("_", " ") || "NIL"}
                    </span>
                  </div>

                  <div className="response">
                    <span className="response-label">Header</span>
                    <span className="response-value">
                      {item?.subscriptionHeaders
                        ? JSON.stringify(item?.subscriptionHeaders).slice(
                            0,
                            45
                          ) + "..."
                        : "NIL"}

                      {item?.subscriptionHeaders &&
                      Object.keys(item?.subscriptionHeaders)?.length ? (
                        <img
                          className="copy-header-icon"
                          onClick={() =>
                            copyResponseDetail(
                              JSON.stringify(item?.subscriptionHeaders || "-")
                            )
                          }
                          src="/images/copy-icon.svg"
                          alt="copy-icon"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>

                <div className="list-row__right">
                  <div className="response">
                    <span className="response-label">HTTP</span>
                    <span className="response-value">
                      {item.httpMethod || "NIL"}
                    </span>
                  </div>

                  <div className="response url-callback-value">
                    <span className="response-label">URL</span>
                    <span className="response-value url-callback-value">
                      {/* {wrapText(item?.subscriberUrl, 20) || "NIL"} */}
                      {item?.subscriberUrl || "NIL"}

                      {item?.subscriberUrl &&
                      Object.keys(item?.subscriberUrl)?.length ? (
                        <img
                          className="copy-header-icon"
                          onClick={() =>
                            copyResponseDetail(
                              // JSON.stringify(item?.subscriberUrl || "-")
                              item?.subscriberUrl || "-"
                            )
                          }
                          src="/images/copy-icon.svg"
                          alt="copy-icon"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div class="action-btn-wrapper">
                <div className="edit-btn-wrapper">
                  <button
                    onClick={() => {
                      callbackEditItem(item);
                      openModal();
                    }}
                    className="callback-edit-btn"
                  >
                    Edit
                  </button>
                </div>

                {/* <div className="delete-btn-wrapper">
                  <button
                    onClick={() => handleDeleteCallback(item.callbackType)}
                    className="callback-delete-btn"
                  >
                    Delete
                  </button>
                </div> */}

                <DeleteCallback item={item} searchCompanyID={searchCompanyID} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ListCallback;
