import React from "react";

//styles-import
import "./CBADetails.scss";

//component-import
import CopyButton from "../../../../../UI/CopyButton/CopyButton";


const CBADetails = ({ data, onClick }) => {
  const details = data[0];

  return (
    <>
      <div className="cba-details-container">
        <h4 className="cba-details-heading">
          CBA Details
          <img
            src="/images/close.svg"
            className="close-cba-details"
            alt="close"
            onClick={onClick}
          />
          </h4>
        <div className="cba-details-divider"></div>
        <div className="cba-details">
          <ul className="left-list">
            <li className="cba-list-item">
              <span className="field">Account Name: </span>
              <span className="value">{details.account_name || "-"}</span>
              {details.account_name && (
                <CopyButton text={details.account_name} />
              )}
            </li>
            <li className="cba-list-item">
              <span className="field">Account Number: </span>
              <span className="value">{details.account_number || "-"}</span>
              {details.account_number && (
                <CopyButton text={details.account_number} />
              )}
            </li>
            <li className="cba-list-item">
              <span className="field">IFSC Code: </span>
              <span className="value">{details.ifsc_code || "-"}</span>
              {details.ifsc_code && <CopyButton text={details.ifsc_code} />}
            </li>
            <li className="cba-list-item">
              <span className="field">UPI ID: </span>
              <span className="value">{details.upi_id || "-"}</span>
              {details.upi_id && <CopyButton text={details.upi_id} />}
            </li>
            <li className="cba-list-item">
              <span className="field">Balance: </span>
              <span className="value">
                {details.balance >= 0 ? details.balance : "-"}
              </span>
              {details.balance >= 0 && <CopyButton text={details.balance} />}
            </li>
          </ul>
          <ul className="right-list">
            <li className="cba-list-item">
              <span className="field">Mobile: </span>
              <span className="value">{details.mobile || "-"}</span>
              {details.mobile && <CopyButton text={details.mobile} />}
            </li>
            <li className="cba-list-item">
              <span className="field">CBA ID: </span>
              <span className="value">{details.cba_id || "-"}</span>
              {details.cba_id && <CopyButton text={details.cba_id} />}
            </li>
            <li className="cba-list-item">
              <span className="field">Company Consumer ID: </span>
              <span className="value">
                {details.company_consumer_id || "-"}
              </span>
              {details.company_consumer_id && (
                <CopyButton text={details.company_consumer_id} />
              )}
            </li>
            <li className="cba-list-item">
              <span className="field">Company ID: </span>
              <span className="value">{details.company_id || "-"}</span>
              {details.company_id && <CopyButton text={details.company_id} />}
            </li>
            <li className="cba-list-item">
              <span className="field">Email: </span>
              <span className="value">{details.email || "-"}</span>
              {details.email && <CopyButton text={details.email} />}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CBADetails;
