import React, { useContext } from "react";
// component imports
import CallbackConfigurationSummary from "../SortTableSummary/CallbackConfigurationSummary";
import AddCallback from "./AddCallback/AddCallback";
// context imports
import CallbackActionContext from "../../contexts/CallbackActionContext";
// styles imports
import "./CallbackConfiguration.scss";

const CallbackConfiguration = () => {
  const { cardType } = useContext(CallbackActionContext);

  const CALLBACK_CARD = {
    newCallback: <AddCallback />,
    allCallback: <AllCallback />,
  };

  return (
    <div>
      <div>{CALLBACK_CARD[cardType]}</div>
    </div>
  );
};

const AllCallback = () => {
  const { configureNewCallback } = useContext(CallbackActionContext);

  return (
    <div className="callback-configuration-page">
      <div className="callback-configuration-content">
        <div className="callback-configuration">
          <h1 className="heading">Callback Configuration</h1>
          <button
            onClick={configureNewCallback}
            className={`configure-callback-btn`}
            type="button"
          >
            {/* <span>+</span> */}
            Manage Callbacks
          </button>
        </div>
        <div className="callback-configuration-card">
          <CallbackConfigurationSummary />
        </div>
      </div>
    </div>
  );
};

export default CallbackConfiguration;

// return (
//   <div>
//     { ALERT_STATUS[status] }
//   </div>
// )
