import React, { useContext, useState } from "react";
// component imports
import TableLoader from "../../Shared/TableLoader/TableLoader";
import CreditDataTable from "../CreditDataTable";
import Error from "../../Shared/Error/Error";
// api config imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
// context imports
import CreditsActionContext from "../../../contexts/CreditsActionContext";
// utilities imports
import capitalizeFirstLetter from "../../../utilities/capitalizeLetter";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// styles imports
import "./CreditsManagementSummary.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CreditsManagementSummary = () => {
  const { setTableEditData, editCredits } = useContext(CreditsActionContext);

  function handleEdit(row) {
    editCredits();
    setTableEditData({
      id: row.companyId,
      edit: true,
      companyName: row.commonName,
    });
  }

  // Dynamic useTable headers for data-table
  const onBoardedCompanyTableHeaders = React.useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        columns: [
          {
            Header: "Company Id",
            accessor: "companyId",
            className: "header-small",
            sort: true,
          },
          {
            Header: "Company",
            accessor: "commonName",
            className: "header-medium",
            sort: true,
            // disable the filter for particular column
            disableFilters: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.commonName
                  ? capitalizeFirstLetter(row.row.original.commonName)
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Legal Name",
            accessor: "fullName",
            className: "header-large",
            sort: true,
            // disable the filter for particular column
            disableFilters: true,
          },
          {
            Header: "Admin Email",
            accessor: "emailId",
            className: "header-large",
            sort: true,
            // disable the filter for particular column
            disableFilters: true,
          },
          // {
          //   Header: "Contact",
          //   accessor: "mobileNumber",
          //   // disable the filter for particular column
          //   disableFilters: true,
          //   Cell: (row) => (
          //     <div style={{ textAlign: "center" }}>
          //       {row.row.original.mobileNumber
          //         ? row.row.original.mobileNumber
          //         : "-"}
          //     </div>
          //   ),
          // },
          // {
          //   Header: "Modules",
          //   accessor: "customerModules",
          //   sort: true,
          //   // disable the filter for particular column
          //   disableFilters: true,
          //   Cell: (row) => (
          //     <div style={{ textAlign: "center" }}>
          //       {row.row.original.customerModules.length > 0
          //         ? row.row.original.customerModules.join()
          //         : "-"}
          //     </div>
          //   ),
          // },
          {
            Header: "Action",
            accessor: "action",
            sort: false,
            className: "last-child-header header-small",
            Cell: (row) => (
              <div>
                <button
                  onClick={(e) => {
                    handleEdit(row.row.original);
                  }}
                  className="callback-table-edit-btn"
                >
                  Edit
                  <img src="/images/back.svg" alt="right-arrow" />
                </button>
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const [mappedValue, setMappedValue] = React.useState("");
  // Error Handling states
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState({
    status: "",
    message: "",
  });

  const getAPIModuleData = (searchPayload) => {
    // * Emptying the state
    setMappedValue("");

    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_API_METER_SALAD.baseUrl +
        apiEndpointList.COMPANY_API_METER_SALAD.endpoint,
      searchPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        // * Table Data
        setMappedValue(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error?.response?.status,
          message: error?.message,
        });
      });
  };

  React.useEffect(() => {
    getAPIModuleData({
      limit: 10,
      offset: 0,
      company_name: "",
    });
  }, []);

  // & search company state
  const [companyName, setCompanyName] = useState("");

  const handleSearchCreditCompany = (event) => {
    setCompanyName(event.target.value);
  };

  const handleSearchCompany = (event) => {
    event.preventDefault();
    getAPIModuleData({
      limit: 10,
      offset: 0,
      company_name: companyName,
    });
  };

  return (
    <div className="sort-table">
      {!isError ? (
        mappedValue ? (
          <>
            <div class="table-search-header">
              <form onSubmit={handleSearchCompany}>
                <div class="data-table-credit-filter">
                  <input
                    onChange={handleSearchCreditCompany}
                    type="text"
                    placeholder="Press enter to search"
                  />
                </div>
                <button type="submit" className="visible-false">
                  Search
                </button>
              </form>
            </div>
            <CreditDataTable
              columns={onBoardedCompanyTableHeaders}
              data={mappedValue}
              csvName="CreditsManagementSummaryDetails"
            />
          </>
        ) : (
          <TableLoader />
        )
      ) : (
        <Error type={errorResponse} />
      )}
    </div>
  );
};

export default CreditsManagementSummary;
