import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import CallbackContext from "../../../../contexts/CallbackContext";
import { APIConfig } from "../../../../services/apiConfiguration";
import "./EditHttpDropdown.scss";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const EditHttpDropdown = ({ setHttpId, setHttpTypeName, editData }) => {
  const [selected, setSelected] = useState("Choose Http Type");
  const [isActive, setIsActive] = useState(false);

  //  Dropdown field state
  const [httpMethod, setHttpMethod] = useState("");

  //   const { resetCallback } = useContext(CallbackContext);

  //   useEffect(() => {
  //     setSelected("Choose Http Type");
  //   }, [resetCallback]);

  useEffect(() => {
    setSelected(editData?.httpMethod || "Choose Http Type");
  }, [editData]);

  const httpMethodList = () => {
    const check = localStorage.getItem("callbackMethodType");

    if (check) {
      setHttpMethod(JSON.parse(check));
    } else {
      APIConfig.API_Client.post(
        apiEndpointList.CALLBACK_HTTP_METHOD_TYPE.baseUrl +
          apiEndpointList.CALLBACK_HTTP_METHOD_TYPE.endpoint,
        // APIConfig.BASE_URL + "/frontend_sa/decentro/read/fetch/http_method_type",
        {},
        { cancelToken: source.token }
      )
        .then((response) => {
          localStorage.setItem(
            "callbackMethodType",
            JSON.stringify(response.data)
          );
          setHttpMethod(response.data);
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    httpMethodList();
  }, []);

  if (httpMethod.length === 0) {
    return (
      <div className="edit-httptype-loader-skeleton">
        <span className="skeleton-loader"></span>
      </div>
    );
  }
  return (
    <div className="edit-dropdown-http-type">
      {/* <div className="dropdown-http-label">Http</div> */}
      <div
        className={`dropdown-btn ${
          selected === "Choose Http Type" ? "text-light" : "text-dark"
        } ${isActive && " border-radius__invert"}`}
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected}
        {selected.length !== 1 && (
          <span>
            <DownArrow isActive={isActive} />
          </span>
        )}
      </div>

      {isActive && (
        <div className="http-type-content">
          {httpMethod.map((option) => (
            <div key={option.id}>
              {option.name !== selected && (
                <div
                  key={option.id}
                  onClick={(e) => {
                    setSelected(option.name);
                    setIsActive(false);
                    setHttpId(option.id);
                    setHttpTypeName(option.name);
                  }}
                  className="dropdown-item"
                >
                  {option.name}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${
        isActive && "dropdown-arrow__rotate"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default EditHttpDropdown;
