import React from "react";

//component-import
import BulkUnloadAccount from "./UnloadVirtualAccount/BulkUnloadAccount";
import SingleUnloadAccount from "./UnloadVirtualAccount/SingleUnloadAccount";
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";

const UnloadAccount = () => {

  return (
    <React.Fragment>
     <TabComponentWrapper
        heading="Unload Account"
        tabs={[
          {
            className: "single-unload",
            label: "Single",
            value: "single",
            component: <SingleUnloadAccount />,
          },
          {
            className: "bulk-unload",
            label: "Bulk",
            value: "bulk",
            component: <BulkUnloadAccount />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default UnloadAccount;
