import React from "react";
// component imports
import DataTable from "./DataTable";
import TableLoader from "../Shared/TableLoader/TableLoader";
import Error from "../Shared/Error/Error";
// api config imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
// utilities imports
import apiEndpointList from "../../config/modules/customer_management/endpoint";
// styles imports
import "./OnBoardingSummary.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

/**
 * The SelectColumnFilter function generates a multi-select box for filtering data based on unique
 * values in a specified column.
 * @returns The SelectColumnFilter component is being returned. It renders a multi-select box with
 * options generated based on the preFilteredRows data for the specified column. The options are
 * filtered to exclude falsy values, and the component allows users to select a filter value for the
 * column.
 */
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id]) {
        // Filter out falsy values
        optionsSet.add(row.values[id]);
      }
    });

    return Array.from(optionsSet);
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const OnBoardingSummary = () => {
  // dynamic useTable headers for data-table
  const onBoardedCompanyTableHeaders = React.useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        showColumnFilter: true,
        columns: [
          {
            Header: "Company ID",
            accessor: "company_id",
            className: "header-small",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.company_id || "-"}
              </div>
            ),
          },
          {
            Header: "Name",
            accessor: "common_name",
            className: "header-small",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.common_name || "-"}
              </div>
            ),
          },
          {
            Header: "Admin Email",
            accessor: "email_id",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.email_id || "-"}
              </div>
            ),
          },
          {
            Header: "Mobile Number",
            accessor: "mobile_number",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.mobile_number || "-"}
              </div>
            ),
          },
          {
            Header: "Strategic Account",
            accessor: "strategic_account",
            className: "header-small",
            sort: false,
            // Filter: SelectColumnFilter,
            // filter: "equals",
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.strategic_account ? "Yes" : "No"}
              </div>
            ),
          },
          {
            Header: "Created On",
            accessor: "created_on",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.created_on ? (
                  <>{row.row.original.created_on || ""}</>
                ) : (
                  "-"
                )}
              </div>
            ),
          },
          {
            Header: "Updated On",
            accessor: "updated_on",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.updated_on ? (
                  <>{row.row.original.updated_on || ""}</>
                ) : (
                  "-"
                )}
              </div>
            ),
          },
          {
            Header: "Category",
            accessor: "category",
            className: "header-small",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.category || "-"}
              </div>
            ),
          },
          {
            Header: "Partner",
            accessor: "partner",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.partner ? "Yes" : "No"}
              </div>
            ),
          },
          {
            Header: "Decentro Partner ID",
            accessor: "partner_id",
            className: "header-small",
            sort: false,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row?.row?.original?.partner_id || "-"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const [mappedValue, setMappedValue] = React.useState("");

  // Error Handling states
  const [isError, setIsError] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState({
    status: "",
    message: "",
  });

  /**
   * The function `getAPIModuleData` makes a POST request to an API endpoint with a search payload and
   * handles the response and error accordingly.
   * @param searchPayload - The `searchPayload` parameter in the `getAPIModuleData` function is the data
   * that will be sent as the payload in the API request. It contains the information needed to search
   * for specific data or perform a specific action on the server. This data is typically in the form of
   * an object
   */
  const getAPIModuleData = (searchPayload) => {
    APIConfig.API_Client.post(
      apiEndpointList.ALL_CONSUMERS_DETAILS.baseUrl +
        apiEndpointList.ALL_CONSUMERS_DETAILS.endpoint,
      searchPayload,
      { cancelToken: source.token }
    )
      .then((response) => {
        // * Table Data
        setMappedValue(response.data);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error.response.status,
          message: error.message,
        });
      });
  };

  React.useEffect(() => {
    getAPIModuleData({
      // limit: 10,
      // offset: 0,
    });
  }, []);

  return (
    <div className="all-company-table-container">
      {!isError ? (
        mappedValue ? (
          <DataTable
            columns={onBoardedCompanyTableHeaders}
            data={mappedValue}
            csvName="OnBoardingSummaryDetails"
          />
        ) : (
          <TableLoader />
        )
      ) : (
        <Error type={errorResponse} />
      )}
    </div>
  );
};

export default OnBoardingSummary;
