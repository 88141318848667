import React from "react";
// component-imports
import { DateRangePicker } from "rsuite";
import ToolTip from "../../UI/ToolTip/ToolTip";
// utils-import
import PropTypes from "prop-types";
import subDays from "date-fns/subDays";
import format from "date-fns/format";
import { dateValues, Locale, Ranges } from "../../utilities/constants";
// styles-import
import "rsuite/dist/rsuite-no-reset.min.css";
import "./DateSelectorReports.scss";

function DateSelectorReports({
  selectedDateRange,
  label,
  setSelectedDateRange,
  isToolTip,
  id,
  name,
  required,
  touched,
  error,
}) {
  const [selectedDateRangeValue, setSelectedDateRangeValue] =
    React.useState(selectedDateRange);

  const today = new Date();
  // const minDate = subDays(today, 180); //180 days from today
  // The minimum date range that can be selected in the calendar should be 1st April 2020
  const minDate = new Date("2020-03-31");

  const formatDate = (date) => {
    return format(date, "dd MMM yyyy");
  };

  return (
    <div
      className={`ui-form-input-section ui-form-content-input user-input date-selector-reports`}
    >
      <div className="datereports">
        <DateRangePicker
          placement="autoVerticalStart"
          format={dateValues.dateFormat}
          cleanable={false}
          value={selectedDateRangeValue}
          ranges={Ranges}
          locale={Locale}
          shouldDisableDate={(date) => date < minDate || date > today}
          onChange={(item) => {
            setSelectedDateRangeValue(item);
            setSelectedDateRange(item);
          }}
          renderValue={(value) => {
            if (value && value[0] && value[1]) {
              return `${formatDate(value[0])} - ${formatDate(value[1])}`;
            }
            return "";
          }}
          placeholder="Select date range"
          style={{
            width: "100%",
            overflow: "hidden",
            border: "none",
            outline: "none",
          }}
          onShortcutClick={(shortcut, event) => {
            console.log(shortcut);
          }}
        />
      </div>
      <label className="ui-label" htmlFor={id}>
        {label}{" "}
        <ToolTip
          text={isToolTip}
          requiredField={required}
          isToolTip={isToolTip}
        >
          {isToolTip && (
            <div className="ui-info-icon">
              <img src="/images/info-icon.svg" alt="" />
            </div>
          )}
        </ToolTip>
      </label>
      {touched && error ? (
        <img
          src="/images/invalid-input.svg"
          alt="Invalid Input"
          className="ui-invalid-field-icon"
        />
      ) : null}
    </div>
  );
}

DateSelectorReports.propTypes = {
  selectedDateRange: PropTypes.array,
  setSelectedDateRange: PropTypes.func,
};

DateSelectorReports.defaultProps = {
  selectedDateRange: [subDays(new Date(), 30), new Date()],
  setSelectedDateRange: () => {},
};

export default DateSelectorReports;
