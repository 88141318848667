import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";
import DownloadButton from "../../../../UI/DownloadButton/DownloadButton";

//API-imports
import {
  APIConfig,
  APIConfigFormData,
} from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  file: null,
  purpose: "",
  provider_code: "",
};

const { alphanumericWithWhitespaceRegex } = REGEXP;
const validationSchema = Yup.object({
  file: Yup.mixed()
    .required("File is required")
    .test(
      "fileType",
      "Only CSV files are allowed",
      (value) => value && value.type === "text/csv"
    ),
  purpose: Yup.string()
    .min(5)
    .max(50)
    .matches(alphanumericWithWhitespaceRegex)
    .required("Purpose message is required"),
  provider_code: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BulkIncomingTransaction = () => {
  const [providerList, setProviderList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const file = document.getElementById("file").files[0];
      const batch_job_data = JSON.stringify({
        provider_code: values.provider_code.label,
      });

      const formData = new FormData();
      formData.set("file", file);
      formData.append("reference_id", randomUUID());
      formData.append("purpose", values.purpose);
      formData.append("action", "record_transaction");
      formData.append("batch_job_data", batch_job_data);

      action.setSubmitting(true);
      APIConfigFormData.API_Client.post(
        apiEndpointList.BULK_ACTION.baseUrl +
          apiEndpointList.BULK_ACTION.endpoint,
        formData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          const fileInput = document.getElementById("file");
          if (fileInput) {
            fileInput.value = "";
          }
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.four_character_bank_code.toLowerCase(),
        }));
        setProviderList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getProviderList();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("file", file);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="ui-form-details">
          <div className="ui-form-header">
            <h2 className="ui-form-title">Fill transaction details to continue</h2>
            <DownloadButton
              fileSrc="/files/record_txn.csv"
              downloadedFilename="RecordTxn.csv"
              text="Sample File"
            />
          </div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose"
                name="purpose"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose}
                touched={formik.touched.purpose}
                error={formik.errors.purpose}
                placeholder="Enter Purpose Message"
                label="Purpose Message"
                required={true}
                disabled={false}
                isToolTip={true ? "Min 5 characters" : null}
                maxLength={50}
              />
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider Code"
                required={true}
                isLoading={isLoading}
                placeholder="Select Provider"
                isformatOptionLabel={true}
              />
            </div>
            <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".csv"
                  className="file"
                  onChange={handleFileUpload}
                  required={true}
                />
                <label htmlFor="file" className="ui-file-label">
                  {"Upload CSV File"} <span className="required-field">*</span>
                </label>
                {formik.values.file &&
                formik.values.file.type !== "text/csv" ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default BulkIncomingTransaction;
