import React, { useState } from "react";
import ReactDOM from "react-dom";
import { APIConfig } from "../../services/apiConfiguration.js";
import axios from "axios";
import CustomerDetailsSummary from "../../components/CustomerDetailsSummary/CustomerDetailsSummary";
import CompanyDetails from "../../components/CompanyDetails/CompanyDetails";
import ModuleProvider from "../../components/ModuleProvider/ModuleProvider";
import PlanMapping from "../../components/PlanMapping/PlanMapping";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";
import { getAccessToken } from "axios-jwt";
import { randomUUID } from "../../services/randomUUID.js";
import Loader from "../../components/Loader/Loader";
import Box from "@material-ui/core/Box";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import apiEndpointList from "../../config/modules/customer_management/endpoint.js";

// styles import
import "./OnboardCustomer.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let commonCompanyId;
let companyInputData;
let companySecretInputData;
let modResponse;
let moduleProviderResponse;
let planOptions;
let planPricing;
let selectedModuleData;
let providerData;
let apiMeterData;
let copyModulesData;
let providerSecretData;
let apiMeterPayloadData;
let successSecretStateData;
let companyHiddenInputTemp;
let companyHiddenSecretInputTemp;
let companySelectedModulesInput;
let companySelectedProvidersInput;
let modulesInputData;
let finalProviderData;
let moduleAndProvidersMap;

const OnboardCustomer = () => {
  const [onboardingStep, setOnboardingStep] = React.useState("companyDetails");
  const [tabDisabled, setTabDisabled] = React.useState({
    companyDetails: false,
    moduleProvider: true,
    planMapping: true,
    credentials: true,
    summary: true,
  });

  const [providerSecretIds, setProviderSecretIds] = React.useState([]);
  const [companyDetails, setCompanyDetails] = React.useState({});
  const [companySecretData, setCompanySecretData] = React.useState({});
  const [companyModulesData, setCompanyModulesData] = React.useState([]);
  const [companySelectedModulesData, setCompanySelectedModulesData] =
    React.useState([]);
  const [companyProviderSecretData, setProviderSecretData] = React.useState([]);
  const [companyAPIMeterData, setCompanyAPIMeterData] = React.useState([]);
  const [mvaResponseDetails, setMvaResponseDetails] = useState([]);
  const [successSecretDetails, setSuccessSecretDetails] = React.useState([]);

  const handleChange = (event, newStep) => {
    setTabDisabled({
      ...tabDisabled,
      [newStep]: false,
    });
    setOnboardingStep(newStep);
  };

  const updateTab = (tabName) => {
    let tempTabsData = tabDisabled;
    for (const [key, value] of Object.entries(tempTabsData)) {
      if (key == tabName) {
        tempTabsData[key] = false;
      } else {
        tempTabsData[key] = true;
      }
    }
    setTabDisabled(tempTabsData);
    setOnboardingStep(tabName);
  };

  // APIs / functions to be called on load by default
  React.useEffect(() => {
    // modulesDataLatest();
    modulesData();
    providerNamesApi();
    planTypes();
    planPricingType();
    // Calling this function in future to fetch account aliases for generalization
    // fetchAccountData();
  }, []);

  // const fetchAccountData = () => {
  //   APIConfig.API_Client.post(
  //     apiEndpointList.MASTER_ACCOUNTS_ALIAS.baseUrl +
  //       apiEndpointList.MASTER_ACCOUNTS_ALIAS.endpoint,
  //     // APIConfig.BASE_URL + APIEndpointList.COMPANY_MODULE_PROVIDER.endpoint,

  //     { cancelToken: source.token }
  //   )
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {});
  // };

  // Provider Names API calling function
  const providerNamesApi = () => {
    APIConfig.API_Client.get(
      apiEndpointList.COMPANY_MODULE_PROVIDER.baseUrl +
        apiEndpointList.COMPANY_MODULE_PROVIDER.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_MODULE_PROVIDER.endpoint,

      { cancelToken: source.token }
    )
      .then((response) => {
        moduleProviderResponse = response.data.data;
      })
      .catch((error) => {});
  };

  // Modules Data API calling function
  const modulesData = () => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_MODULES_LATEST.baseUrl +
        apiEndpointList.COMPANY_MODULES_LATEST.endpoint,{},
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_MODULES.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        modResponse = response.data.filter((module) => {
          return (
            module.code !== "ledger" &&
            module.code !== "aml_and_compliance" &&
            module.code !== "lending" // removing lending since it is already set to is_active=false on response and still appearing in plan mapping since all module checkboxes == true by default
          );
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };


  // All Plans API calling function
  const planTypes = () => {
    APIConfig.API_Client.get(
      apiEndpointList.PLAN_TYPE.baseUrl + apiEndpointList.PLAN_TYPE.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.PLAN_TYPE.endpoint,
      {
        headers: {
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        planOptions = response.data.data;
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  // All Plans pricing API calling function
  const planPricingType = () => {
    APIConfig.API_Client.get(
      apiEndpointList.PLAN_PRICING_TYPE.baseUrl +
        apiEndpointList.PLAN_PRICING_TYPE.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.PLAN_PRICING_TYPE.endpoint,
      {
        headers: {
          Authorization: "Bearer " + getAccessToken(),
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      planPricing = response.data.data;
    });
  };

  const addCompanyData = (companyDetailData, companySecretData) => {
    companyInputData = companyDetailData;
    companySecretInputData = companySecretData;
    ReactDOM.render(<Loader />, document.getElementById("addCustomer-loader"));
    companyDetailApi();
  };

  const companyDetailApi = () => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_DETAIL_DATA.baseUrl +
        apiEndpointList.COMPANY_DETAIL_DATA.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_DETAIL_DATA.endpoint,
      companyInputData
    )
      .then((response) => {
        commonCompanyId = response.data.data.companyId;
        companySecretInputData.company_id = response.data.data.companyId;
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setCompanyDetails(response.data.data);
        companySecretApi();
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-loader")
        );
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  const companySecretApi = () => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_SECRET_DATA.baseUrl +
        apiEndpointList.COMPANY_SECRET_DATA.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_SECRET_DATA.endpoint,
      companySecretInputData
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setCompanySecretData(response.data.data);
        updateTab("moduleProvider");
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-loader")
        );
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  const addCompanyModules = (
    modulesData,
    providerObj,
    apiMeterObj,
    copyDataObj,
    modulesInputData
  ) => {
    selectedModuleData = modulesData;
    providerData = providerObj;
    apiMeterData = apiMeterObj;
    copyModulesData = copyDataObj;
    modulesInputData = modulesInputData;

    ReactDOM.render(
      <Loader />,
      document.getElementById("addCustomer-module-loader")
    );
    setCompanySelectedModulesData(selectedModuleData);
    companyModulesApi(modulesInputData);
  };

  const companyModulesApi = (modulesInputData) => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_MODULE.baseUrl +
        apiEndpointList.COMPANY_MODULE.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_MODULE.endpoint,
      { company_id: commonCompanyId, module_details: selectedModuleData }
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        let modulesArray = [];
        for (let i = 0; i < response.data.data.moduleDetails.length; i++) {
          modulesArray.push(response.data.data.moduleDetails[i].code);
        }

        // If modules selected with no providers shown  then
        if (
          (modulesArray.length == 1 &&
            (modulesArray.includes("kyc_and_onboarding") ||
              modulesArray.includes("aml_and_compliance"))) ||
          (modulesArray.length == 2 &&
            modulesArray.includes("kyc_and_onboarding") &&
            modulesArray.includes("aml_and_compliance"))
        ) {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("addCustomer-module-loader")
          );
          mapProvidersAndModules(providerData, modulesInputData);
          mapPlans();
        }

        // if (providerData.length == 0) {
        //   ReactDOM.unmountComponentAtNode(
        //     document.getElementById("addCustomer-module-loader")
        //   );
        //   mapPlans();
        // }
        else {
          CustomerProviderSecret(providerData, modulesInputData);
        }
        setCompanyModulesData(response.data.data.moduleDetails);
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-module-loader")
        );
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  const filterProvidersForModule = (moduleAndProvidersMap) => {
    for (let i = 0; i < moduleAndProvidersMap.length; i++) {
      if (
        moduleAndProvidersMap[i].code == "kyc_and_onboarding" ||
        moduleAndProvidersMap[i].code == "aml_and_compliance"
      ) {
        moduleAndProvidersMap[i].selectedProvider = {};
      }
    }
  };

  // Mapping modules and Providers

  const mapProvidersAndModules = (finalProviderData, modulesInputData) => {
    let finalModulesInputData = modulesInputData;
    for (let i = 0; i < modulesInputData.length; i++) {
      for (let j = 0; j < finalProviderData.length; j++) {
        if (modulesInputData[i].moduleCheckbox == true) {
          if (
            modulesInputData[i].moduleProviderId ==
            finalProviderData[j].moduleProviderId
          ) {
            finalModulesInputData[i].moduleProvider = "";
            finalModulesInputData[i].selectedProvider = finalProviderData[j];
            break;
          }
        }
      }
    }
    moduleAndProvidersMap = finalModulesInputData;
    filterProvidersForModule(moduleAndProvidersMap);
  };

  const CustomerProviderSecret = (providerData, modulesInputData) => {
    // excluding module providers using below code (from modules not requiring provider setup)
    let exModProv = []; // Exclude Module Provider Array - Stores modules that need to exclude their providers before the provider API Call
    for (let i = 0; i < moduleProviderResponse.length; i++) {
      if (
        moduleProviderResponse[i].moduleCode == "kyc_and_onboarding" ||
        moduleProviderResponse[i].moduleCode == "aml_and_compliance"
      ) {
        exModProv.push(moduleProviderResponse[i]);
      }
    }
    for (let i = 0; i < providerData.length; i++) {
      for (let j = 0; j < exModProv.length; j++) {
        for (let k = 0; k < exModProv[j].providerDetails.length; k++) {
          if (
            providerData[i].moduleProviderId ==
              exModProv[j].providerDetails[k].id &&
            providerData[i].moduleProvider ==
              exModProv[j].providerDetails[k].name &&
            (modulesInputData[j].code == "kyc_and_onboarding" ||
              modulesInputData[j].moduleCode == "aml_and_compliance")
          ) {
            providerData.splice(i, 1);
          }
        }
      }
    }

    const providerInitialData = providerData
      .map((item, idx) => {
        return {
          ...item,
          providerSecret: randomUUID(),
        };
      })
      .filter(
        (item, index, self) =>
          self.findIndex(
            (t) =>
              t.moduleProviderId === item.moduleProviderId &&
              t.moduleProvider === item.moduleProvider
          ) === index
      );
    const providerSecretObj = providerInitialData
      .filter((item) => {
        if (!!item.providerSecret) return item;
      })
      .map((el, index) => {
        return {
          id: +el.moduleProviderId,
          key: el.providerSecret,
        };
      });

    finalProviderData = providerInitialData;
    setProviderSecretIds(providerInitialData);
    // mapping of modules with their providers
    mapProvidersAndModules(finalProviderData, modulesInputData);

    addProviderSecret(providerSecretObj, providerInitialData);
  };

  const addProviderSecret = (providerSecretDetail, secretData) => {
    const providerSecretDetailUniqueUpdated = providerSecretDetail.reduce(
      (acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    );
    providerSecretData = providerSecretDetailUniqueUpdated;
    successSecretStateData = secretData;
    setSuccessSecretDetails(secretData);
    providerSecretApi();
  };

  const providerSecretApi = () => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_PROVIDER_SECRET.baseUrl +
        apiEndpointList.COMPANY_PROVIDER_SECRET.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_PROVIDER_SECRET.endpoint,
      { company_id: commonCompanyId, provider_details: providerSecretData }
    )
      .then((response) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-module-loader")
        );
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setProviderSecretData(response.data.data.providerDetails);
        mapPlans();
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("addCustomer-module-loader")
        );
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  const addApiMeterDetails = (ApiMeterDetailsDetail) => {
    apiMeterPayloadData = ApiMeterDetailsDetail;
    ReactDOM.render(
      <Loader />,
      document.getElementById("apiMeter-customer-loader")
    );
    meterDetailsApi();
  };

  const mapPlans = () => {
    updateTab("planMapping");
  };

  const meterDetailsApi = () => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_API_METER.baseUrl +
        apiEndpointList.COMPANY_API_METER.endpoint,
      // APIConfig.BASE_URL + APIEndpointList.COMPANY_API_METER.endpoint,
      { company_id: commonCompanyId, api_meter_details: apiMeterPayloadData }
    )
      .then((response) => {
        // ReactDOM.unmountComponentAtNode(
        //   document.getElementById("apiMeter-customer-loader")
        // );
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setCompanyAPIMeterData(response.data.data.apiMeterDetails);
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("apiMeter-customer-loader")
        );
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  React.useEffect(() => {}, [companyAPIMeterData]);

  const showCredentials = () => {
    updateTab("credentials");
  };

  const companyDetailsSummary = () => {
    updateTab("summary");
  };

  return (
    <React.Fragment>
      <div id="customerHiddenData"></div>
      <div className="onboard-customer-page">
        <div className="onboard-customer-content">
          <div className="onboard-customer">
            <h1 className="heading">Onboard Merchant</h1>
          </div>
          <div className="onboard-customer-forms">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={onboardingStep}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab
                      className="companyDetails onboard-tab"
                      disabled={tabDisabled.companyDetails}
                      label="Company Details"
                      value="companyDetails"
                    />
                    <Tab
                      className="moduleProvider onboard-tab"
                      disabled={tabDisabled.moduleProvider}
                      label="Module Provider"
                      value="moduleProvider"
                    />
                    <Tab
                      className="planMapping onboard-tab"
                      disabled={tabDisabled.planMapping}
                      label="Plan Mapping"
                      value="planMapping"
                    />
                    {/* <Tab
                      className="credentials onboard-tab"
                      disabled={tabDisabled.credentials}
                      label="Credentials"
                      value="credentials"
                    /> */}
                    <Tab
                      className="summary onboard-tab"
                      disabled={tabDisabled.summary}
                      label="Summary"
                      value="summary"
                    />
                  </TabList>
                </Box>
                <TabPanel value="companyDetails">
                  <div id="companyDetails">
                    <CompanyDetails addCompanyData={addCompanyData} />
                  </div>
                </TabPanel>
                <TabPanel value="moduleProvider">
                  <div id="moduleProvider">
                    <ModuleProvider
                      addCompanyModules={addCompanyModules}
                      modResponse={modResponse}
                      moduleProviderResponse={moduleProviderResponse}
                      companyDetails={companyDetails}
                    />
                  </div>
                </TabPanel>
                <TabPanel value="planMapping">
                  <div id="planMapping">
                    <PlanMapping
                      planOptions={planOptions}
                      planPricing={planPricing}
                      addApiMeterDetails={addApiMeterDetails}
                      apiMeterData={apiMeterData}
                      companyDetails={companyDetails}
                      commonCompanyId={commonCompanyId}
                      setMvaResponseDetails={setMvaResponseDetails}
                      companySecretInputData={companySecretInputData}
                      companyDetailsSummary={companyDetailsSummary}
                    />
                  </div>
                </TabPanel>
                {/* <TabPanel value="credentials">
                  <div id="credentials">
                    <CustomerCredentials
                      companySecretInputData={companySecretInputData}
                      selectedModuleData={selectedModuleData}
                      successSecretStateData={successSecretStateData}
                      companyDetails={companyDetails}
                      copyModulesData={copyModulesData}
                      companyDetailsSummary={companyDetailsSummary}
                      mvaResponseDetails={mvaResponseDetails}
                    />
                  </div>
                </TabPanel> */}
                {companyAPIMeterData.length != 0 && (
                  <TabPanel value="summary">
                    <div id="summary">
                      <CustomerDetailsSummary
                        companyDetails={companyDetails}
                        companySecretInputData={companySecretInputData}
                        companySecretData={companySecretData}
                        selectedModuleData={companySelectedModulesData}
                        companyProviderSecretData={companyProviderSecretData}
                        companyAPIMeterData={companyAPIMeterData}
                        successSecretDetails={successSecretDetails}
                        mvaResponseDetails={mvaResponseDetails}
                        moduleAndProvidersMap={moduleAndProvidersMap}
                      />
                    </div>
                  </TabPanel>
                )}
              </TabContext>
            </Box>
            {/* <div className={classes.root}>
                            <AppBar position="static">
                                <Tabs value={onboardingStep} onChange={handleChange} aria-label="wrapped label tabs example">
                                    <Tab
                                        className="companyDetails"
                                        disabled={tabDisabled.companyDetails}
                                        label="Company Details"
                                        value="companyDetails"
                                        {...a11yProps('companyDetails')}
                                    />
                                    <Tab className="moduleProvider" disabled={tabDisabled.moduleProvider} label="Module Provider" value="moduleProvider" {...a11yProps('moduleProvider')} />
                                    <Tab className="planMapping" disabled={tabDisabled.planMapping} label="Plan Mapping" value="planMapping" {...a11yProps('planMapping')} />
                                    <Tab className="credentials" disabled={tabDisabled.credentials} label="Credentials" value="credentials" {...a11yProps('credentials')} />
                                    <Tab className="summary" disabled={tabDisabled.summary} label="Summary" value="summary" {...a11yProps('summary')} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value="companyDetails" index="companyDetails">
                                <div id="companyDetails">
                                    <CompanyDetails
                                        addCompanyData={addCompanyData}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value="moduleProvider" index="moduleProvider">
                                <div id="moduleProvider">
                                    <ModuleProvider
                                        addCompanyModules={addCompanyModules}
                                        modResponse={modResponse}
                                        moduleProviderResponse={moduleProviderResponse}
                                        companyDetails={companyDetails}

                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value="planMapping" index="planMapping">
                                <div id="planMapping">
                                    <PlanMapping
                                        planOptions={planOptions}
                                        planPricing={planPricing}
                                        addApiMeterDetails={addApiMeterDetails}
                                        apiMeterData={apiMeterData}
                                        companyDetails={companyDetails}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value="credentials" index="credentials">
                                <div id="credentials">
                                    <CustomerCredentials
                                        companySecretInputData={companySecretInputData}
                                        selectedModuleData={selectedModuleData}
                                        successSecretStateData={successSecretStateData}
                                        companyDetails={companyDetails}
                                        copyModulesData={copyModulesData}
                                        companyDetailsSummary={companyDetailsSummary}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value="summary" index="summary">
                                <div id="summary">
                                    <CustomerDetailsSummary
                                        companyDetails={companyDetails}
                                        companySecretInputData={companySecretInputData}
                                        companySecretData={companySecretData}
                                        selectedModuleData={companySelectedModulesData}
                                        companyProviderSecretData={companyProviderSecretData}
                                        companyAPIMeterData={companyAPIMeterData}
                                        successSecretDetails={successSecretDetails}
                                    />
                                </div>
                            </TabPanel>
                        </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnboardCustomer;
