import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  sender_account_number: "",
  action: "",
  provider_code:"",
  account_number: "",
  ifsc: "",
  name: "",
  email: "",
  mobile: "",
};

const { accountNumberRegex, ifscRegex, emailRegex, phoneNumberRegex } = REGEXP;

const validationSchema = Yup.object({
  sender_account_number: Yup.string()
    .matches(accountNumberRegex)
    .required("Sender Account Number is required"),
  action: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  provider_code: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  account_number: Yup.string().when("action", {
    is: (action) => action?.label === "ADD",
    then: () =>
      Yup.string()
        .matches(accountNumberRegex)
        .required("Account Number is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  ifsc: Yup.string().when("action", {
    is: (action) => action?.label === "ADD",
    then: () => Yup.string().matches(ifscRegex).required("IFSC is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  name: Yup.string().when("action", {
    is: (action) => action?.label === "ADD",
    then: () =>
      Yup.string()
        .trim()
        .strict(true)
        .min(5)
        .max(100)
        .required("Name is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  email: Yup.string().when("action", {
    is: (action) => action?.label === "ADD",
    then: () => Yup.string().matches(emailRegex),
    otherwise: () => Yup.string().notRequired(),
  }),
  mobile: Yup.string().when("action", {
    is: (action) => action?.label === "ADD",
    then: () => Yup.string().matches(phoneNumberRegex),
    otherwise: () => Yup.string().notRequired(),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const AddSettlementAccount = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [providerList, setProviderList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: randomUUID(),
        action: values.action.label,
        sender_account_number: values.sender_account_number,
      };

      if (values.action.label === "ADD") {
        payloadData["account_number"] = values.account_number;
        payloadData["ifsc"] = values.ifsc;
        payloadData["name"] = values.name;

        if (values.email) payloadData["email"] = values.email;
        if (values.mobile) payloadData["mobile"] = values.mobile;
      }

      action.setSubmitting(true);

      APIConfig.API_Client.defaults.headers.post["provider_code"] = values.provider_code.label
      APIConfig.API_Client.post(
        apiEndpointList.ADD_SETTLEMENT.baseUrl +
          apiEndpointList.ADD_SETTLEMENT.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    }
  });

  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {        
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.four_character_bank_code.toLowerCase(),
        }));
        setProviderList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        setProviderList([]);
        setIsLoading(false);
        setIsError(true);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(()=>{
    getProviderList()
  },[])


  useEffect(() => {
    formik.setFieldValue("account_number", "");
    formik.setFieldValue("ifsc", "");
    formik.setFieldValue("name", "");
    formik.setFieldValue("email", "");
    formik.setFieldValue("mobile", "");
    formik.setFieldTouched("account_number", false, false);
    formik.setFieldTouched("ifsc", false, false);
    formik.setFieldTouched("name", false, false);
    formik.setFieldTouched("email", false, false);
    formik.setFieldTouched("mobile", false, false);
  }, [formik.values.action?.label]);

  const actions = [
    { value: 1, label: "ADD" },
    // { value: 2, label: "DEACTIVATE" },
  ];

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-divider"></div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="sender_account_number"
                name="sender_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sender_account_number}
                touched={formik.touched.sender_account_number}
                error={formik.errors.sender_account_number}
                placeholder="Enter sender account number"
                label="Sender Account Number"
                required={true}
                disabled={false}
              />
              <TextFieldSelect
                id="action"
                name="action"
                onChange={(selectedOption) =>
                  formik.setFieldValue("action", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("action", true)}
                value={formik.values.action}
                options={actions}
                noOptionsMessage={() => "No such action exists"}
                label="Action"
                required={true}
                placeholder="Select action"
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider Code"
                required={true}
                isLoading={isLoading}
                isError={isError}
                placeholder="Select provider"
                isformatOptionLabel={true}
              />
            </div>
            {formik.values.action?.label === "ADD" && (
              <div style={{ marginTop: "50px", textDecoration: "underline" }}>
                Settlement Activity Details
              </div>
            )}
            {formik.values.action?.label === "ADD" && (
              <div className="ui-form-inputs-section">
                <TextFieldInput
                  id="account_number"
                  name="account_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account_number}
                  touched={formik.touched.account_number}
                  error={formik.errors.account_number}
                  placeholder="Enter account number"
                  label="Account Number"
                  required={true}
                  disabled={false}
                />
                <TextFieldInput
                  id="ifsc"
                  name="ifsc"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ifsc}
                  touched={formik.touched.ifsc}
                  error={formik.errors.ifsc}
                  placeholder="Enter account ifsc"
                  label="Account IFSC"
                  required={true}
                  disabled={false}
                />
              </div>
            )}
            {formik.values.action?.label === "ADD" && (
              <div className="ui-form-inputs-section">
                <TextFieldInput
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  error={formik.errors.name}
                  placeholder="Enter account holder's name"
                  label="Name"
                  required={true}
                  disabled={false}
                  isToolTip={true ? "Min 5 characters" : ""}
                />
                <TextFieldInput
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  touched={formik.touched.email}
                  error={formik.errors.email}
                  placeholder="Enter account holder's email"
                  label="Email"
                  required={false}
                  disabled={false}
                />
              </div>
            )}
            {formik.values.action?.label === "ADD" && (
              <div className="ui-form-inputs-section">
                <TextFieldInput
                  id="mobile"
                  name="mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  touched={formik.touched.mobile}
                  error={formik.errors.mobile}
                  placeholder="Enter mobile number "
                  label="Mobile"
                  required={false}
                  disabled={false}
                />
              </div>
            )}
            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default AddSettlementAccount;
