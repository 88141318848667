import React, { useState, useEffect, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  transfer_type: "UPI",
  bank_reference_number: "",
  beneficiary_provider_code: "",
  remitter_provider_code: "",
  decentro_api: "PAYMENTS_NPST_UPI_CALLBACK",
  remitter_upi: "",
  beneficiary_upi: "",
  amount: "",
  purpose_message: "",
  transaction_urn: "",
  npci_transaction_id: "",
  transaction_type: "",
};

const { alphanumericRegex, UPIRegex, alphanumericWithWhitespaceRegex } = REGEXP;
const validationSchema = Yup.object({
  bank_reference_number: Yup.string()
    .max(50)
    .matches(alphanumericRegex)
    .required("It's a required field"),
  beneficiary_provider_code: Yup.string()
    .min(4)
    .max(4)
    .matches(alphanumericRegex)
    .required("It's a required field"),
  remitter_provider_code: Yup.string()
    .min(4)
    .max(4)
    .matches(alphanumericRegex)
    .required("It's a required field"),
  remitter_upi: Yup.string()
    .matches(UPIRegex)
    .required("It's a required field"),
  beneficiary_upi: Yup.string()
    .matches(UPIRegex)
    .required("It's a required field"),
  amount: Yup.number()
    .required()
    .positive()
    .test(
      "is-amount",
      "Must be a valid number with up to two decimal places",
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    ),
  purpose_message: Yup.string()
    .min(5)
    .max(50)
    .matches(alphanumericWithWhitespaceRegex)
    .required("It's a required field"),
  transaction_urn: Yup.string().when("transaction_type", {
    is: (transaction_type) =>
      transaction_type?.label === "Payment Link" ||
      transaction_type?.label === "Collection Request",
    then: () => Yup.string().max(50).required("It's a required field"),
    otherwise: () => Yup.string().notRequired(),
  }),
  npci_transaction_id: Yup.string().max(50).required("It's a required field"),
  transaction_type: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleIncomingTransaction = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let currentDate = new Date();
      const offset = currentDate.getTimezoneOffset();
      currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

      const payloadData = {
        transfer_type: values.transfer_type,
        bank_reference_number: values.bank_reference_number,
        beneficiary_provider_code: values.beneficiary_provider_code,
        remitter_provider_code: values.remitter_provider_code,
        decentro_api: values.decentro_api,
        remitter_upi: values.remitter_upi,
        beneficiary_upi: values.beneficiary_upi,
        amount: parseFloat(values.amount),
        purpose_message: values.purpose_message,
        npci_transaction_id: values.npci_transaction_id,
        transaction_timestamp: currentDate
          .toISOString()
          .slice(0, 19)
          .replace("T", " "),
      };

      if (values.transaction_type.label === "Payment Link") {
        payloadData["payment_link_decentro_company_urn"] =
          values.transaction_urn;
      }

      if (values.transaction_type.label === "Collection Request") {
        payloadData["collection_request_decentro_company_urn"] =
          values.transaction_urn;
      }

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.RECORD_INCOMING_TRANSACTION.baseUrl +
          apiEndpointList.RECORD_INCOMING_TRANSACTION.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("transaction_urn", "");
    formik.setFieldTouched("transaction_urn", false, false);
  }, [formik.values.transaction_type?.label]);

  const transactionType = [
    { value: 1, label: "Payment Link" },
    { value: 2, label: "Collection Request" },
    { value: 3, label: "UPI Push" },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">
            Fill transaction details to continue
          </h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="transfer_type"
                name="transfer_type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.transfer_type}
                touched={formik.touched.transfer_type}
                error={formik.errors.transfer_type}
                label="Transfer Type"
                required={true}
                disabled={true}
              />
              <TextFieldInput
                id="bank_reference_number"
                name="bank_reference_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_reference_number}
                touched={formik.touched.bank_reference_number}
                error={formik.errors.bank_reference_number}
                placeholder="Enter Bank Reference Number"
                label="Bank Reference Number"
                required={true}
                disabled={false}
                maxLength={50}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="beneficiary_provider_code"
                name="beneficiary_provider_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_provider_code}
                touched={formik.touched.beneficiary_provider_code}
                error={formik.errors.beneficiary_provider_code}
                placeholder="Enter Beneficiary Provider Code"
                label="Beneficiary Code"
                required={true}
                disabled={false}
                maxLength={4}
              />
              <TextFieldInput
                id="remitter_provider_code"
                name="remitter_provider_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.remitter_provider_code}
                touched={formik.touched.remitter_provider_code}
                error={formik.errors.remitter_provider_code}
                placeholder="Enter Remitter Provider Code"
                label="Remitter Provider Code"
                required={true}
                disabled={false}
                maxLength={4}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="decentro_api"
                name="decentro_api"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.decentro_api}
                touched={formik.touched.decentro_api}
                error={formik.errors.decentro_api}
                label="Decentro API"
                required={true}
                disabled={true}
              />
              <TextFieldInput
                id="remitter_upi"
                name="remitter_upi"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.remitter_upi}
                touched={formik.touched.remitter_upi}
                error={formik.errors.remitter_upi}
                placeholder="Enter Remitter UPI ID"
                label="Remitter UPI ID"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="beneficiary_upi"
                name="beneficiary_upi"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_upi}
                touched={formik.touched.beneficiary_upi}
                error={formik.errors.beneficiary_upi}
                placeholder="Enter Beneficiary UPI ID"
                label="Beneficiary UPI ID"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="Enter Amount"
                label="Amount"
                required={true}
                disabled={false}
                isToolTip={true ? "Upto 2 decimal places" : null}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter Purpose Message"
                label="Purpose Message"
                required={true}
                disabled={false}
                maxLength={50}
                isToolTip={true ? "Min 5 characters" : null}
              />
              <TextFieldSelect
                id="transaction_type"
                name="transaction_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("transaction_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("transaction_type", true)}
                value={formik.values.transaction_type}
                options={transactionType}
                noOptionsMessage={() => "No such transaction type exists"}
                label="Transaction Type"
                required={true}
                placeholder="Select Transaction Type"
              />
            </div>
            <div className="ui-form-inputs-section">
              {formik.values.transaction_type?.label === "Payment Link" ||
              formik.values.transaction_type?.label === "Collection Request" ? (
                <TextFieldInput
                  id="transaction_urn"
                  name="transaction_urn"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.transaction_urn}
                  touched={formik.touched.transaction_urn}
                  error={formik.errors.transaction_urn}
                  placeholder="Enter Transaction URN"
                  label="Transaction URN"
                  required={true}
                  disabled={false}
                  maxLength={50}
                />
              ) : null}
              <TextFieldInput
                id="npci_transaction_id"
                name="npci_transaction_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.npci_transaction_id}
                touched={formik.touched.npci_transaction_id}
                error={formik.errors.npci_transaction_id}
                placeholder="Enter NPCI Transaction ID"
                label="NPCI Transaction ID"
                required={true}
                disabled={false}
                maxLength={50}
              />
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default SingleIncomingTransaction;
