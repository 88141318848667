import React from "react";

//styles-import
import "./DownloadButton.scss"

const DownloadButton = ({fileSrc,downloadedFilename,text}) => {
  return (
    <>
      <a
        href={fileSrc}
        download={downloadedFilename} // filename to be displayed upon downloading
        className="ui-file-download"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/download-icon.svg" alt="download-icon" />
        {text}
      </a>
    </>
  );
};

export default DownloadButton;
