import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
// component imports
import axios from "axios";
import { Modal } from "./Modal/Modal";
import ListCallback from "../ListCallback/ListCallback";
import CallbackTypeDropdown from "./CallbackTypeDropdown";
import CompanySearchInput from "./CompanySearchInput";
import HttpTypeDropdown from "./HttpTypeDropdown";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import { TotpModal } from "./TotpModal/TotpModal";
// utils imports
import { randomUUID } from "../../../services/randomUUID";
// assets imports
import infoIcon from "../../../assets/information.svg";
// context imports
import CallbackContext from "../../../contexts/CallbackContext";
import CallbackActionContext from "../../../contexts/CallbackActionContext";
// api config imports
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// styles imports
import "./NewCallback.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
const NewCallback = () => {
  // Form show/hide
  const [showForm, setShowForm] = useState(true);
  // Dropdown
  const [getCompanyID, setGetCompanyId] = useState("");
  const [companyCommonName, setCompanyCommonName] = useState(null);
  const [callbackId, setCallbackId] = useState(null);
  const [callbackTypeName, setCallbackTypeName] = useState(null);
  const [httpId, setHttpId] = useState(null);
  const [httpTypeName, setHttpTypeName] = useState(null);
  // Input Fields
  const [headerField, setHeaderField] = useState(() =>
    JSON.stringify({ key1: "value1", key2: "value2" })
  );
  //&  default value for header field
  // useState(() =>
  //   JSON.stringify({ key1: "value1", key2: "value2" })
  // );
  const [callbackExist, setCallbackExist] = useState(false);
  const [urlField, setUrlField] = useState("");
  // Button validation
  const [isDisabled, setIsDisabled] = useState(true);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // ^ Context
  const {
    addCallbackApi,
    editCallback,
    showCallbackList,
    showModal,
    setListIds,
    showTotpModal,
    setShowTotpModal,
    openTotpModal,
    setSearchCompanyID,
  } = useContext(CallbackContext);
  const { tableEditData, allCallback } = useContext(CallbackActionContext);

  // back To allcallback handler
  const backToAllCallback = () => {
    allCallback();
  };

  // input validations
  const headerValidtion = useRef(false);
  const urlValidation = useRef(false);

  // header input value handler
  const headerInputHandler = (e) => {
    setHeaderField(e.target.value);
    try {
      JSON.parse(e.target.value);
      headerValidtion.current = false;
    } catch (e) {
      headerValidtion.current = true;
    }
  };

  // url input value handler
  const urlInputHandler = (e) => {
    setUrlField(e.target.value);

    const urlRegExp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if (urlRegExp.test(e.target.value)) {
      urlValidation.current = false;
    } else {
      urlValidation.current = true;
    }
  };

  // callback already exist api check
  const callbackExistCheckApi = () => {
    APIConfig.API_Client.get(
      // APIConfig.BASE_URL +
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint +
        "/" +
        `${getCompanyID}/${callbackId}`,
      { cancelToken: source.token }
    )
      .then((response) => {
        if (Object.keys(response.data.data).length) {
          ReactDOM.render(
            <div className="exist-callback__message">
              <p>Callback Already exist. Choose another callback !</p>
            </div>,
            document.getElementById("callback-type__validation-message")
          );
          setCallbackExist(false);
        } else {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("callback-type__validation-message")
          );

          setCallbackExist(true);
        }
      })
      .catch((error) => {});
  };

  // call exist side effect
  useEffect(() => {
    if (!!callbackId && !!getCompanyID && !tableEditData.edit) {
      callbackExistCheckApi();
    }
  }, [callbackId, getCompanyID]);

  // fill's input on click of edit from data table
  useEffect(() => {
    if (tableEditData.edit) {
      setGetCompanyId(tableEditData?.item?.companyId || "");
      setCompanyCommonName(tableEditData?.item?.commonName || "");
      setListIds({
        companyId: tableEditData?.item?.companyId || "",
        setListIds: tableEditData?.item?.commonName || "",
      });
      setCallbackId(tableEditData?.item?.type || "");
      // setCallbackTypeName
      setHttpId(tableEditData?.item?.httpMethod || "");
      setHeaderField(
        JSON.stringify(tableEditData?.item?.subscriptionHeaders) || null
      );
      setUrlField(tableEditData?.item?.subscriberUrl || "");
    }
  }, [tableEditData]);

  // submit callback details handler
  const submitCallback = () => {
    // api payload
    let payload = {
      company_id: +getCompanyID,
      subscriber_url: urlField,
      http_method: +httpId,
      subscription_protocol:
        urlField.indexOf("https://") === 0 ? "https" : "http",
      subscription_headers: JSON.parse(headerField),
      callback_type: +callbackId,
    };

    let newPayload;

    if (JSON.parse(headerField)) {
      // ! updated api payload
      newPayload = {
        reference_id: randomUUID(),
        company_id: +getCompanyID,
        subscriber_url: urlField,
        http_method: httpTypeName, // https type name - new upload
        subscription_protocol:
          urlField.indexOf("https://") === 0 ? "https" : "http",
        subscription_headers: JSON.parse(headerField),
        callback_type: callbackTypeName, // callback type - updated payload
      };
    } else {
      // ! updated api payload
      newPayload = {
        reference_id: randomUUID(),
        company_id: +getCompanyID,
        subscriber_url: urlField,
        http_method: httpTypeName, // https type name - new upload
        subscription_protocol:
          urlField.indexOf("https://") === 0 ? "https" : "http",
        callback_type: callbackTypeName, // callback type - updated payload
      };
    }

    if (tableEditData.edit) {
      editCallback(newPayload);
    } else {
      addCallbackApi(newPayload);
    }

    // reset input's on submit
    setShowForm(false);
    setCallbackId(null);
    setCallbackTypeName(null);
    setHttpId(null);
    setHttpTypeName(null);
    setHeaderField(null);
    setUrlField("");
    setIsDisabled(true);
  };

  // btn disablility side effect handler
  useEffect(() => {
    if (
      !!getCompanyID &&
      getCompanyID.length !== 0 &&
      !!callbackId &&
      !!httpId &&
      // !!headerField &&
      // headerField.length !== 0 &&
      !!urlField &&
      urlField.length !== 0 &&
      (tableEditData.edit ? true : callbackExist) &&
      // !headerValidtion.current &&
      !urlValidation.current
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    urlValidation,
    getCompanyID,
    callbackId,
    httpId,
    urlField,
    callbackExist,
    tableEditData,
  ]);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = () => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        openTotpModal();
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="callback-container">
      <div className="add-callback">
        <div onClick={backToAllCallback} className="header-container">
          <img src="/images/back.svg" alt="back-icon" />
          <h1 className="heading">Configure New Callback</h1>
        </div>

        {!showForm && (
          <button
            onClick={() => setShowForm(true)}
            className={`new-callback-btn`}
            type="button"
          >
            <span>+</span> Add Callback
          </button>
        )}
      </div>

      <div className="callback-divider"></div>

      {/* Search Company */}
      <div className="callback-scrollable-container">
        <CompanySearchInput
          setGetCompanyId={setGetCompanyId}
          setSearchCompanyID={setSearchCompanyID}
          setCompanyCommonName={setCompanyCommonName}
        />

        {showForm && (
          <>
            <div className="callback-divider"></div>
            <div className="callback-input-container">
              <div className="input-wrapper">
                <div className="input-group__upper">
                  {/* callback dropdown */}
                  <CallbackTypeDropdown
                    setCallbackId={setCallbackId}
                    setCallbackTypeName={setCallbackTypeName}
                  />
                  {/* http dropdown */}
                  <HttpTypeDropdown
                    setHttpId={setHttpId}
                    setHttpTypeName={setHttpTypeName}
                  />
                </div>

                <div className="input-group__lower">
                  {/* header input */}
                  <div className="text-input-wrapper">
                    <div className="label-wrapper">
                      <div className="text-input-label-without-required">
                        Header
                      </div>
                      <Tooltip direction="top">
                        <img src={infoIcon} alt="information" />
                      </Tooltip>
                    </div>
                    <div
                      className={`add-callback-input ${
                        headerValidtion.current === true && "error-border"
                      } `}
                    >
                      <input
                        type="text"
                        placeholder={JSON.stringify({
                          key1: "value1",
                          key2: "value2",
                        })}
                        value={headerField}
                        className="callback-field-input"
                        onChange={headerInputHandler}
                      />
                    </div>
                  </div>

                  {/* url input */}
                  <div className="text-input-wrapper">
                    <div className="text-input-label">URL</div>
                    <div
                      className={`add-callback-input ${
                        urlValidation.current === true && "error-border"
                      } `}
                    >
                      <input
                        type="text"
                        placeholder={"https://www.placeholder.com"}
                        value={urlField}
                        className="callback-field-input"
                        onChange={urlInputHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* update and submit callback btn */}
              <div className="button-wrapper">
                <button
                  className={`submit-callback-details ${
                    !isDisabled && !isLoading && "active-callback-btn"
                  }`}
                  onClick={submitHandler}
                  type="button"
                  disabled={isDisabled || isLoading}
                >
                  {isLoading
                    ? "Loading"
                    : tableEditData.edit
                    ? "Update"
                    : "Submit"}
                </button>
              </div>
            </div>
          </>
        )}

        {/* edit callback modal */}
        {showModal ? <Modal /> : null}

        {/* TOTP modal */}
        {showTotpModal ? (
          <TotpModal
            setShowTotpModal={setShowTotpModal}
            showTotp={showTotp}
            setShowTotp={setShowTotp}
            qrBase={qrBase}
            dataToVerify={dataToVerify}
            submitCallback={submitCallback}
          />
        ) : null}

        {/* callback list */}
        {showCallbackList && getCompanyID && (
          <>
            <ListCallback />
          </>
        )}
      </div>
    </div>
  );
};

export default NewCallback;
