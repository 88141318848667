import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../services/randomUUID";

//component-import
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../utilities/scrollIntoView";

const initialValues = {
  company_common_name: "",
  sub_merchant_id: "",
  virtual_account_number: "",
  upi_id: "",
  merchant_category_code: "",
  business_type_code: "",
  provider_code: "",
  is_sub_merchant: "",
  secret_key: "",
};

const { alphanumericRegex, accountNumberRegex, UPIRegex } = REGEXP;
const validationSchema = Yup.object({
  company_common_name: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  sub_merchant_id: Yup.string().required("Sub-Merchant ID is required"),
  virtual_account_number: Yup.string()
    .matches(accountNumberRegex)
    .required("Account Number is required"),
  upi_id: Yup.string().matches(UPIRegex).required("UPI ID is required"),
  merchant_category_code: Yup.number()
    .positive()
    .required("Merchant Category Code is required"),
  business_type_code: Yup.number()
    .positive()
    .required("Business Type Code is required"),
  provider_code: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  is_sub_merchant: Yup.string().when("provider_code", {
    is: (provider_code) => provider_code?.label === "npst",
    then: () =>
      Yup.string()
        .test(
          "is boolean",
          "Value can be either 0 or 1",
          (value) => value === "0" || value === "1"
        )
        .required(),
    otherwise: () => Yup.string().notRequired(),
  }),
  secret_key: Yup.string().when("provider_code", {
    is: (provider_code) => provider_code?.label === "phnp",
    then: () => Yup.string().required("Secret Key is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SIDConfigure = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [providerList, setProviderList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: randomUUID(),
        company_id: values.company_common_name.value,
        sub_merchant_id: values.sub_merchant_id.trim(),
        virtual_account_number: values.virtual_account_number.trim(),
        upi_id: values.upi_id.trim(),
        merchant_category_code: values.merchant_category_code.toString().trim(),
        business_type_code: values.business_type_code.toString().trim(),
        provider_code: values.provider_code.label.trim(),
      };
      if (values.provider_code.label === "npst")
        payloadData["is_sub_merchant"] = values.is_sub_merchant;

      if (values.provider_code.label === "phnp")
        payloadData["secret_key"] = values.secret_key;

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.SID_CONFIGURATION.baseUrl +
          apiEndpointList.SID_CONFIGURATION.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });


  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };


  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        // Currently, showing all providers but only 'npst' and 'phnp' code are valid
        // Providers API will be refactored later on
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.four_character_bank_code.toLowerCase(),
        }));
        setProviderList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getProviderList();
  }, []);

  useEffect(() => {
    if (formik.values.provider_code?.label === "npst") {
      formik.setFieldValue("is_sub_merchant", "");
      formik.setFieldTouched("is_sub_merchant", false, false);
    }
    if (formik.values.provider_code?.label === "phnp") {
      formik.setFieldValue("secret_key", "");
      formik.setFieldTouched("secret_key", false, false);
    }
  }, [formik.values.provider_code]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-divider"></div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_common_name"
                name="company_common_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_common_name", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_common_name", true)
                }
                value={formik.values.company_common_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company Common Name"
                required={true}
                isLoading={isLoading}
                placeholder="Select Common Name"
                isformatOptionLabel={true}
              />
              <TextFieldInput
                id="sub_merchant_id"
                name="sub_merchant_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sub_merchant_id}
                touched={formik.touched.sub_merchant_id}
                error={formik.errors.sub_merchant_id}
                placeholder="Enter Sub-Merchant ID"
                label="Sub-Merchant ID"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="virtual_account_number"
                name="virtual_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.virtual_account_number}
                touched={formik.touched.virtual_account_number}
                error={formik.errors.virtual_account_number}
                placeholder="Enter Virtual Account Number"
                label="Virtual Account Number"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="upi_id"
                name="upi_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.upi_id}
                touched={formik.touched.upi_id}
                error={formik.errors.upi_id}
                placeholder="Enter UPI ID"
                label="UPI ID"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider Code"
                required={true}
                isLoading={isLoading}
                placeholder="Select Provider"
                isformatOptionLabel={true}
              />
              <TextFieldInput
                id="business_type_code"
                name="business_type_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.business_type_code}
                touched={formik.touched.business_type_code}
                error={formik.errors.business_type_code}
                placeholder="Enter Business Type Code"
                label="Business Type"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="merchant_category_code"
                name="merchant_category_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.merchant_category_code}
                touched={formik.touched.merchant_category_code}
                error={formik.errors.merchant_category_code}
                placeholder="Enter Merchant Category Code"
                label="Merchant Category Code"
                required={true}
                disabled={false}
              />
              {formik.values.provider_code?.label === "npst" ? (
                <div className={`ui-form-input-section ui-form-content-input`}>
                  <label>
                    <input
                      type="radio"
                      id="is_sub_merchant_yes"
                      name="is_sub_merchant"
                      value="1"
                      onChange={formik.handleChange}
                      checked={formik.values.is_sub_merchant === "1"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      id="is_sub_merchant_no"
                      name="is_sub_merchant"
                      value="0"
                      onChange={formik.handleChange}
                      checked={formik.values.is_sub_merchant === "0"}
                    />
                    No
                  </label>
                  <label htmlFor="is_sub_merchant" className="ui-label">
                    {"Is Sub-Merchant?"}{" "}
                    <span className="required-field">*</span>
                  </label>
                </div>
              ) : null}
              {formik.values.provider_code?.label === "phnp" ? (
                <TextFieldInput
                  id="secret_key"
                  name="secret_key"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.secret_key}
                  touched={formik.touched.secret_key}
                  error={formik.errors.secret_key}
                  placeholder="Enter Secret Key"
                  label="Secret Key"
                  required={true}
                  disabled={false}
                />
              ) : null}
            </div>
            <div className="configuration-details-submit ui-button-container">
              <button
                className={`configuration-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default SIDConfigure;
