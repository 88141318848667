import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

//component-import
import { Modal } from "../Modal/Modal";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import TextFieldInputCustom from "../../../UI/TextField/TextFieldInputCustom";

// context imports
import IPManagementActionContext from "../../../contexts/IPManagementActionContext";

// Utils import
import { randomUUID } from "../../../services/randomUUID";
import MultiInputField from "../../../UI/MultiInputField/MultiInputField";
import { REGEXP } from "../../../utilities/validators/inputValidators";
import IPManagementSuccessDialog from "../IPManagementSuccessDialog/IPManagementSuccessDialog";

// Define validation schema with Yup
const validationSchema = Yup.object({
  requestType: Yup.string().required("Request Type is required"),
  ipAddresses: Yup.array()
    .of(
      Yup.string()
        .required("IP Address is required")
        .matches(REGEXP.ipAddressRegex, "Invalid IP address")
    )
    .max(3, "You can only enter up to 3 IP addresses")
    .required("At least one IP Address is required")
    .test(
      "at-least-one-ip-address",
      "At least one IP Address is required",
      (value) => value && value.length > 0
    ),
});

const ManageIpsForm = () => {
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  const backToAllCompanies = () => {
    allCompanies();
  };

  const { allCompanies, tableEditData, openModal, setOpenModal, showModal } =
    useContext(IPManagementActionContext);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const handleSubmit = (formik) => {
    const { values, action } = formik;

    const payload = {
      ip_address: values.ipAddresses,
      request_type: values.requestType,
      company_id: tableEditData.id,
    };

    formik.setSubmitting(true);
    APIConfig.API_Client.post(
      apiEndpointList.REQUEST_IP_WHITELIST.baseUrl +
        apiEndpointList.REQUEST_IP_WHITELIST.endpoint,
      payload
    )
      .then((response) => {
        if (response?.data && response?.data?.error) {
          // API call successful but with an error response
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
        } else {
          // Handle successful response
          if (response?.data?.status === "Failed") {
            ReactDOM.render(
              <SnackbarMessage
                msgtype="error"
                msg={response?.data?.message || "Something went wrong!"}
              />,
              document.getElementById("snackbar")
            );
          } else if (response?.data?.status === "Success") {
            ReactDOM.render(
              <IPManagementSuccessDialog data={response.data} />,
              document.getElementById("add-customer-components")
            );
            formik.resetForm();
          }
        }
      })
      .catch((error) => {
        // Handle request error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={
              error?.response?.message ||
              error?.response?.data?.message ||
              "Something went wrong!"
            }
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        showModal();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      requestType: tableEditData.pluginCreated ? "update" : "create",
      ipAddresses: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  const extraHint = {
    create: "This will create a new plugin If no IP address is allowed",
    update: "This will add to the allowed list of IP addresses",
  };

  return (
    <div className="edit-credits-page">
      <div className="edit-credits-content">
        <div className="edit-credits-card">
          <div className="available-modules-container">
            <div className="available-modules-header">
              <div onClick={backToAllCompanies} className="header-container">
                <img src="/images/back.svg" alt="back-icon" />
                <h1 className="heading">
                  Edit IPs{" "}
                  <span className="company-name-highlight">
                    {" "}
                    {tableEditData.companyName}
                  </span>
                </h1>
              </div>
            </div>
            <div className="subscribed-module-list-container">
              <div className="credits-management-divider"></div>
              <div className="edit-credit-card">
                <div className="credit-details-container">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="ui-form-details">
                      <h2 className="ui-form-title">
                        Fill IP details to continue
                      </h2>
                      <div className="ui-form-content">
                        <div className="ui-form-inputs-section">
                          <TextFieldInputCustom
                            id="requestType"
                            name="requestType"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.requestType}
                            label="Request Type"
                            required={true}
                            placeholder="Select Request Type"
                            touched={formik.touched.requestType}
                            error={formik.errors.requestType}
                            disabled={true}
                            displayValue={
                              formik.values.requestType === "update"
                                ? "Add"
                                : "Create"
                            }
                            extraHint={extraHint}
                          />

                          <MultiInputField
                            id="ipAddresses"
                            name="ipAddresses"
                            value={formik.values.ipAddresses}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                            touched={formik.touched.ipAddresses}
                            error={formik.errors.ipAddresses}
                            placeholder={
                              formik.values.ipAddresses.length > 0
                                ? ""
                                : "Enter IP Address(es)"
                            }
                            label="IP Address(es)"
                            required={true}
                            extraHint={
                              "Press Enter after entering each IP Address. Max 3 IP Addresses allowed"
                            }
                            singleFieldErrorText="Invalid IP addresses"
                          />
                        </div>
                        <div className="transaction-details-submit ui-button-container">
                          <button
                            className={`transaction-details-submit ${
                              formik.isValid &&
                              formik.dirty &&
                              !formik.isSubmitting
                                ? "active"
                                : ""
                            }`}
                            type="submit"
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            {formik.isSubmitting ? "Loading..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {openModal ? (
                <Modal
                  showTotp={showTotp}
                  setShowTotp={setShowTotp}
                  qrBase={qrBase}
                  dataToVerify={dataToVerify}
                  handleSubmitUser={(formik) => handleSubmit(formik)}
                  formik={formik}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageIpsForm;
