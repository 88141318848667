import ReactDOM from "react-dom";

import {
  IAuthTokens,
  getAccessToken,
  getRefreshToken,
  clearAuthTokens,
  setAuthTokens,
} from "axios-jwt";
import axios from "axios";
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
if (!BASE_URL) throw new Error("BASE_URL is not defined");

export interface IAuthResponse {
  access_token: string;
  refresh_token: string;
}

export const AuthResponseToAuthTokens = (res: IAuthResponse): IAuthTokens => ({
  accessToken: res.access_token,
  refreshToken: res.refresh_token,
});

export let APIConfig = {
  BASE_URL: BASE_URL,
  API_Client: axios.create(),
};

APIConfig.API_Client.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["client_id"] = JSON.parse(
        localStorage.getItem("user")
      ).client_id;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

APIConfig.API_Client.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === BASE_URL + "/user/internal/auth"
    ) {
      clearAuthTokens();
      window.location.reload();
      return Promise.reject(error);
    }
    if (
      error.response &&
      [401, 400].includes(error.response.status) &&
      (error.response.data.response_key === "error_authentication_failed" ||
        error.response.data.message === "Signature has expired") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .get(BASE_URL + "/user/internal/auth", {
          headers: { Authorization: "Bearer " + getRefreshToken() },
        })
        .then((response) => {
          if (response.status === 200) {
            response.data.refresh_token = getRefreshToken();
            setAuthTokens(AuthResponseToAuthTokens(response.data));
            return APIConfig.API_Client(originalRequest);
          }
        })
        .catch((error) => {
          console.log(
            "🚀 🚀 🚀 ~ file: apiConfiguration.js:85 ~ error:",
            error
          );

          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong!"}
            />,
            document.getElementById("snackbar")
          );
          // clearAuthTokens();
          // window.location.reload();
        });
    }

    return Promise.reject(error);
  }
);

// ! Form Data

export let APIConfigFormData = {
  BASE_URL: BASE_URL,
  API_Client: axios.create(),
};
APIConfigFormData.API_Client.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["client_id"] = JSON.parse(
        localStorage.getItem("user")
      ).client_id;
    }
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
APIConfigFormData.API_Client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      originalRequest.url === BASE_URL + "/user/internal/auth"
    ) {
      clearAuthTokens();
      window.location.reload();
      return Promise.reject(error);
    }
    if (
      error.response &&
      error.response.status === 401 &&
      (error.response.data.response_key === "error_authentication_failed" ||
        error.response.data.message === "Signature has expired") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .get(BASE_URL + "/user/internal/auth", {
          headers: { Authorization: "Bearer " + getRefreshToken() },
        })
        .then((response) => {
          if (response.status === 200) {
            response.data.refresh_token = getRefreshToken();
            setAuthTokens(AuthResponseToAuthTokens(response.data));
            return APIConfigFormData.API_Client(originalRequest);
          }
        })
        .catch((error) => {
          console.log(
            "🚀 🚀 🚀 ~ file: apiConfiguration.js:157 ~ error:",
            error
          );
          // clearAuthTokens();
          // window.location.reload();
        });
    }
    return Promise.reject(error);
  }
);
