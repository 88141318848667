import React from "react";

//component-import
import SingleTransactionSuccess from "./MarkTransaction/SingleTransactionSuccess";
import BulkTransactionSuccess from "./MarkTransaction/BulkTransactionSuccess";
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";

const MarkAsSuccess = () => {
 
  return (
    <React.Fragment>
     <TabComponentWrapper
        heading="Mark As Success"
        tabs={[
          {
            className: "single-success",
            label: "Single",
            value: "single",
            component: <SingleTransactionSuccess />,
          },
          {
            className: "bulk-success",
            label: "Bulk",
            value: "bulk",
            component: <BulkTransactionSuccess />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default MarkAsSuccess;
