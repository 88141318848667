import React, { useState, useEffect, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  decentro_txn_id: "",
  company_common_name: "",
  bank_reference_number: "",
};

const Regex = REGEXP.alphanumericRegex;
const validationSchema = Yup.object({
  decentro_txn_id: Yup.string()
    .matches(Regex)
    .required("Transaction ID is required"),
  // company_common_name: //! no validation as it's an optional dropdown field
  bank_reference_number: Yup.string().max(50).matches(Regex),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const InitiateReversal = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      // Build the payload by iterating over the keys and adding non-empty values
      const payloadData = Object.entries(values).reduce((acc, [key, value]) => {
        if (key === "company_common_name" && value?.label) {
          acc[key] = value.label; // Handle nested object for company_common_name
        } else if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.INITIATE_TRANSACTION_REVERSAL.baseUrl +
          apiEndpointList.INITIATE_TRANSACTION_REVERSAL.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">
            Fill transaction details to continue
          </h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                // id="decentro_txn_id"
                name="decentro_txn_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.decentro_txn_id}
                touched={formik.touched.decentro_txn_id}
                error={formik.errors.decentro_txn_id}
                placeholder="Enter Transaction ID"
                label="Transaction ID"
                required={true}
                disabled={false}
                isToolTip={true ? "Decentro Company URN" : null}
              />
              <TextFieldSelect
                id="company_common_name"
                name="company_common_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_common_name", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_common_name", true)
                }
                value={formik.values.company_common_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company Common Name"
                required={false}
                isLoading={isLoading}
                placeholder="Select Common Name"
                isformatOptionLabel={true}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="bank_reference_number"
                name="bank_reference_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_reference_number}
                touched={formik.touched.bank_reference_number}
                error={formik.errors.bank_reference_number}
                placeholder="Enter Bank Reference Number"
                label="Bank Reference Number"
                required={false}
                disabled={false}
              />
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default InitiateReversal;
