import React from "react";
import ReactDOM from "react-dom";

import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";

import "./UpdateCompanySuccessDialog.scss";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

let successResponseData;

function UpdateCompanySuccessDialog(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
    window.location.reload();
  };

  function copyResponseDetail() {
    const elTable = document.querySelector(".update-company-data");
    let range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={"Copied to clipboard"} />,
          document.getElementById("snackbar")
        );
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }
    sel.removeAllRanges();
  }

  successResponseData = props.data;
  return (
    <React.Fragment>
      <Dialog
        className="customer-update-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-heading">
            <p>Update Company Details</p>
          </div>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>Company Details updated successfully</h3>
              {/* <p>{successResponseData?.message || "NA"}</p> */}
            </div>
            <div className="success-account-details">
              <table className="update-company-data">
                <tbody>
                  <tr>
                    <td className="detail-heading">Company Name</td>
                    <td className="detail-value title-case">
                      {successResponseData?.data?.commonName || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Registered Name</td>
                    <td className="detail-value title-case">
                      {successResponseData?.data?.fullName || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Official Email</td>
                    <td className="detail-value">
                      {successResponseData?.data?.emailId || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Website</td>
                    <td className="detail-value">
                      {successResponseData?.data?.website || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Mobile Number</td>
                    <td className="detail-value">
                      {successResponseData?.data?.mobileNumber || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Address Line</td>
                    <td className="detail-value">
                      {successResponseData?.data?.address?.addressLine || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">City</td>
                    <td className="detail-value">
                      {successResponseData?.data?.address?.city || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Pincode</td>
                    <td className="detail-value">
                      {successResponseData?.data?.address?.pincode || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">District</td>
                    <td className="detail-value">
                      {successResponseData?.data?.address?.district || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">State</td>
                    <td className="detail-value">
                      {successResponseData?.data?.address?.state || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Country</td>
                    <td className="detail-value">
                      {successResponseData?.data?.address?.country || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">GST Number</td>
                    <td className="detail-value">
                      {successResponseData?.data?.gstin || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Category</td>
                    <td className="detail-value">
                      {successResponseData?.data?.category || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">BD POC</td>
                    <td className="detail-value">
                      {successResponseData?.data?.bd_poc || "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Partner ID</td>
                    <td className="detail-value">
                      {successResponseData?.data?.partner_id || "NA"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                className="copy-account-details"
                onClick={copyResponseDetail}
                type="button"
              >
                <img src="/images/copy-icon.svg" alt="copy-icon" />
                Copy
              </button>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default UpdateCompanySuccessDialog;
