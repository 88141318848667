import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
// component styles
import CloseIcon from "@material-ui/icons/Close";
import EditHttpDropdown from "../../EditCallbackModal/EditHttpDropdown/EditHttpDropdown";
import { EditTotpModal } from "../EditTotpModal/EditTotpModal";
import { randomUUID } from "../../../../services/randomUUID";
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
// context imports
import CallbackContext from "../../../../contexts/CallbackContext";
// styles imports
import "./Modal.scss";

export const Modal = () => {
  // ^ context
  const { setShowModal, editCallbackItem, editCallback, searchCompanyID } =
    useContext(CallbackContext);

  // * if user clicks on submit after changing callback, totpModal will open.
  const [isTotpModal, setIsTotpModal] = useState(false);
  //manage modal state
  const [openModal, setOpenModal] = useState(false);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  // input Fields
  const [callbackField, setCallbackField] = useState("");
  const [headerField, setHeaderField] = useState("");
  const [urlField, setUrlField] = useState("");
  // button validation
  const [isDisabled, setIsDisabled] = useState(true);

  // dropDown field
  const [httpId, setHttpId] = useState("");
  const [httpTypeName, setHttpTypeName] = useState(editCallbackItem.httpMethod);

  // validations
  const headerValidtion = useRef(false);
  const urlValidation = useRef(false);

  // autoFill fields side Effect
  useEffect(() => {
    setCallbackField(
      editCallbackItem?.callbackType?.replaceAll("_", " ") || ""
    );
    setHeaderField(JSON.stringify(editCallbackItem?.subscriptionHeaders) || "");
    setUrlField(editCallbackItem?.subscriberUrl || "");
    setHttpId(editCallbackItem?.httpMethod || "");
  }, [editCallbackItem]);

  // header InputHandler
  const headerInputHandler = (e) => {
    setHeaderField(e.target.value);
    try {
      JSON.parse(e.target.value);
      headerValidtion.current = false;
    } catch (e) {
      headerValidtion.current = true;
    }
  };

  // url InputHandler
  const urlInputHandler = (e) => {
    setUrlField(e.target.value);

    var urlRegExp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if (urlRegExp.test(e.target.value)) {
      urlValidation.current = false;
    } else {
      urlValidation.current = true;
    }
  };

  // Btn validation side effect
  useEffect(() => {
    if (
      !!httpId &&
      // !!headerField &&
      // headerField.length !== 0 &&
      !!urlField &&
      urlField.length !== 0 &&
      // !headerValidtion.current &&
      !urlValidation.current
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [urlValidation, httpId, headerField, urlField]);

  // submit handler
  const submitHandler = () => {
    // let payload = {
    //   company_id: +editCallbackItem.companyId,
    //   subscriber_url: urlField,
    //   http_method: +httpId,
    //   subscription_protocol:
    //     urlField.indexOf("https://") === 0 ? "https" : "http",
    //   subscription_headers: JSON.parse(headerField),
    //   callback_type: editCallbackItem.callbackType,
    // };

    // ! updated api payload
    let newPayload = {
      reference_id: randomUUID(),
      company_id: +searchCompanyID,
      subscriber_url: urlField,
      http_method: httpTypeName, // https type name - new upload
      subscription_protocol:
        urlField.indexOf("https://") === 0 ? "https" : "http",
      subscription_headers: JSON.parse(headerField) || "",
      callback_type: editCallbackItem.callbackType, // callback type - updated payload
    };

    editCallback(newPayload);
  };

  // this will invoke to generate totp
  const handleSubmit = () => {
    const companyInput = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      companyInput
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: companyInput.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        setIsTotpModal(true);
        setOpenModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  // render the modal JSX in the portal div (index.html).
  return ReactDom.createPortal(
    <>
      {/* Edit callback modal will only appear if "isTotpModal" is false */}
      {!isTotpModal && (
        <div className="container" ref={modalRef} onClick={closeModal}>
          <div className="modal">
            {/* close modal icon */}
            <button
              className="close-modal-btn"
              onClick={() => setShowModal(false)}
            >
              <CloseIcon />
            </button>
            {/* modal header */}
            <div className="edit-callback-heading">
              <p>Edit Callback</p>
            </div>
            <p className="edit-callback-subtitle">
              Here are some quick details you need to edit
            </p>

            {/* modal fields */}
            <div className="edit-callback-fields">
              {/* callback input */}
              <div className="text-input-wrapper">
                <div className="text-input-label">Callback</div>
                <div className={`add-callback-input callback-read-only`}>
                  <input
                    type="text"
                    value={callbackField}
                    placeholder="Enter Callback"
                    className="callback-field-input callback-default-filled"
                    readOnly
                  />
                </div>
              </div>

              {/* http dropdown */}
              <div className="dropdown-input-wrapper">
                <div className="http-input-label">Http</div>
                <EditHttpDropdown
                  setHttpId={setHttpId}
                  setHttpTypeName={setHttpTypeName}
                  editData={editCallbackItem}
                />
              </div>

              {/* header input */}
              <div className="text-input-wrapper">
                <div className="text-input-label-without-required">Header</div>
                <div
                  className={`add-callback-input ${
                    headerValidtion.current === true && "error-border"
                  } `}
                >
                  <input
                    type="text"
                    placeholder={JSON.stringify({
                      key1: "value1",
                      key2: "value2",
                    })}
                    value={headerField}
                    className="callback-field-input"
                    onChange={headerInputHandler}
                  />
                </div>
              </div>

              {/* url input */}
              <div className="text-input-wrapper">
                <div className="text-input-label">Url</div>
                <div
                  className={`add-callback-input ${
                    urlValidation.current === true && "error-border"
                  } `}
                >
                  <input
                    type="text"
                    placeholder={"https://www.placeholder.com"}
                    value={urlField}
                    className="callback-field-input"
                    onChange={urlInputHandler}
                  />
                </div>
              </div>
            </div>

            {/* update callback btn */}
            <div className="edit-button-wrapper">
              <button
                className={`update-callback-details ${
                  !isDisabled && "active-update-btn"
                }`}
                onClick={() => {
                  handleSubmit();
                }}
                type="button"
                disabled={isDisabled}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {/* EditTotp modal will open after generating TOTP from above modal*/}
      {isTotpModal && (
        <EditTotpModal
          setIsTotpModal={setIsTotpModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitAfterTotp={submitHandler}
        />
      )}
    </>,
    document.getElementById("portal")
  );
};
