import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  account_number: "",
  purpose: "",
  amount: "",
  bank_reference_number: "",
  provider: "",
  transfer_type: "",
  currency_code: "INR",
};

const {
  accountNumberRegex,
  alphanumericRegex,
  alphanumericWithWhitespaceRegex,
} = REGEXP;
const validationSchema = Yup.object({
  account_number: Yup.string()
    .matches(accountNumberRegex)
    .required("Account Number is required"),
  purpose: Yup.string()
    .min(5)
    .max(50)
    .matches(alphanumericWithWhitespaceRegex)
    .required("Purpose message is required"),
  bank_reference_number: Yup.string()
    .matches(alphanumericRegex),
  amount: Yup.number()
    .positive()
    .test(
      "is-amount",
      "Must be a valid number with up to two decimal places",
      (number) => /^\d+(\.\d{1,2})?$/.test(number)
    )
    .required("Amount is required"),
  provider: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  transfer_type: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleUnloadAccount = () => {
  const [providerList, setProviderList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: randomUUID(),
        account_number: values.account_number,
        purpose: values.purpose,
        bank_reference_number: values.bank_reference_number,
        amount: parseFloat(values.amount),
        provider: values.provider.label,
        transfer_type: values.transfer_type.label,
        currency_code: values.currency_code,
      };

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.UNLOAD_ACCOUNT.baseUrl +
          apiEndpointList.UNLOAD_ACCOUNT.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.four_character_bank_code.toLowerCase(),
        }));
        setProviderList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getProviderList();
  }, []);

  const transferType = [
    { value: 1, label: "IMPS" },
    { value: 2, label: "NEFT" },
    { value: 3, label: "RTGS" },
  ];

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Fill details to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="currency_code"
                name="currency_code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currency_code}
                touched={formik.touched.currency_code}
                error={formik.errors.currency_code}
                label="Currency Code"
                required={true}
                disabled={true}
              />
              <TextFieldSelect
                id="transfer_type"
                name="transfer_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("transfer_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("transfer_type", true)}
                value={formik.values.transfer_type}
                options={transferType}
                noOptionsMessage={() => "No such transfer type exists"}
                label="Transfer Type"
                required={true}
                isLoading={isLoading}
                placeholder="Select Transfer Type"
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider"
                name="provider"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider", true)}
                value={formik.values.provider}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider Code"
                required={true}
                isLoading={isLoading}
                placeholder="Select Provider"
                isformatOptionLabel={true}
              />

              <TextFieldInput
                id="account_number"
                name="account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.account_number}
                touched={formik.touched.account_number}
                error={formik.errors.account_number}
                placeholder="Enter Account Number"
                label="Account Number"
                required={true}
                disabled={false}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose"
                name="purpose"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose}
                touched={formik.touched.purpose}
                error={formik.errors.purpose}
                placeholder="Enter Purpose Message"
                label="Purpose Message"
                maxLength={50}
                required={true}
                isToolTip={true ? "Min 5 characters" : null}
              />
              <TextFieldInput
                id="amount"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="Enter Amount"
                label="Amount"
                required={true}
                isToolTip={true ? "Upto 2 decimal places" : null}
              />
            </div>
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="bank_reference_number"
                name="bank_reference_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_reference_number}
                touched={formik.touched.bank_reference_number}
                error={formik.errors.bank_reference_number}
                placeholder="Enter Bank Reference Number"
                label="Bank Reference Number"
                required={false}
                maxLength={100}
              />
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleUnloadAccount;
