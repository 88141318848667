/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect, useMemo } from "react";
// API Config imports
import axios from "axios";
// Components Imports
import DataTable from "../SortTableSummary/DataTable";
import TableLoader from "../Shared/TableLoader/TableLoader";
import Error from "../Shared/Error/Error";
// Utility Imports
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
// Styles Imports
import "./CreditConsumption.scss";

const CreditConsumption = () => {
  const [creditsData, setCreditsData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [tableData, setTableData] = useState([]);
  // Error Handling states
  const [isError, setIsError] = useState(false);
  const [errorResponse, setErrorResponse] = useState({
    status: "",
    message: "",
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const companyHeaders = useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        columns: [
          {
            Header: "Company Id",
            accessor: "companyId",
            className: "header-small",
            sort: true,
          },
          {
            Header: "Company Name",
            accessor: "commonName",
            className: "header-medium",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.commonName !== undefined
                  ? row.row.original.commonName
                  : "-"}
              </div>
            ),
          },
          {
            Header: "KYC Credits",
            accessor: "kyc_and_onboarding",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.kyc_and_onboarding !== undefined
                  ? row.row.original.kyc_and_onboarding
                  : "-"}
              </div>
            ),
          },
          {
            Header: "FS Service Credits",
            accessor: "financial_services",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.financial_services !== undefined
                  ? row.row.original.financial_services
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Accounts Credits",
            accessor: "core_banking",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.core_banking !== undefined
                  ? row.row.original.core_banking
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Payment Credits",
            accessor: "payments",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.payments !== undefined
                  ? row.row.original.payments
                  : "-"}
              </div>
            ),
          },
          {
            Header: "PPI Credits",
            accessor: "ppi_wallet",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.ppi_wallet !== undefined
                  ? row.row.original.ppi_wallet
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Lending",
            accessor: "lending",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.lending !== undefined
                  ? row.row.original.lending
                  : "-"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  // getting companyId along with all the credits for available modules
  const getAPIModuleData = () => {
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_API_METER_SALAD.baseUrl +
        apiEndpointList.COMPANY_API_METER_SALAD.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        // Transforming structure of Data.
        const flattenResponse = response.data.map((item) => {
          const result = {
            companyId: item.companyId,
          };
          item.apiMeterDetails.forEach((detail) => {
            result[detail.moduleCode.toLowerCase()] = detail.maxUsableCredits;
          });
          return result;
        });
        setCreditsData(flattenResponse);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error.response.status,
          message: error.message,
        });
      });
  };

  // getting company name and companyId
  const getCompanyData = () => {
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setCompanyData(response.data.data);
      })
      .catch((error) => {
        setIsError(true);
        setErrorResponse({
          status: error.response.status,
          message: error.message,
        });
      });
  };

  // adding company's common name and credit details in single object by matching companyId.
  const tableDataSummary = () => {
    setTableData(
      creditsData.map((eachCreditsData) => {
        const matchingItemTwo = companyData.find(
          (eachCompanyData) =>
            eachCompanyData.companyId === eachCreditsData.companyId
        );
        return { ...eachCreditsData, ...matchingItemTwo };
      })
    );
  };

  useEffect(() => {
    getAPIModuleData();
    getCompanyData();
  }, []);

  useEffect(() => {
    tableDataSummary();
  }, [creditsData, companyData]);

  return (
    <>
      <div className="credits-consumption-page">
        <div className="credits-consumption-content">
          <div className="credits-consumption">
            <h1 className="heading">Credits Consumption</h1>
          </div>

          <div className="credits-consumption-card">
            {!isError ? (
              tableData.length > 0 ? (
                <DataTable
                  columns={companyHeaders}
                  data={tableData}
                  csvName="CreditConsumption"
                />
              ) : (
                <TableLoader />
              )
            ) : (
              <Error type={errorResponse} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditConsumption;
