import React, { createContext, useState } from "react";

const CreditsActionContext = createContext();

export const CreditsManagementProvider = ({ children }) => {
  // type of card state
  const [cardType, setCardType] = useState("allCredits");
  // edit callback data state
  const [tableEditData, setTableEditData] = useState({ id: "", edit: false , companyName: "",});

  // jumps to add new callback form card
  const editCredits = () => {
    setCardType("editCredits");
  };

  // jumps to add all callback table card
  const allCredits = () => {
    setCardType("allCredits");
    setTableEditData({ id: "", edit: false ,companyName: ""});
  };

  return (
    <CreditsActionContext.Provider
      value={{
        cardType,
        tableEditData,
        setTableEditData,
        editCredits,
        allCredits,
      }}
    >
      {children}
    </CreditsActionContext.Provider>
  );
};

export default CreditsActionContext;
