import React from "react";

//component-import
import ComponentWrapper from "../../../UI/ComponentWrapper/ComponentWrapper";
import AddSettlementAccount from "./AddSettlementAccount/AddSettlementAccount";

const AddSettlement = () => {

  return (
    <React.Fragment>
      <ComponentWrapper
        heading="Add Settlement"
        component={<AddSettlementAccount />}
      />
    </React.Fragment>
  );
};

export default AddSettlement;
