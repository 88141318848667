import React, { useContext } from "react";
// component imports
import ManageCustomersSummary from "../SortTableSummary/ManageCustomersSummary/ManageCustomersSummary";
import EditCustomerData from "../CustomerManagement/EditCustomerData/EditCustomerData";
// context imports
import CustomerManagementActionContext from "../../contexts/CustomerManagementActionContext";
// styles imports
import "./ManageCustomers.scss";

const ManageCustomers = () => {
  const { cardType } = useContext(CustomerManagementActionContext);

  const CUSTOMER_MANAGEMENT_CARD = {
    editCustomerData: <EditCustomerData />,
    allCustomers: <ManageCustomersSummaryCard />,
  };

  return (
    <div>
      <div>{CUSTOMER_MANAGEMENT_CARD[cardType]}</div>
    </div>
  );
};

const ManageCustomersSummaryCard = () => {
  return (
    <div className="customer-management-page">
      <div className="customer-management-content">
        <div className="customer-management">
          <h1 className="heading">Update Merchant</h1>
        </div>
        <div className="customer-management-card">
          <ManageCustomersSummary />
        </div>
      </div>
    </div>
  );
};

export default ManageCustomers;
