import React, { createContext, useState } from "react";
import ReactDOM from "react-dom";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import axios from "axios";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";
import { randomUUID } from "../../services/randomUUID";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OnboardProdContext = createContext();

export const OnboardProdProvider = ({ children }) => {
  //manage modal state
  const [openModal, setOpenModal] = useState(false);
  const [tabType, setTabType] = useState("companyDetails");
  // & types of tab
  // companyDetails
  // moduleTab
  // providerTab
  // summary

  // & loading state's
  const [companyDetailsLoading, setCompanyDetailsLoading] = useState(false);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [providerLoading, setProviderLoading] = useState(false);

  // & response state's
  const [companyID, setCompanyID] = useState("");
  const [selectedModulesForProviderTab, setSelectedModulesForProviderTab] =
    useState([]);

  // & api response state's
  //@ --> company onboard response state's
  const [companyDetailsResponse, setCompanyDetailsResponse] = useState([]);
  const [companySecretResponse, setCompanySecretResponse] = useState([]);
  //@ --> module provider response state's
  const [moduleSecretResponse, setModuleSecretResponse] = useState([]);
  const [moduleProviderResponse, setModuleProviderResponse] = useState([]);
  //@ --> mva response state's
  const [mvaDetailsResponse, setMvaDetailsResponse] = useState([]);
  //@ --> create user
  const [createUserResponse, setCreateUserResponse] = useState([]);

  // & module data merge
  const [mergeData, setMergeData] = useState([]);

  // & summary details state's
  const [companySecretSummary, setCompanySecretSummary] = useState([]);
  const [moduleSecretsSummary, setModuleSecretsSummary] = useState([]);
  const [providerSecretSummary, setProviderSecretSummary] = useState([]);

  // & summary prepaid state
  const [providerIDSummaryCode, setProviderIDSummaryCode] = useState("");

  // Tab Parent state
  // * on-boarding state
  const [onboardingStep, setOnboardingStep] = React.useState(tabType);
  const [tabDisabled, setTabDisabled] = React.useState({
    companyDetails: false,
    moduleTab: true,
    providerTab: true,
    summary: true,
  });

  const showModal = () => {
    setOpenModal(true);
  };

  const updateTab = (tabName) => {
    let tempTabsData = tabDisabled;
    for (const [key] of Object.entries(tempTabsData)) {
      if (key === tabName) {
        tempTabsData[key] = false;
      } else {
        tempTabsData[key] = true;
      }
    }
    setTabDisabled(tempTabsData);
    setOnboardingStep(tabName);
  };

  // Tab to Module Tab
  const goToModuleProvider = () => {
    updateTab("moduleTab");
  };

  // Tab to Provider Tab
  const goToProvider = () => {
    updateTab("providerTab");
  };

  // Tab to Summary Tab
  const goToSummary = () => {
    updateTab("summary");
  };

  // Tab to Company Details Tab
  const goToCompanyDetails = () => {
    updateTab("companyDetails");

    // ^ setting the state initial state
    setCompanyDetailsResponse([]);
    setCompanySecretResponse([]);
    setModuleSecretResponse([]);
    setModuleProviderResponse([]);
    setMvaDetailsResponse([]);
    setCreateUserResponse([]);
    setCompanySecretSummary([]);
    setModuleSecretsSummary([]);
    setProviderSecretSummary([]);
  };

  // * Company Detail API's
  const companyDetailApi = (companyInputData, companySecretInputData) => {
    setCompanyDetailsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_DETAIL_DATA.baseUrl +
        apiEndpointList.COMPANY_DETAIL_DATA.endpoint,
      companyInputData
    )
      .then((response) => {
        setCompanyDetailsLoading(true);

        setCompanyID(response.data.data.companyId);
        companySecretInputData.company_id = response.data.data.companyId;

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        setCompanyDetailsResponse(response.data.data);
        companySecretApi(companySecretInputData);
      })
      .catch((error) => {
        setCompanyDetailsLoading(false);
        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  const companySecretApi = (companySecretInputData) => {
    setCompanyDetailsLoading(true);

    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_SECRET_DATA.baseUrl +
        apiEndpointList.COMPANY_SECRET_DATA.endpoint,
      companySecretInputData
    )
      .then((response) => {
        setCompanyDetailsLoading(false);

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setCompanySecretResponse(response.data.data);
        //& Move to Module Tab
        goToModuleProvider();
      })
      .catch((error) => {
        setCompanyDetailsLoading(false);

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  // * Module API Call
  const companyModulesApi = (selectedModuleData) => {
    const filteredData = selectedModuleData.filter(
      (moduleData) => moduleData.code !== "kyc_and_onboarding"
    );

    setSelectedModulesForProviderTab(filteredData);

    // ! un-comment below code commented only for testing the state
    setModuleLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_MODULE.baseUrl +
        apiEndpointList.COMPANY_MODULE.endpoint,
      { company_id: companyID, module_details: selectedModuleData }
    )
      .then((response) => {
        setModuleLoading(true);
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        setModuleSecretResponse(response.data.data.moduleDetails);

        //& This condition exist as if only KYC is elected they is no need of Provider API to get a call
        if (
          response.data.data.moduleDetails.length === 1 &&
          response.data.data.moduleDetails[0].code === "kyc_and_onboarding"
        ) {
          //& Move to Summary Tab
          goToSummary();
          // ^ create user api
          createUserApi();
        } else {
          //^ Move to Provider Tab
          goToProvider();
          // ! below api call to be deleted - reason : seperate API calls to be made below
          // providerSecretApi(providerSecretData);
        }
      })
      .catch((error) => {
        setModuleLoading(false);

        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );

        //& setting the summary to null as mapping issue based on virtual account setup error
        setProviderIDSummaryCode("");
      });
  };

  // ^ Provider API Call
  const providerSecretApi = (providerSecretData) => {
    setProviderLoading(true);

    APIConfig.API_Client.post(
      apiEndpointList.COMPANY_PROVIDER_SECRET.baseUrl +
        apiEndpointList.COMPANY_PROVIDER_SECRET.endpoint,
      { company_id: companyID, provider_details: providerSecretData }
    )
      .then((response) => {
        setProviderLoading(false);
        setModuleProviderResponse(response.data.data.providerDetails);

        // ^ create user api
        createUserApi();

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        // & Move to Summary Tab
        goToSummary();
      })
      .catch((error) => {
        setProviderLoading(false);

        ReactDOM.render(
          <SnackbarMessage msgtype="Error" msg={error.response.data.message} />,
          document.getElementById("snackbar")
        );
      });
  };

  // ^ MVA configure API call
  const configureMVA = (payloadData) => {
    payloadData.company_id = companyID;

    APIConfig.API_Client.defaults.headers.post["provider_code"] =
      payloadData.provider_code;

    APIConfig.API_Client.post(
      apiEndpointList.CONFIGURE_MVA.baseUrl +
        apiEndpointList.CONFIGURE_MVA.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        setMvaDetailsResponse((prevResponse) => [
          ...prevResponse,
          response.data.data,
        ]);

        // & Move to Summary Tab
        // goToSummary();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ^ create user
  const createUserApi = () => {
    let payload = {
      company_id: companyID,
      user_type: "4",
      name: companyDetailsResponse.fullName,
      email: companyDetailsResponse.emailId,
      mobile: companyDetailsResponse.mobileNumber,
      set_active: true,
      password: randomUUID(),
    };

    APIConfig.API_Client.post(
      apiEndpointList.USER_REGISTER.baseUrl +
        apiEndpointList.USER_REGISTER.endpoint,
      payload,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setCreateUserResponse(payload);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "User creation failed!"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <OnboardProdContext.Provider
      value={{
        companyID,
        tabType,
        setTabType,
        onboardingStep,
        tabDisabled,
        updateTab,
        goToModuleProvider,
        goToSummary,
        goToCompanyDetails,
        companyDetailApi,
        companyModulesApi,
        providerSecretApi,
        configureMVA,
        companyDetailsResponse,
        companySecretResponse,
        moduleSecretResponse,
        moduleProviderResponse,
        mvaDetailsResponse,
        createUserResponse,
        companyDetailsLoading,
        moduleLoading,
        moduleSecretsSummary,
        setModuleSecretsSummary,
        providerSecretSummary,
        setProviderSecretSummary,
        companySecretSummary,
        setCompanySecretSummary,
        providerIDSummaryCode,
        setProviderIDSummaryCode,
        // delete below one
        goToModuleProvider,
        goToProvider,
        selectedModulesForProviderTab,
        providerLoading,
        showModal,
        openModal,
        setOpenModal,
      }}
    >
      {children}
    </OnboardProdContext.Provider>
  );
};

export default OnboardProdContext;
