import React from "react";
import ReactDom from "react-dom";
import { useHistory } from "react-router-dom";
// utils
import { clearAuthTokens } from "axios-jwt";
// styles
import "./LogoutModal.scss";

export const LogoutModal = () => {
  const history = useHistory();
  // logout actions: clear authentication tokens, refresh page.
  const loginAgainHandler = () => {
    clearAuthTokens();
    // this will clear unwanted values.
    localStorage.removeItem("currentActiveTabIndex");
    localStorage.removeItem("currentSubmenu");
    localStorage.clear();
    history.push("/login");
    window.location.reload(true);
  };
  return ReactDom.createPortal(
    <>
      <div className="logout-container">
        <div className="relogin-modal">
          {/* modal header */}
          <div className="relogin-heading">
            <p>Oops! Session Expired</p>
          </div>
          <p className="relogin-subheading">
            The session has expired please login again.
          </p>

          {/* update callback btn */}
          <div className="relogin-button-wrapper">
            <button className="relogin-btn" onClick={loginAgainHandler}>
              Login Again
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
