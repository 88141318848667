import Settlement from "./Settlement";

const SettlementCycle = () => {
  return (
    <>
      <Settlement />
    </>
  );
};

export default SettlementCycle;
