import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//component-import
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

let currentDate = new Date();
const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);
currentDate = currentDate.toISOString().slice(0,16);

const initialValues = {
  start_datetime: "",
  end_datetime:"",
  master_consumer_bank_account_alias:""
};

const validationSchema = Yup.object({
    start_datetime: Yup.date().required("Start datetime is required").min(currentDate),
    end_datetime: Yup.date().required("End datetime is required").min(currentDate),
    master_consumer_bank_account_alias: Yup.object().shape({
      value: Yup.number().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const PauseSettlementInternal = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [cbaAliasList, setCBAAliasList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {

      const payloadData = {
        reference_id: randomUUID(),
        start_datetime: values.start_datetime + ":00",
        end_datetime: values.end_datetime + ":00",
        underlying_account_id: values.master_consumer_bank_account_alias.underlying_account_id
      };

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.PAUSE_SETTLEMENT.baseUrl +
          apiEndpointList.PAUSE_SETTLEMENT.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getMasterCBAAliasList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_MASTER_CBA_ALIAS.baseUrl +
        apiEndpointList.FETCH_MASTER_CBA_ALIAS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item, i) => ({
          value: i,
          label: item.alias,
          underlying_account_id: item.account_id,
        }));
        setCBAAliasList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        setCBAAliasList([]);
        setIsLoading(false);
        setIsError(true);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to fetch Master CBA Alias list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getMasterCBAAliasList();
  }, []);

  const handleStartDateTimeSelect = (e) => {
    const date = e.target.value;
    if (!date || new Date(date) >= new Date(currentDate) )
        formik.setFieldValue("start_datetime", date);
    };

  const handleEndDateTimeSelect = (e) => {
    const date = e.target.value;
    if (!date || new Date(date) >= new Date(currentDate) )
        formik.setFieldValue("end_datetime", date);
    };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
        <div className="ui-divider"></div>
          <div className="ui-form-content">
              <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                  <input
                    type="datetime-local"
                    id="start_datetime"
                    name="start_datetime"
                    onChange={handleStartDateTimeSelect}
                    value={formik.values.start_datetime}
                    required={true}
                    min={currentDate}
                  />
                  <label htmlFor="start_datetime" className="ui-file-label">
                    {"Start Datetime"}{" "}
                    <span className="required-field">*</span>
                  </label>
                </div>
                <div className={`ui-form-input-section ui-form-content-input`}>
                  <input
                    type="datetime-local"
                    id="end_datetime"
                    name="end_datetime"
                    onChange={handleEndDateTimeSelect}
                    value={formik.values.end_datetime}
                    required={true}
                    min={currentDate}
                  />
                  <label htmlFor="end_datetime" className="ui-file-label">
                    {"End Datetime"}{" "}
                    <span className="required-field">*</span>
                  </label>
                </div>
            </div>
            <div className="ui-form-inputs-section">
            <TextFieldSelect
                  id="master_consumer_bank_account_alias"
                  name="master_consumer_bank_account_alias"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "master_consumer_bank_account_alias",
                      selectedOption
                    )
                  }
                  onBlur={() =>
                    formik.setFieldTouched(
                      "master_consumer_bank_account_alias",
                      true
                    )
                  }
                  value={formik.values.master_consumer_bank_account_alias}
                  options={cbaAliasList}
                  noOptionsMessage={() => "No such alias exists"}
                  label="Master CBA Alias"
                  required={true}
                  isLoading={isLoading}
                  isError={isError}
                  placeholder="Select master CBA alias"
                  isformatOptionLabel={false}
                />
            </div>
            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default PauseSettlementInternal;
