import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { randomUUID } from "../../services/randomUUID.js";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import "./ModuleProvider.scss";
import moduleWithoutProviderList from "../../config/moduleWithoutProviderList/moduleWithoutProviderList";
// import Select from "react-select";
import defaultProviderList from "../../config/providers/bank/defaultProviders.js";

function ModuleProvider(props) {
  // To use module Provider Response as a base to show modules on module provider screen
  // Initially  all modules were shown, now using module Provider Response only those modules will be shown those have providers
  // Only exceptions are kyc, aml and ledgers, they may be present in module provider response and have providers but they dont need a provider to be selected
  let prevData = props.companySelectedModulesInput ? true : false;

  const mergeByModuleCode = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find((item) => item.moduleCode === itm.code && item),
      ...itm,
      moduleSecret: randomUUID(),
    }));

  const mappedData = mergeByModuleCode(
    props.modResponse,
    props.moduleProviderResponse
  );

  const filteredMappedData = mappedData.filter(
    (item, index) => item.providerDetails != undefined
  );

  const [providerAfterRevisit, setProviderAfterRevisit] = React.useState(false);
  const [modulesInputAfterRevisit, setModulesInputAfterRevisit] =
    React.useState([]);

  React.useEffect(() => {
    for (let i = 0; i < filteredMappedData.length; i++) {
      if (filteredMappedData[i].code === "core_banking") {
        document.getElementById(
          "customerHiddenData"
        ).dataset.coreBankingProviderSecret =
          filteredMappedData[i].moduleSecret;
        break;
      }
    }
  }, [filteredMappedData]);

  const initialData = filteredMappedData.map((item, idx) => {
    return {
      ...item,
      moduleCheckbox: true,
      moduleCode: item.code,
      moduleProvider: "",
      moduleProviderId: "",
    };
  });
  const [modulesInputData, setModulesInputData] = React.useState(initialData);

  const [btnVerify, setBtnVerify] = React.useState(1);
  const [btnValidationCheck, setBtnValidationCheck] = React.useState([]);

  // React.useEffect(() => {
  //     for (let i = 0; i < modulesInputData.length; i++) {
  //         for (let j = 0; j < defaultProviderList.length; j++) {
  //             if (modulesInputData[i].code == defaultProviderList[j].code) {
  //                 modulesInputData[i].defaultProvider = defaultProviderList[j].id;
  //                 modulesInputData[i].defaultProviderName = defaultProviderList[j].defaultProvider;
  //                 break
  //             }
  //         }
  //     }
  // }, [modulesInputData])

  React.useEffect(() => {
    // for providers settings
    if (modulesInputAfterRevisit.length > 0) {
      let checklistElements = document.querySelectorAll("[type=checkbox]");
      for (let i = 0; i < modulesInputAfterRevisit.length; i++) {
        let currentModuleCode = modulesInputAfterRevisit[i].code;
        if (currentModuleCode == "payments") {
          const indexOfCbProvider = modulesInputData.findIndex(
            (i) => i.code === "core_banking"
          );
          const indexOfPayProvider = modulesInputData.findIndex(
            (i) => i.code === "payments"
          );
          modulesInputData[indexOfPayProvider].moduleProvider =
            modulesInputData[indexOfCbProvider].moduleProvider;
          modulesInputData[indexOfPayProvider].moduleProviderId =
            modulesInputData[indexOfCbProvider].moduleProviderId;
          break;
        }
        let providerOptionsInput = document.querySelectorAll(
          "[data-module=" + currentModuleCode + "]"
        );
        for (let j = 0; j < providerOptionsInput.length; j++) {
          for (let k = 0; k < props.companySelectedProvidersInput.length; k++) {
            if (
              providerOptionsInput[j].value ==
              props.companySelectedProvidersInput[k].moduleProviderId
            ) {
              providerOptionsInput[j].selected = true;
              const indexOfObject = modulesInputData.findIndex(
                (i) => i.code === currentModuleCode
              );
              modulesInputData[indexOfObject].moduleProviderId =
                props.companySelectedProvidersInput[k].moduleProviderId;
              modulesInputData[indexOfObject].moduleProvider =
                props.companySelectedProvidersInput[k].moduleProvider;
            }
          }
        }
      }
    }
  }, [modulesInputAfterRevisit]);

  useEffect(() => {
    // Filtering out objects with moduleCode 'ppi_wallet' and 'ppi_full_kyc'
    const updatedModulesInputData = modulesInputData.filter(
      (item) =>
        item.moduleCode !== "ppi_wallet" && item.moduleCode !== "ppi_full_kyc"
    );

    // Check if the filtered data is different from the current state
    if (
      JSON.stringify(updatedModulesInputData) !==
      JSON.stringify(modulesInputData)
    ) {
      // Update the state with the filtered data
      setModulesInputData(updatedModulesInputData);
    }
  }, [modulesInputData]);

  React.useEffect(() => {
    if (prevData) {
      let checklistElements = document.querySelectorAll("[type=checkbox]");
      let companyModulesInputTemp = props.companySelectedModulesInput;
      for (let i = 0; i < checklistElements.length; i++) {
        for (let j = 0; j < companyModulesInputTemp.length; j++) {
          if (checklistElements[i].id == companyModulesInputTemp[j].code) {
            checklistElements[i].checked = true;
            const indexOfObject = modulesInputData.findIndex(
              (i) => i.code === companyModulesInputTemp[j].code
            );
            modulesInputData[indexOfObject].moduleCheckbox = true;
            setProviderAfterRevisit(true);
            if (companyModulesInputTemp[j].code == "core_banking") {
              document.getElementById("payments").disabled = false;
              document.getElementById("ppi_wallet").disabled = false;
            }
            break;
          }
        }
      }
      setModulesInputAfterRevisit(companyModulesInputTemp);
    }
  }, []);

  React.useEffect(() => {
    const checkResult = modulesInputData.filter((item, idx) => {
      return item.moduleCheckbox === true;
    });

    setBtnValidationCheck(checkResult);
  }, [modulesInputData]);

  React.useEffect(() => {
    if (btnValidationCheck.length > 0) {
      let count = 0;
      for (let i = 0; i < btnValidationCheck.length; i++) {
        const element = btnValidationCheck[i];
        if (
          moduleWithoutProviderList.includes(element.moduleCode) ||
          (!moduleWithoutProviderList.includes(element.moduleCode) &&
            element.moduleProvider !== "")
        ) {
          count++;
        }
      }
      if (count === btnValidationCheck.length) {
        setBtnVerify(0);
      } else {
        setBtnVerify(1);
      }
    } else {
      setBtnVerify(1);
    }
  }, [btnValidationCheck]);

  const modulesPayloadData = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleSecret) return item;
      return false;
    })
    .map((el, index) => {
      return {
        code: el.moduleCode,
        secret: el.moduleSecret,
      };
    });

  const providerObj = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleProvider) return item;
      return false;
    })
    .map((el, index) => {
      return {
        moduleProviderId: el.moduleProviderId,
        moduleProvider: el.moduleProvider,
      };
    });

  const copyDataObj = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true) return item;
      return false;
    })
    .map((el, index) => {
      return {
        moduleProviderId: el.moduleProviderId,
        moduleProvider: el.moduleProvider,
        code: el.moduleCode,
        secret: el.moduleSecret,
        providSecret: "",
        isProvider: el.isProvider,
      };
    });

  const apiMeterObj = modulesInputData
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleSecret) return item;
      return false;
    })
    .map((el, index) => {
      return {
        moduleCode: el.code,
        moduleName: el.name,
      };
    });

  const submitModules = () => {
    props.addCompanyModules(
      modulesPayloadData,
      providerObj,
      apiMeterObj,
      copyDataObj,
      modulesInputData
    );
    document.getElementById("customerHiddenData").dataset.modulesInputData =
      JSON.stringify(modulesPayloadData);
    document.getElementById("customerHiddenData").dataset.providerObj =
      JSON.stringify(providerObj);
  };

  React.useEffect(() => {
    for (let i = 0; i < modulesInputData.length; i++) {
      if (modulesInputData[i].code == "ledger") {
        modulesInputData[i].providerDetails = [];
      }
    }
  }, [modulesInputData]);

  React.useEffect(() => {
    // For QA and staging there are mismatch on number of providers, so for QA if we need to fix then later we will add a condition ki if process.env == QA ? then follow the [0] wala process else follow the complete below process!
    for (let i = 0; i < modulesInputData.length; i++) {
      // Logic is to set the first item {provider} from response as a default provider for all modules except ppi_wallet - for ppi_wallet we want livquik as default for now so set its index as default provider
      if (
        modulesInputData[i].code != "ppi_wallet" &&
        modulesInputData[i].code != "core_banking" &&
        modulesInputData[i].code != "payments"
      ) {
        modulesInputData[i].moduleProviderId =
          modulesInputData[i].providerDetails[0].id;
        modulesInputData[i].moduleProvider =
          modulesInputData[i].providerDetails[0].name;
      }
      // condition for core banking
      else if (modulesInputData[i].code == "core_banking") {
        modulesInputData[i].moduleProviderId =
          modulesInputData[i].providerDetails[0].id;
        modulesInputData[i].moduleProvider =
          modulesInputData[i].providerDetails[0].name;
      } else if (modulesInputData[i].code == "ppi_wallet") {
        modulesInputData[i].moduleProviderId =
          modulesInputData[i].providerDetails[1].id;
        modulesInputData[i].moduleProvider =
          modulesInputData[i].providerDetails[1].name;
      }
      // else {
      //   modulesInputData[i].moduleProviderId =
      //     modulesInputData[i].providerDetails.slice(-1)[0].id;
      //   modulesInputData[i].moduleProvider =
      //     modulesInputData[i].providerDetails.slice(-1)[0].name;
      // }

      if (modulesInputData[i].code === "core_banking") {
        document.getElementById(
          "customerHiddenData"
        ).dataset.coreBankingProviderSecret = modulesInputData[i].moduleSecret;
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="module-provider-form">
        <h2 className="form-title">Select modules and providers</h2>
        <div className="form-details">
          <div className="company-details row">
            <div className="label_main">Company</div>
            <div className="label_main_value company-name">
              {props?.companyDetails?.fullName || "NA"}
            </div>
          </div>
          <div className="available-modules row">
            <div className="label_main">Select Modules</div>
            <div className="label_main_value">
              <div className="form-content">
                <div className="client-module-container">
                  {modulesInputData.map((data, index) => {
                    return (
                      <>
                        {data.is_active && (
                          <div key={index} className="module-section-form">
                            <div className="checkbox-container">
                              <input
                                type="checkbox"
                                defaultChecked="true"
                                // disabled={
                                //     data.code === "payments" ||
                                //         data.code === "ppi_wallet"
                                //         ? true
                                //         : false
                                // }
                                id={data.code}
                                autoComplete="off"
                                onChange={(e) => {
                                  data.moduleCheckbox = e.target.checked;
                                  const indexOfCbObject =
                                    modulesInputData.findIndex(
                                      (i) => i.code === "core_banking"
                                    );

                                  if (
                                    modulesInputData[indexOfCbObject]
                                      .moduleCheckbox === true
                                  ) {
                                    if (document.getElementById("payments")) {
                                      document.getElementById(
                                        "payments"
                                      ).disabled = false;
                                    }
                                    if (document.getElementById("ppi_wallet")) {
                                      document.getElementById(
                                        "ppi_wallet"
                                      ).disabled = false;
                                    }
                                  } else {
                                    if (document.getElementById("payments")) {
                                      document.getElementById(
                                        "payments"
                                      ).disabled = true;
                                      let indexOfPaymentObject =
                                        modulesInputData.findIndex(
                                          (i) => i.code === "payments"
                                        );
                                      modulesInputData[
                                        indexOfPaymentObject
                                      ].moduleCheckbox = false;
                                      document.getElementById(
                                        "payments"
                                      ).checked = false;
                                    }
                                    if (document.getElementById("ppi_wallet")) {
                                      document.getElementById(
                                        "ppi_wallet"
                                      ).disabled = true;
                                      let indexOfPpiObject =
                                        modulesInputData.findIndex(
                                          (i) => i.code === "ppi_wallet"
                                        );
                                      indexOfPpiObject =
                                        modulesInputData.findIndex(
                                          (i) => i.code === "ppi_wallet"
                                        );
                                      modulesInputData[
                                        indexOfPpiObject
                                      ].moduleCheckbox = false;
                                      document.getElementById(
                                        "ppi_wallet"
                                      ).checked = false;
                                    }
                                  }
                                  if (data.moduleCheckbox === false) {
                                    data.moduleProviderId = "";
                                    data.moduleProvider = "";
                                  } else {
                                    data.moduleProvider =
                                      data.providerDetails[0].name;
                                    data.moduleProviderId =
                                      data.providerDetails[0].id;
                                  }

                                  setModulesInputData([...modulesInputData]);
                                }}
                                value={data.checked}
                              />
                              <label htmlFor={data.code}>{data.name}</label>
                            </div>
                            <div className="separator"></div>
                            {data.moduleCheckbox && (
                              <>
                                <div className="form-field_select">
                                  <div className="select">
                                    <select
                                      disabled={
                                        data.code === "kyc_and_onboarding" ||
                                        data.code === "aml_and_compliance" ||
                                        data.code === "ledger"
                                          ? true
                                          : false
                                      }
                                      onChange={({ target }) => {
                                        data.moduleProviderId = target.value;
                                        data.moduleProvider =
                                          target[target.selectedIndex].text;

                                        setModulesInputData([
                                          ...modulesInputData,
                                        ]);
                                      }}
                                      className="select-text"
                                      required
                                    >
                                      {!(
                                        data.code === "kyc_and_onboarding" ||
                                        data.code === "aml_and_compliance" ||
                                        data.code === "ledger"
                                      ) ? (
                                        <>
                                          {data.code === "payments" &&
                                          data.moduleProvider !== "" ? (
                                            <option
                                              defaultValue
                                              hidden
                                              value={data.moduleProviderId}
                                              data-module="core_banking"
                                              selected
                                            >
                                              {data.moduleProvider}
                                            </option>
                                          ) : (
                                            <option defaultValue hidden>
                                              select provider..
                                            </option>
                                          )}
                                          {data.providerDetails.map(
                                            (elem, index) => {
                                              return (
                                                elem &&
                                                elem.id &&
                                                elem.isActive && (
                                                  <option
                                                    key={index}
                                                    value={elem.id}
                                                    data-module={data.code}
                                                    selected={
                                                      // Logic is to set the first item {provider} from response as a default provider for all modules except ppi_wallet - for ppi_wallet we want livquik as default for now so set its index as default provider

                                                      data.code !=
                                                        "ppi_wallet" &&
                                                      data.code !=
                                                        "core_banking" &&
                                                      data.code != "payments"
                                                        ? data
                                                            .providerDetails[0]
                                                            .id == elem.id
                                                          ? true
                                                          : false
                                                        : data.code ==
                                                            "core_banking" ||
                                                          data.code ==
                                                            "payments"
                                                        ? // data.providerDetails.slice(-1)[0].id == elem.id
                                                          data
                                                            .providerDetails[0]
                                                            .id == elem.id
                                                          ? true
                                                          : false
                                                        : data
                                                            .providerDetails[1]
                                                            .id == elem.id
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    {elem.name}
                                                  </option>
                                                )
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        " "
                                      )}
                                    </select>
                                    <label className="select-label">
                                      Provider
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="company-details-submit button-container">
          <button
            className={`company-details-submit ${
              btnVerify === 1 ? "" : "active"
            } active`}
            // disabled={btnVerify > 0 && true}
            disabled={false}
            onClick={submitModules}
          >
            Save & Continue
            <span id="addCustomer-module-loader"></span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ModuleProvider;
