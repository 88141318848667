import React, { useEffect, useState } from "react";
// provider list imports
import bankProviderList from "../../../config/providers/bank/provider";
// styles imports
import "./MvaProviderDropdown.scss";

const MvaProviderDropdown = ({ setProvider, resetInputFields }) => {
  const [selected, setSelected] = useState("Choose Bank Provider");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setSelected("Choose Bank Provider");
  }, [resetInputFields]);

  return (
    <div className="dropdown-mva-provider">
      <div className="dropdown-mva-provider-label">Provider</div>
      <div
        className={`dropdown-btn ${
          selected === "Choose Bank Provider" ? "text-light" : "text-dark"
        } ${isActive && " border-radius__invert"}`}
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected}
        {selected.length !== 1 && (
          <span>
            <DownArrow isActive={isActive} />
          </span>
        )}
      </div>
      {isActive && (
        <div className="http-type-content mva-provider-type-content">
          {bankProviderList
            .filter((option) => option.bank_name !== selected)
            .map((option) => (
              <div
                key={option.bank_code}
                onClick={(e) => {
                  setSelected(option.bank_name);
                  setIsActive(false);
                  setProvider(option.bank_code);
                }}
                className="dropdown-item"
              >
                {option.bank_name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${
        isActive && "dropdown-arrow__rotate"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default MvaProviderDropdown;
