// Bank providers for production env
const productionAccountBalanceData = [
  {
    bank: "ICICI Bank",
    accountType: "Zoho Invoices Account",
    account: "313205000647",
    provider: "icic",
  },
  {
    bank: "Axis Bank",
    accountType: "Common Escrow",
    account: "922020047762308",
    provider: "utib",
  },
  {
    bank: "IDFC Bank",
    accountType: "Common Escrow",
    account: "10139953666",
    provider: "idfb",
  },
  {
    bank: "IDFC Bank",
    accountType: "Escrow",
    account: "10170578606",
    provider: "idfb",
  },
  {
    bank: "Yes Bank",
    accountType: "Current Account",
    account: "002281300009510",
    provider: "yesb",
  },
  {
    bank: "DBS Bank",
    accountType: "Common Escrow",
    account: "8827210000008167",
    provider: "dbss",
  },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Lender Escrow Account",
  //   account: "002266200001920",
  //   provider: "yesb",
  // },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Vendor Account",
  //   account: "002266200001930",
  //   provider: "yesb",
  // },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Current Account",
  //   account: "002281300009510",
  //   provider: "yesb",
  // },
  // {
  //   bank: "Yes Bank",
  //   accountType: "Nodal Account",
  //   account: "002267800000779",
  //   provider: "yesb",
  // },
  // {
  //   bank: "ICICI Bank",
  //   accountType: "Payouts",
  //   account: "313205000588",
  //   provider: "icic",
  // },
  // {
  //   bank: "ICICI Bank",
  //   accountType: "Lender Escrow",
  //   account: "058305006597",
  //   provider: "icic",
  // },
  // {
  //   bank: "Axis Bank",
  //   accountType: "Nodal Account",
  //   account: "921020054939507",
  //   provider: "utib",
  // },
];

export default productionAccountBalanceData;
