import React, { useState, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  decentro_txn_id: "",
  bank_reference_number: "",
};

const Regex = REGEXP.alphanumericRegex;
const validationSchema = Yup.object({
  decentro_txn_id: Yup.string()
    .matches(Regex)
    .required("Transaction ID is required"),
  bank_reference_number: Yup.string()
    .max(50)
    .matches(Regex)
    .required("Bank Reference Number is required"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleTransactionSuccess = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        action: "mark_as_success",
        transaction_id: values.decentro_txn_id,
        bank_reference_number: values.bank_reference_number,
      };

      action.setSubmitting(true);
      APIConfig.API_Client.put(
        apiEndpointList.MARK_TRANSACTION_SUCCESS.baseUrl +
          apiEndpointList.MARK_TRANSACTION_SUCCESS.endpoint +
          `/${payloadData.transaction_id}`,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Fill transaction details to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="decentro_txn_id"
                name="decentro_txn_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.decentro_txn_id}
                touched={formik.touched.decentro_txn_id}
                error={formik.errors.decentro_txn_id}
                placeholder="Enter Transaction ID"
                label="Transaction ID"
                required={true}
                disabled={false}
                isToolTip={true ? "Decentro Company URN" : null}
              />
              <TextFieldInput
                id="bank_reference_number"
                name="bank_reference_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bank_reference_number}
                touched={formik.touched.bank_reference_number}
                error={formik.errors.bank_reference_number}
                placeholder="Enter Bank Reference Number"
                label="Bank Reference Number"
                required={true}
              />
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleTransactionSuccess;
