import React from "react";

//component-import
import SingleRemitterWhitelist from "./RemitterWhitelist/SingleRemitterWhitelist";
import BulkRemitterWhitelist from "./RemitterWhitelist/BulkRemitterWhitelist";
import CompanyRemitterWhitelist from "./RemitterWhitelist/CompanyRemitterWhitelist";
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";


const RemitterWhitelist = () => {

  return (
    <React.Fragment>
    <TabComponentWrapper
       heading="Remitter Whitelist"
       tabs={[
        {
          className: "company-remitter",
          label: "Enable/Disable Company",
          value: "company",
          component: <CompanyRemitterWhitelist />,
        },
         {
           className: "single-remitter",
           label: "Single",
           value: "single",
           component: <SingleRemitterWhitelist />,
         },
         {
           className: "bulk-remitter",
           label: "Bulk",
           value: "bulk",
           component: <BulkRemitterWhitelist />,
         },
       ]}
     />
   </React.Fragment>
  );
};

export default RemitterWhitelist;
