import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
// api config imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
// component imports
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// styles imports
import "./BalanceRow.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BalanceRow = ({ bankName, accountType, accountNumber, provider }) => {
  const [displayBalance, setDisplayBalance] = useState("");
  const [displayAverageBalance, setDisplayAverageBalance] = useState("");
  const [balanceStatus, setBalanceStatus] = useState("loading");
  const [balanceAverageStatus, setBalanceAverageStatus] = useState("loading");
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");

  const getAccountBalance = (account) => {
    const date = new Date();

    setBalanceStatus("loading");
    setLastUpdatedTime(date.toLocaleTimeString("en-GB"));

    APIConfig.API_Client.defaults.headers.get["provider_code"] = provider;

    APIConfig.API_Client.get(
      apiEndpointList.BANKING_ACCOUNT.baseUrl +
        apiEndpointList.BANKING_ACCOUNT.endpoint +
        `/${account}/balance`,
      // APIConfig.BASE_URL + `/frontend/v2/banking/account/${account}/balance`,
      { cancelToken: source.token }
    )
      .then((response) => {
        setBalanceStatus("success");
        setLastUpdatedTime(date.toLocaleTimeString("en-GB"));
        setDisplayBalance(response.data.data.presentBalance);
      })
      .catch((error) => {
        setBalanceStatus("failed");
        console.log("Error Balance", error);
        // * snackbar for error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  const getAccountBalanceAverage = (account) => {
    setBalanceAverageStatus("loading");

    // APIConfig.API_Client.defaults.headers.get["provider_code"] = provider;

    APIConfig.API_Client.post(
      apiEndpointList.AVERAGE_BANKING_ACCOUNT.baseUrl +
        apiEndpointList.AVERAGE_BANKING_ACCOUNT.endpoint,
      {
        account_number: account,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        setBalanceAverageStatus("success");
        setDisplayAverageBalance(response.data[0].average_balance || "-");
      })
      .catch((error) => {
        setBalanceAverageStatus("failed");
        console.log("Error Balance", error);
        // * snackbar for error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    if (!!accountNumber) {
      getAccountBalance(accountNumber);
      getAccountBalanceAverage(accountNumber);
    }
  }, []);

  return (
    <>
      <tr>
        <td>{bankName}</td>
        <td>
          {accountType}
          {/* <span className="last-updated-time">Last updated on : {lastUpdatedTime}</span> */}
        </td>
        <td>{accountNumber}</td>
        <td className="last-updated-time">{lastUpdatedTime}</td>
        {/* Account Balance */}
        <td className="balance">
          <span className="balance-value">
            {balanceStatus === "success" && displayBalance}
          </span>
          {balanceStatus === "failed" && "Retry Again"}
          {balanceStatus === "loading" && (
            <img
              className="table-loader"
              src="/images/loader.gif"
              alt="loader"
            />
          )}
          {balanceStatus === "loading" ? (
            ""
          ) : (
            <span
              onClick={() => getAccountBalance(accountNumber)}
              className="refresh-icon-wrapper"
            >
              <img src="/images/refresh-icon.svg" alt="refresh icon" />
            </span>
          )}
        </td>

        {/* Average Account Balance */}
        <td className="balance">
          <span className="balance-value">
            {balanceAverageStatus === "success" && parseFloat(displayAverageBalance).toFixed(2)}
          </span>
          {balanceAverageStatus === "failed" && "Retry Again"}
          {balanceAverageStatus === "loading" && (
            <img
              className="table-loader"
              src="/images/loader.gif"
              alt="loader"
            />
          )}
          {balanceAverageStatus === "loading" ? (
            ""
          ) : (
            <span
              onClick={() => getAccountBalanceAverage(accountNumber)}
              className="refresh-icon-wrapper"
            >
              <img src="/images/refresh-icon.svg" alt="refresh icon" />
            </span>
          )}
        </td>
      </tr>
    </>
  );
};

export default BalanceRow;
