import React, { useState } from "react";
import ReactDOM from "react-dom";
import { randomUUID } from "../../services/randomUUID.js";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import {
  mobileRegexValidate,
  emailRegexValidate,
  urlRegexValidate,
  partnerIDRegexValidate,
  panRegexValidate
} from "../../utilities/validators/inputValidators";
import generateRandomPAN from "../../utilities/generateRandomPAN.js";
import "./CompanyDetails.scss";
import { APIConfig } from "../../services/apiConfiguration.js";
import apiEndpointList from "../../config/modules/customer_management/endpoint.js";
import { Modal } from "./Modal/Modal.js";

function CompanyDetails(props) {
  let prevData = props.companyHiddenInputTemp ? true : false;
  const [companyInput, setCompanyInput] = React.useState({
    companyName: "",
    clientId: "",
    website: "",
    partnerId: "",
    mobileNumber: "",
    legalName: "",
    clientSecrets: randomUUID(),
    officeEmail: "",
    pan: process.env.REACT_APP_ENV_NAME === "staging" ? generateRandomPAN() : ""
  });

  const [inputError, setInputError] = React.useState({
    company_name: false,
    legal_name: false,
    client_id: false,
    website: false,
    email_id: false,
    mobile_number: false,
    partner_id: false,
    pan: false
  });

  React.useEffect(() => {
    if (prevData) {
      let inputElements = document.querySelectorAll("[data-field]");
      for (var i = 0; i < inputElements.length; i++) {
        let field = inputElements[i].dataset.field;
        inputElements[i].value =
          field != "client_id"
            ? props.companyHiddenInputTemp[field]
            : props.companyHiddenSecretInputTemp[field];
      }
    }
  }, []);
  const [btnVerify, setBtnVerify] = React.useState(1);
  React.useEffect(() => {
    if (
      companyInput.companyName !== "" &&
      companyInput.legalName !== "" &&
      companyInput.officeEmail !== "" &&
      companyInput.clientId !== "" &&
      companyInput.mobileNumber !== "" &&
      companyInput.pan !== "" &&
      document.getElementsByClassName("input-error").length <= 0
    ) {
      setBtnVerify(0);
    } else {
      setBtnVerify(1);
    }
  }, [companyInput]);

  const handleCompanyInput = (event) => {
    const value = event.target.value;
    setCompanyInput({
      ...companyInput,
      [event.target.name]: value,
    });
  };

  let addCompanyData;
  if (+companyInput.partnerId) {
    addCompanyData = {
      common_name: companyInput.companyName,
      full_name: companyInput.legalName,
      email_id: companyInput.officeEmail,
      website: companyInput.website,
      mobile_number: companyInput.mobileNumber,
      decentro_partner_id: +companyInput.partnerId,
      pan: companyInput.pan
    };
  } else {
    addCompanyData = {
      common_name: companyInput.companyName,
      full_name: companyInput.legalName,
      email_id: companyInput.officeEmail,
      website: companyInput.website,
      mobile_number: companyInput.mobileNumber,
      pan: companyInput.pan
    };
  }

  let addCompanySecretData = {
    company_id: "",
    client_id: companyInput.clientId,
    client_secret: companyInput.clientSecrets,
  };

  const submitCompanyData = (event) => {
    if (
      companyInput.companyName !== "" &&
      companyInput.legalName !== "" &&
      companyInput.officeEmail !== "" &&
      companyInput.clientId !== "" &&
      companyInput.clientSecrets !== "" &&
      companyInput.pan !== ""
    ) {
      props.addCompanyData(addCompanyData, addCompanySecretData);
      document.getElementById("customerHiddenData").dataset.companyDetails =
        JSON.stringify(addCompanyData);
      document.getElementById("customerHiddenData").dataset.companySecretData =
        JSON.stringify(addCompanySecretData);
    } else {
      ReactDOM.render(
        <SnackbarMessage
          msgtype="Error"
          msg="Please fill the above required * fields"
        />,
        document.getElementById("snackbar")
      );
    }
    // commented because this was causing display of error on otp before succeding.
    // event.preventDefault();
  };

  const checkEmailValidation = (event) => {
    emailValidation(event);
  };

  const checkPhoneValidation = (event) => {
    phoneValidation(event);
  };

  const checkUrlValidation = (event) => {
    urlValidation(event);
  };

  const checkPartnerIDValidation = (event) => {
    partnerValidation(event);
  };

  const checkPanValidation = (event) => {
    panValidation(event);
  };

  const phoneValidation = (event) => {
    if (
      !mobileRegexValidate(event.target.value) &&
      event.target.value.length > 0
    ) {
      event.target.classList.add("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.add("invalid-error-active");
    } else {
      event.target.classList.remove("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.remove("invalid-error-active");
    }
  };

  const emailValidation = (event) => {
    if (
      !emailRegexValidate(event.target.value) &&
      event.target.value.length > 0
    ) {
      event.target.classList.add("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.add("invalid-error-active");
    } else {
      event.target.classList.remove("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.remove("invalid-error-active");
    }
  };

  const partnerValidation = (event) => {
    if (
      !partnerIDRegexValidate(event.target.value) &&
      event.target.value.length > 0
    ) {
      event.target.classList.add("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.add("invalid-error-active");
    } else {
      event.target.classList.remove("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.remove("invalid-error-active");
    }
  };

  const urlValidation = (event) => {
    if (
      !urlRegexValidate(event.target.value) &&
      event.target.value.length > 0
    ) {
      event.target.classList.add("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.add("invalid-error-active");
    } else {
      event.target.classList.remove("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.remove("invalid-error-active");
    }
  };

  const panValidation = (event) => {
    if (
      !panRegexValidate(event.target.value) &&
      event.target.value.length > 0
    ) {
      event.target.classList.add("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.add("invalid-error-active");
    } else {
      event.target.classList.remove("input-error");
      document
        .getElementsByClassName(event.target.classList[1] + "-error")[0]
        .classList.remove("invalid-error-active");
    }
  };

  //manage modal state
  const [openModal, setOpenModal] = useState(false);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const submitHandler = () => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };
    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: randomUUID(),
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        setOpenModal(true);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="company-details-form">
        <h2 className="form-title">Fill company details to continue</h2>
        <div className="form-content">
          <div className="form-inputs-section">
            <div className="company-name-input-section form-content-input">
              <input
                id="company-name"
                name="companyName"
                data-field="common_name"
                onChange={handleCompanyInput}
                type="text"
                required
                spellCheck="false"
                autoComplete="off"
                className="form-input-box"
                placeholder="Enter company name"
              />
              <label className="label" htmlFor="company-name">
                Company Name <span className="required-field">*</span>
              </label>
            </div>
            <div className="company-name-input-section form-content-input">
              <input
                id="legal-name"
                name="legalName"
                data-field="full_name"
                onChange={handleCompanyInput}
                autoComplete="off"
                spellCheck="false"
                type="text"
                required
                className="form-input-box"
                placeholder="Enter legal name"
              />
              <label className="label" htmlFor="company-name">
                Registered Name <span className="required-field">*</span>
              </label>
            </div>
          </div>
          <div className="form-inputs-section">
            <div className="company-name-input-section form-content-input">
              <input
                id="client-id"
                name="clientId"
                data-field="client_id"
                onChange={handleCompanyInput}
                type="text"
                required
                spellCheck="false"
                className="form-input-box"
                autoComplete="off"
                placeholder="Enter Client ID"
              />
              <label className="label" htmlFor="client-id">
                Client Id <span className="required-field">*</span>
              </label>
            </div>
            <div className="company-name-input-section form-content-input">
              <input
                id="client-secret"
                name="clientSecrets"
                value={companyInput.clientSecrets}
                autoComplete="off"
                spellCheck="false"
                type="text"
                className="form-input-box"
                readOnly
                disabled
              />
              <label className="label" htmlFor="client-secret">
                Client secret <span className="required-field">*</span>
              </label>
            </div>
          </div>
          <div className="form-inputs-section">
            <div className="company-name-input-section form-content-input">
              <input
                id="client-website"
                name="website"
                data-field="website"
                onChange={handleCompanyInput}
                onInput={checkUrlValidation}
                type="text"
                required
                spellCheck="false"
                className="form-input-box website-input"
                autoComplete="off"
                placeholder="Enter company’s website"
              />
              <label className="label" htmlFor="client-website">
                Website
              </label>
              <img
                src="/images/invalid-input.svg"
                className={`website-input-error invalid-error ${
                  inputError.email_id ? "invalid-error-active" : ""
                }`}
              />
            </div>
            <div className="company-name-input-section form-content-input">
              <input
                id="office-email"
                name="officeEmail"
                data-field="email_id"
                onChange={handleCompanyInput}
                onInput={checkEmailValidation}
                autoComplete="off"
                spellCheck="false"
                type="text"
                className="form-input-box email-input"
                required
                placeholder="Enter company’s email ID"
              />
              <label className="label" htmlFor="office-email">
                Office Email <span className="required-field">*</span>
              </label>
              <img
                src="/images/invalid-input.svg"
                className={`email-input-error invalid-error ${
                  inputError.email_id ? "invalid-error-active" : ""
                }`}
              />
            </div>
          </div>
          <div className="form-inputs-section">
            <div className="company-name-input-section form-content-input">
              <input
                id="mob-number"
                name="mobileNumber"
                data-field="mobile_number"
                onChange={handleCompanyInput}
                onInput={checkPhoneValidation}
                type="tel"
                maxLength="10"
                pattern="[0-9]{10}"
                required
                autoComplete="off"
                className="form-input-box phone-input"
                placeholder="Enter registered mobile number"
              />
              <label className="label" htmlFor="mob-number">
                Mobile Number <span className="required-field">*</span>
              </label>
              <img
                src="/images/invalid-input.svg"
                className={`phone-input-error invalid-error ${
                  inputError.phone_number ? "invalid-error-active" : ""
                }`}
              />
            </div>

            <div className="company-name-input-section form-content-input">
              <input
                id="partner-id"
                name="partnerId"
                data-field="partner_id"
                onChange={handleCompanyInput}
                onInput={checkPartnerIDValidation}
                type="text"
                required
                spellCheck="false"
                autoComplete="off"
                className="form-input-box partner-id"
                placeholder="Enter partner ID"
              />
              <label className="label" htmlFor="partner-id">
                Partner ID
              </label>
              <img
                src="/images/invalid-input.svg"
                className={`partner-id-error invalid-error ${
                  inputError.gst_number ? "invalid-error-active" : ""
                }`}
              />
            </div>
            </div>
            <div className="form-inputs-section">
            <div className="company-name-input-section form-content-input">
              <input
                id="pan"
                name="pan"
                data-field="pan"
                value={companyInput.pan.toUpperCase()}
                onChange={handleCompanyInput}
                onInput={checkPanValidation}
                type="text"
                required
                autoComplete="off"
                className="form-input-box pan-input"
                placeholder="Enter PAN"
              />
              <label className="label" htmlFor="pan">
                PAN <span className="required-field">*</span>
              </label>
              <img
                src="/images/invalid-input.svg"
                className={`pan-input-error invalid-error ${
                  inputError.phone_number ? "invalid-error-active" : ""
                }`}
              />
            </div>
          </div>
        </div>
        <div className="company-details-submit button-container">
          <button
            className={`company-details-submit ${
              btnVerify === 1 || isLoading ? "" : "active"
            }`}
            disabled={btnVerify > 0 || isLoading}
            onClick={submitHandler}
          >
            {isLoading ? "Loading" : "Save & Continue"}
            <span id="addCustomer-loader"></span>
          </button>
        </div>
      </div>
      {openModal ? (
        <Modal
          setOpenModal={setOpenModal}
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitCompanyData={submitCompanyData}
        />
      ) : null}
    </React.Fragment>
  );
}

export default CompanyDetails;
