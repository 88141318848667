import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

// utils-import
import { REGEXP } from "../../../utilities/validators/inputValidators";

//component-import
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import ToolTip from "../../../UI/ToolTip/ToolTip";
import ResponseDetails from "../UserResponseDetails/ResponseDetails";

//styles-import
import "../../../UI/TextField/TextField.scss";
import { Modal } from "../Modal/Modal";
import { randomUUID } from "../../../services/randomUUID";
import UserActionContext from "../../../contexts/UserActionContext";

const initialValues = {
  company_name: "",
  user_type: "",
  user_name: "",
  email: "",
  mobile: "",
  password: "",
  is_active: true,
};
// regex-imports
const { usernameRegex, phoneNumberRegex, passwordRegex } = REGEXP;

const validationSchema = Yup.object({
  company_name: Yup.object().shape({
    value: Yup.number().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
  user_type: Yup.object().shape({
    value: Yup.string().required("Please Select an Option"),
    label: Yup.string().required("Please Select an Option"),
  }),
  user_name: Yup.string()
    .matches(usernameRegex, "Invalid Name format")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(phoneNumberRegex, "Invalid Mobile number")
    .max(10)
    .required("Mobile number is required"),
  password: Yup.string().when("user_type", {
    is: (val) => val && val.label === "communication_user",
    then: () => Yup.string().notRequired(),
    otherwise: () =>
      Yup.string()
        .matches(passwordRegex, "Invalid Password format")
        .required("Password is required"),
  }),
  is_active: Yup.boolean().when("user_type", {
    is: (val) => val && val.label === "communication_user",
    then: () => Yup.boolean().notRequired(),
    otherwise: () => Yup.boolean().required("Boolean Status required"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const AddNewUser = () => {
  const { showModal, openModal } = useContext(UserActionContext);
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [userTypeOptions, setUserTypeOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUserType, setIsLoadingUserType] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const handleSubmit = (formik) => {
    const { values, action } = formik;
    let payload;

    // communication user
    console.log(values?.user_type?.value);

    if (values?.user_type?.label === "communication_user") {
      payload = {
        company_id: values?.company_name?.value,
        user_type: values?.user_type?.value,
        name: values.user_name,
        email: values.email,
        mobile: values.mobile,
        set_active: true,
      };
    } else {
      payload = {
        company_id: values?.company_name?.value,
        user_type: values?.user_type?.value,
        name: values.user_name,
        email: values.email,
        mobile: values.mobile,
        set_active: values.is_active,
        password: values.password,
      };
    }
    formik.setSubmitting(true);

    // // addNewUserApi(payload, values, action);
    APIConfig.API_Client.post(
      apiEndpointList.USER_REGISTER.baseUrl +
        apiEndpointList.USER_REGISTER.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResponseDetails({
          ...responseDetails,
          userType: values?.user_type?.label,
          responseData: response.data,
          renderData: payload,
        });
        setShowResponseDetails(true);

        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );

        formik.resetForm();
        formik.setFieldValue("is_active", true);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const submitHandler = (values, action) => {
    const payload = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };

    // * Generating OTP
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      payload
    )
      .then((res) => {
        setQrBase("");
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: payload.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        showModal();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  // Fetching Companies to show in dropdown
  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  // Fetching USER type to show in dropdown
  const getUserTypeList = () => {
    setIsLoadingUserType(true);
    APIConfig.API_Client.post(
      apiEndpointList.GET_USER_TYPE_DROPDOWN.baseUrl +
        apiEndpointList.GET_USER_TYPE_DROPDOWN.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        }));

        setUserTypeOptions(options);
        setIsLoadingUserType(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setUserTypeOptions([]);
        setIsLoadingUserType(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getUserTypeList();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          {/* <div className="ui-divider"></div> */}
          <div className="ui-form-content ui-divider">
            {/* 1 */}
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_name"
                name="company_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_name", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("company_name", true)}
                value={formik.values.company_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoading}
                placeholder="Select company name"
                isformatOptionLabel={true}
              />

              <TextFieldSelect
                id="user_type"
                name="user_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("user_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("user_type", true)}
                value={formik.values.user_type}
                options={userTypeOptions}
                noOptionsMessage={() => "No Such User Type exists"}
                label="User Type"
                required={true}
                isLoading={isLoadingUserType}
                placeholder="Select user type"
                isformatOptionLabel={true}
              />

              {/* <TextFieldSelect
                id="user_type"
                name="user_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("user_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("user_type", true)}
                value={formik.values.user_type}
                options={userTypeDropDownValues}
                noOptionsMessage={() => "No Such User Type exists"}
                label="User Type"
                required={true}
                // isLoading={isLoading}
                placeholder="Select user type"
                isformatOptionLabel={false}
                isClearable={false}
              /> */}
            </div>

            {/* 2 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="user_name"
                name="user_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.user_name}
                touched={formik.touched.user_name}
                error={formik.errors.user_name}
                placeholder="Enter username"
                label="Name"
                required={true}
                disabled={false}
              />

              <TextFieldInput
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={formik.touched.email}
                error={formik.errors.email}
                placeholder="Enter desired email"
                label="Email"
                required={true}
                disabled={false}
              />
            </div>

            {/* 3 */}
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="mobile"
                name="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                touched={formik.touched.mobile}
                error={formik.errors.mobile}
                placeholder="Enter mobile number"
                label="Mobile Number"
                maxLength={10}
                required={true}
                disabled={false}
              />

              {formik?.values?.user_type?.label !== "communication_user" ? (
                <TextFieldInput
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={formik.touched.password}
                  error={formik.errors.password}
                  placeholder="Enter password"
                  label="Password"
                  required={true}
                  disabled={false}
                />
              ) : null}
            </div>

            <div className="ui-form-inputs-section">
              {formik?.values?.user_type?.label !== "communication_user" ? (
                <div className="ui-form-toggle-field">
                  <div className="ui-label">
                    Status
                    <ToolTip
                      text={false}
                      requiredField={true}
                      isToolTip={false}
                    >
                      {/* {isToolTip && (
                      <div className="ui-info-icon">
                        <img src="/images/info-icon.svg" alt="" />
                      </div>
                    )} */}
                    </ToolTip>
                  </div>

                  <div className="ui-switch-wrapper">
                    <label className="ui-switch">
                      <input
                        type="checkbox"
                        id="is_active"
                        name="is_active"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.is_active}
                      />
                      <div className="ui-slider"></div>
                    </label>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="ui-button-container">
              <button
                className={`formik-btn-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : "disabled"
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {openModal ? (
        <Modal
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          handleSubmitUser={(formik) => handleSubmit(formik)}
          formik={formik}
        />
      ) : null}

      {showResponseDetails && (
        <ResponseDetails data={responseDetails} onClick={handleClick} />
      )}
    </>
  );
};

export default AddNewUser;
