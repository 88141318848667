import React from "react";
import "./OnBoardingSummary.scss";
// api config imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
// component imports
import DataTable from "./DataTable";
import TableLoader from "../Shared/TableLoader/TableLoader";
import convertTimestamp from "../../utilities/convertTimestamp";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ProviderCallbacksConfigurationSummary = (props) => {

    const callbackHeader = React.useMemo(
        () => [
            {
                Header: "_",
                hideHeader: false,
                columns: [
                    {
                        Header: "Id",
                        accessor: "id",
                        className: "header-small",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.id != null
                                    ? row.row.original.id
                                    : "-"}
                            </div>
                        ),
                    },
                    {
                        Header: "Decentro API ID",
                        accessor: "decentro_api_id",
                        className: "header-small",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.decentro_api_id != null
                                    ? row.row.original.decentro_api_id
                                    : "-"}
                            </div>
                        ),
                    },
                    {
                        Header: "Company URN",
                        accessor: "company_urn",
                        className: "header-small",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.company_urn != null
                                    ? row.row.original.company_urn
                                    : "-"}
                            </div>
                        ),
                    },
                    {
                        Header: "Request Timestamp",
                        accessor: "request_timestamp",
                        className: "header-medium",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.request_timestamp
                                    ? convertTimestamp(row.row.original.request_timestamp)
                                    : "-"}
                            </div>
                        ),
                    },
                    {
                        Header: "Response Timestamp",
                        accessor: "response_timestamp",
                        className: "header-medium",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.response_timestamp
                                    ? convertTimestamp(row.row.original.response_timestamp)
                                    : "-"}
                            </div>
                        ),
                    },
                    {
                        Header: "HTTP Status Code",
                        accessor: "http_status_code",
                        className: "header-small",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.http_status_code != null
                                    ? row.row.original.http_status_code
                                    : "-"}
                            </div>
                        ),
                    },
                    {
                        Header: "Encrypted Request Headers",
                        accessor: "request_headers",
                        className: "header-large",
                        sort: true,
                        Cell: (row) => (
                            <div style={{ textAlign: "center" }}>
                                {row.row.original.request_headers != null
                                    ? row.row.original.request_headers
                                    : "-"}
                            </div>
                        ),
                    }
                ],
            },
        ],
        []
    );


    const [callBackValue, setCallBackValue] = React.useState("");
    const providerCallbackApi = () => {
        APIConfig.API_Client.post(
            apiEndpointList.GET_CALLBACK_VALUES.baseUrl + apiEndpointList.GET_CALLBACK_VALUES.endpoint,
            {},
            { cancelToken: source.token }
        )
            .then((response) => {
                setCallBackValue(response.data)
            })
            .catch((error) => { });
    }


    React.useEffect(() => {
        providerCallbackApi();
    }, []);

    let mappedResult;
    if (
        callBackValue.length > 0
    ) {
        mappedResult = callBackValue
    }

    return (
        <div className="sort-table">
            {mappedResult ? (
                <DataTable
                    columns={callbackHeader}
                    data={mappedResult}
                    csvName="ProviderCallbacksConfigurationSummaryDetails"
                />
            ) : (
                <TableLoader />
            )}
        </div>
    )
}

export default ProviderCallbacksConfigurationSummary;