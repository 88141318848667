import React from "react";
// component imports
import AvailableModulesList from "../AvailableModulesList/AvailableModulesList";
// context imports
import { CreditManagementProvider } from "../../../contexts/CreditManagementContext";
// styles imports
import "./EditCredits.scss";

const EditCredits = () => {
  return (
    <React.Fragment>
      <div className="edit-credits-page">
        <div className="edit-credits-content">
          <div className="edit-credits-card">
            <CreditManagementProvider>
              <AvailableModulesList />
            </CreditManagementProvider>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditCredits;
