import React, { useContext, useEffect, useState } from "react";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import axios from "axios";
import Box from "@material-ui/core/Box";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import CompanyDetailsProd from "./CompanyDetailsProd/CompanyDetailsProd";
import OnboardProdContext from "../../contexts/OnboardProdContext/OnboardProdContext";
import ModuleProviderProd from "./ModuleProviderProd/ModuleProviderProd";
import OnBoardSummaryProd from "./OnBoardSummaryProd/OnBoardSummaryProd";
import { randomUUID } from "../../services/randomUUID";
import ProviderProd from "./ProviderProd/ProviderProd";

// styles import
import "./OnBoardCustomerProd.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

let modulesProvider;
let moduleOtherNew;

const OnBoardCustomerProd = () => {
  // ^ context
  const { onboardingStep, tabDisabled, updateTab } =
    useContext(OnboardProdContext);

  // & state variables
  const [moduleProviderMerge, setModuleProviderMerge] = useState([]);

  // Provider Names API calling function
  const providerNamesApi = () => {
    APIConfig.API_Client.get(
      apiEndpointList.COMPANY_MODULE_PROVIDER.baseUrl +
        apiEndpointList.COMPANY_MODULE_PROVIDER.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        moduleOtherNew = response.data.data.map((item) => {
          return {
            ...item,
            providerDetails: item.providerDetails.map(
              (itm) =>
                itm.isActive === true && {
                  ...itm,
                  value: itm.name,
                  label: itm.name,
                }
            ),
          };
        });
      })
      .catch((error) => {});
  };

  // Modules Data API calling function
  const modulesData = () => {
    APIConfig.API_Client.get(
      apiEndpointList.COMPANY_MODULES.baseUrl +
        apiEndpointList.COMPANY_MODULES.endpoint,
      { cancelToken: source.token }
    )
      .then((response) => {
        modulesProvider = response.data.result.filter(
          (module) => module.is_active === true
        );
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const mergeByModuleCode = (a1, a2) =>
    a1
      .map((itm) => ({
        ...a2.find((item) => item.moduleCode === itm.code && item),
        ...itm,
        moduleSecret: randomUUID(),
      }))
      .filter(
        (mod) => !!mod.providerDetails && mod.providerDetails.length !== 0
      );

  useEffect(() => {
    if (modulesProvider && moduleOtherNew) {
      setModuleProviderMerge(
        mergeByModuleCode(modulesProvider, moduleOtherNew)
      );
    }
  }, [modulesProvider, moduleOtherNew]);

  useEffect(() => {
    providerNamesApi();
    modulesData();
  }, []);

  return (
    <div className="onboard-customer-prod-page">
      <div className="onboard-customer-prod-content">
        <div className="onboard-customer-prod">
          <h1 className="heading">Onboard Merchant</h1>
        </div>

        {/* Tabs */}
        <div className="onboard-customer-forms">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={onboardingStep}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList>
                  <Tab
                    className="companyDetails onboard-tab"
                    disabled={tabDisabled.companyDetails}
                    label="Company Details"
                    value="companyDetails"
                  />
                  <Tab
                    className="moduleTab onboard-tab"
                    disabled={tabDisabled.moduleTab}
                    label="Module"
                    value="moduleTab"
                  />
                  <Tab
                    className="providerTab onboard-tab"
                    disabled={tabDisabled.providerTab}
                    label="Provider"
                    value="providerTab"
                  />
                  <Tab
                    className="summary onboard-tab"
                    disabled={tabDisabled.summary}
                    label="Summary"
                    value="summary"
                  />
                </TabList>
              </Box>
              <TabPanel value="companyDetails">
                <div id="companyDetails">
                  <CompanyDetailsProd updateTab={updateTab} />
                </div>
              </TabPanel>
              <TabPanel value="moduleTab">
                <div id="moduleTab">
                  <ModuleProviderProd
                    moduleProviderMerge={moduleProviderMerge}
                  />
                </div>
              </TabPanel>
              <TabPanel value="providerTab">
                <div id="providerTab">
                  <ProviderProd moduleProviderMerge={moduleProviderMerge} />
                </div>
              </TabPanel>
              <TabPanel value="summary">
                <div id="summary">
                  <OnBoardSummaryProd />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {/* Tabs ends */}
      </div>
    </div>
  );
};

export default OnBoardCustomerProd;
