import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import {
  APIConfig,
  APIConfigFormData,
} from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

let currentDate = new Date();
const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

const initialValues = {
  account_number: "",
  file_date: "",
  file: null,
  provider_code: "",
  statement_type: "",
};

const { accountNumberRegex } = REGEXP;
const validationSchema = Yup.object({
  account_number: Yup.string().when("statement_type", {
    is: (statement_type) => statement_type?.label === "banking",
    then: () =>
      Yup.string()
        .matches(accountNumberRegex)
        .required("Account Number is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  file_date: Yup.string().when("statement_type", {
    is: (statement_type) => statement_type?.label === "collection",
    then: () => Yup.string().required("File Date is required").max(currentDate),
    otherwise: () => Yup.string().notRequired(),
  }),
  file: Yup.mixed().required("File is required"),
  provider_code: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  statement_type: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const UploadStatement = () => {
  const [providerList, setProviderList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const file = document.getElementById("file").files[0];
      const formData = new FormData();
      formData.set("file", file);
      formData.append("reference_id", randomUUID());
      formData.append("statement_type", values.statement_type.label);
      formData.append("provider_code", values.provider_code.label);

      if (values.statement_type.label === "collection")
        formData.append("file_date", values.file_date);

      if (values.statement_type.label === "banking")
        formData.append("account_number", values.account_number);

      action.setSubmitting(true);
      APIConfigFormData.API_Client.post(
        apiEndpointList.UPLOAD_PROVIDER_STATEMENT.baseUrl +
          apiEndpointList.UPLOAD_PROVIDER_STATEMENT.endpoint,
        formData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          const fileInput = document.getElementById("file");
          if (fileInput) fileInput.value = "";
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.four_character_bank_code.toLowerCase(),
        }));
        setProviderList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getProviderList();
  }, []);

  useEffect(() => {
    if (formik.values.statement_type?.label === "banking") {
      formik.setFieldValue("account_number", "");
      formik.setFieldTouched("account_number", false, false);
    }
    if (formik.values.statement_type?.label === "collection") {
      formik.setFieldValue("file_date", "");
      formik.setFieldTouched("file_date", false, false);
    }
  }, [formik.values.statement_type]);

  const statementType = [
    { value: 1, label: "banking" },
    { value: 2, label: "collection" },
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("file", file);
  };

  const handleDateSelect = (e) => {
    const date = e.target.value;
    if (!date || new Date(date) <= currentDate )
      formik.setFieldValue("file_date", date);
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-divider"></div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider Code"
                required={true}
                isLoading={isLoading}
                placeholder="Select Provider"
                isformatOptionLabel={true}
              />
              <TextFieldSelect
                id="statement_type"
                name="statement_type"
                onChange={(selectedOption) =>
                  formik.setFieldValue("statement_type", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("statement_type", true)}
                value={formik.values.statement_type}
                options={statementType}
                noOptionsMessage={() => "No such statement type exists"}
                label="Statement Type"
                required={true}
                isLoading={isLoading}
                placeholder="Select Statement Type"
              />
            </div>
            <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileUpload}
                  required={true}
                />
                <label htmlFor="file" className="ui-file-label">
                  {"Upload File"} <span className="required-field">*</span>
                </label>
              </div>

              {formik.values.statement_type?.label === "banking" ? (
                <TextFieldInput
                  id="account_number"
                  name="account_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account_number}
                  touched={formik.touched.account_number}
                  error={formik.errors.account_number}
                  placeholder="Enter Account Number"
                  label="Account Number"
                  required={true}
                  disabled={false}
                />
              ) : null}

              {formik.values.statement_type?.label === "collection" ? (
                <div className={`ui-form-input-section ui-form-content-input`}>
                  <input
                    type="date"
                    id="file_date"
                    name="file_date"
                    onChange={handleDateSelect}
                    value={formik.values.file_date}
                    required={true}
                    max={currentDate.toISOString().split("T")[0]}
                  />
                  <label htmlFor="file_date" className="ui-file-label">
                    {"Choose File Date"}{" "}
                    <span className="required-field">*</span>
                  </label>
                </div>
              ) : null}
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadStatement;
