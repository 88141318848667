import React, { useState } from "react";
// component imports
import OnBoardingSummary from "../SortTableSummary/OnBoardingSummary";
// styles imports
import "./AllCustomers.scss";
import { VaDetailModal } from "./VaDetailModal/VaDetailModal";

const AllCustomers = () => {
  const [showVaDetailModal, setShowVaDetailModal] = useState(false);
  const openFetchVaModal = () => {
    setShowVaDetailModal(true);
  };

  return (
    <React.Fragment>
      <div className="customer-configuration-page">
        <div className="customer-configuration-content">
          <div className="customer-configuration">
            <h1 className="heading">All Merchants</h1>
            {/* prod env based rendering */}
            {process.env.REACT_APP_READ_API_ENDPOINT ===
              "https://in.decentro.tech/frontend_sa" && (
              <button
                onClick={openFetchVaModal}
                className={`fetch-va-btn`}
                type="button"
              >
                Fetch VA
              </button>
            )}
          </div>
          <div className="customer-configuration-card">
            {showVaDetailModal ? (
              <VaDetailModal setShowModal={setShowVaDetailModal} />
            ) : null}
            <OnBoardingSummary />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllCustomers;
