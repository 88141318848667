import React from "react";
import ComponentWrapper from "../../UI/ComponentWrapper/ComponentWrapper";
import ValidateAccountForm from "./ValidateAccountForm";

const ValidateBankAccount = () => {
  return (
    <React.Fragment>
      <ComponentWrapper
        heading="Validate Bank Account"
        component={<ValidateAccountForm />}
      />
    </React.Fragment>
  );
};

export default ValidateBankAccount;
