import React, { useContext } from "react";

// component imports
import IPManagementSummary from "../SortTableSummary/IPManagementSummary/IPManagementSummary";
import ManageIpsForm from "./ManageIpsForm/ManageIpsForm";
import ComponentWrapper from "../../UI/ComponentWrapper/ComponentWrapper";
import FeatureUnavailable from "../../UI/FeatureUnavailable/FeatureUnavailable";

// context imports
import IPManagementActionContext from "../../contexts/IPManagementActionContext";

const IPManagement = () => {
  const { cardType } = useContext(IPManagementActionContext);

  const CREDIT_MANAGEMENT_CARD = {
    editIps: <ManageIpsForm />,
    allCompanies: <IpManagementSummaryCard />,
  };

  if (process.env.REACT_APP_ENV_NAME === "staging") {
    return (
      <React.Fragment>
        <ComponentWrapper
          heading="IP Management"
          component={<FeatureUnavailable />}
        />
      </React.Fragment>
    );
  }

  return (
    <div>
      <div>{CREDIT_MANAGEMENT_CARD[cardType]}</div>
    </div>
  );
};

const IpManagementSummaryCard = () => {
  return (
    <div className="credits-management-page">
      <div className="credits-management-content">
        <div className="credits-management">
          <h1 className="heading">Manage IPs</h1>
        </div>
        <div className="credits-management-card">
          <IPManagementSummary />
        </div>
      </div>
    </div>
  );
};

export default IPManagement;
