import React, { useState, useEffect, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//component-import
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  settlement_cycle_urn: "",
};

const validationSchema = Yup.object({
  settlement_cycle_urn: Yup.object().shape({
      value: Yup.string().required("Please select an option"),
      label: Yup.string().required("Please select an option"),
    }).required(),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementBatchJob = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const [isError, setIsError] = useState(false);
  const [settlementUrnOptions, setSettlementUrnOptions] = useState([""]);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {

      const payloadData = {
        reference_id: randomUUID(),
        batch_process_name: "AUTO_EXTERNAL_BALANCE_SETTLEMENT",
      };

    payloadData["batch_data"] = {
        settlement_cycle_urn: values.settlement_cycle_urn.value
    };

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.INVOKE_SETTLEMENT_BATCH_JOB.baseUrl +
          apiEndpointList.INVOKE_SETTLEMENT_BATCH_JOB.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getSettlementCycleUrn = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.SETTLEMENT_CYCLE_URN.baseUrl +
        apiEndpointList.SETTLEMENT_CYCLE_URN.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {


        const options = response.data.map((item) => ({
          value: item.settlement_cycle_urn,
          label: item.name,
        }));


        setSettlementUrnOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        setSettlementUrnOptions([]);
        setIsError(true);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    getSettlementCycleUrn()
  }, []);


  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
              <div className="ui-form-inputs-section">
                <TextFieldSelect
                  id="settlement_cycle_urn"
                  name="settlement_cycle_urn"
                  onChange={(selectedOption) =>
                    formik.setFieldValue(
                      "settlement_cycle_urn",
                      selectedOption
                    )
                  }
                  onBlur={() =>
                    formik.setFieldTouched(
                      "settlement_cycle_urn",
                      true
                    )
                  }
                  value={formik.values.settlement_cycle_urn}
                  options={settlementUrnOptions}
                  noOptionsMessage={() => "No such cycle exists"}
                  label="Settlement Cycle"
                  required={true}
                  isLoading={isLoading}
                  isError={isError}
                  placeholder="Select Settlement Cycle"
                  isformatOptionLabel={false}
                />
            </div>
            <div className="settlement-details-submit ui-button-container">
              <button
                className={`settlement-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SettlementBatchJob;
