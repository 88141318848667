import React from "react";

//styles-import
import "./ResponseDetails.scss";

const ResponseDetails = ({ data, onClick }) => {
  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          CAS Response Details
          <img
            src="/images/close.svg"
            className="close-response-details"
            alt="close"
            onClick={onClick}
          />
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          <ul className="response-list">
            {/* 1 */}
            <li className="response-list-item">
              <span className="field">Message: </span>
              {data?.message ? (
                <span className="value">{data?.message || "-"}</span>
              ) : (
                ""
              )}
            </li>
            {/* 2 */}
            {/* {data?.url ? (
              <li className="response-list-item">
                <span className="field">URL: </span>
                <span className="value">
                  <a href={data.url} rel="noopener noreferrer">
                    Download
                  </a>
                </span>
              </li>
            ) : (
              ""
            )} */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
