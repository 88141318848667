import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom";
// api config imports
import axios from "axios";
import apiEndpointList from "../config/modules/customer_management/endpoint";
import { APIConfig } from "../services/apiConfiguration";
// component imports
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";
// context imports
import CallbackActionContext from "./CallbackActionContext";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CallbackContext = createContext();

export const CallbackProvider = ({ children }) => {
  const [showCallbackList, setShowCallbackList] = useState(true);
  const [resetCallback, setResetCallback] = useState(false);
  const [listIds, setListIds] = useState("");
  const [searchCompanyID, setSearchCompanyID] = useState("");
  // modal state
  const [showModal, setShowModal] = useState(false);
  const [showTotpModal, setShowTotpModal] = useState(false);
  // edit callback state
  const [editCallbackItem, setEditCallbackItem] = useState({});

  // open modal handler
  const openModal = () => {
    setShowModal(true);
  };
  const openTotpModal = () => {
    setShowTotpModal(true);
  };

  // set edit callback item
  const callbackEditItem = (item) => {
    setEditCallbackItem(item);
  };

  // context
  const { setTableEditData } = useContext(CallbackActionContext);

  // & PUT ==> callback put(edit) request : @ edit
  const editCallback = (payload) => {
    setTableEditData({ item: "", edit: false });

    APIConfig.API_Client.put(
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint,
      // APIConfig.BASE_URL + apiEndpointList.GET_CALLBACK_DETAILS,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setResetCallback(!resetCallback);
        setShowCallbackList(true);

        setListIds({
          ...listIds,
          companyId: payload.company_id,
          callbackType: payload.callback_type,
        });

        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={"Callback Updated successfully"}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  // & add callback post request
  const addCallbackApi = (payload, companyName) => {
    setListIds({
      ...listIds,
      companyId: payload.company_id,
      callbackType: payload.callback_type,
    });

    setShowCallbackList(false);

    APIConfig.API_Client.post(
      apiEndpointList.GET_CALLBACK_DETAILS.baseUrl +
        apiEndpointList.GET_CALLBACK_DETAILS.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
        setResetCallback(!resetCallback);
        setShowCallbackList(true);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  // & DELETE callback request
  const deleteCallbackApi = (payload) => {
    setShowCallbackList(false);

    APIConfig.API_Client.delete(
      `${apiEndpointList.GET_CALLBACK_DETAILS.baseUrl}${apiEndpointList.GET_CALLBACK_DETAILS.endpoint}`,
      {
        data: payload,
        cancelToken: source.token,
      }
    )
      .then((response) => {
        // Show success message
        ReactDOM.render(
          <SnackbarMessage msgtype="success" msg={response.data.message} />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        // Show error message
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={error?.response?.data?.message || "Something went wrong!"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        // Reset and show callback list regardless of success or error
        setResetCallback((prevReset) => !prevReset);
        setShowCallbackList(true);
      });
  };

  return (
    <CallbackContext.Provider
      value={{
        resetCallback,
        setResetCallback,
        addCallbackApi,
        deleteCallbackApi,
        showCallbackList,
        setShowCallbackList,
        listIds,
        setListIds,
        callbackEditItem,
        setEditCallbackItem,
        editCallbackItem,
        editCallback,
        openModal,
        setShowModal,
        showModal,
        showTotpModal,
        setShowTotpModal,
        openTotpModal,
        searchCompanyID,
        setSearchCompanyID,
      }}
    >
      {children}
    </CallbackContext.Provider>
  );
};

export default CallbackContext;
