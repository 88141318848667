import React from "react";
import KeyboardArrowDownSharp from "@material-ui/icons/KeyboardArrowDownSharp.js";
import { useHistory } from "react-router-dom";
import { clearAuthTokens } from "axios-jwt";
import { APIConfig } from "../../services/apiConfiguration.js";
import "./Header.scss";

const Header = () => {
  let history = useHistory();
  let user = {};
  if (JSON.parse(localStorage.getItem("user")))
    user = JSON.parse(localStorage.getItem("user"));

  const toggleMenu = () => {
    document.querySelector(".dropdown-content").classList.toggle("active");
  };

  const closeDropDown = () => {
    document.querySelector(".dropdown-content").classList.remove("active");
  };

  return (
    <div className="header">
      <div className="left-side">
        <img src="/images/decentro-logo.svg" alt="logo" />
      </div>
      <div className="right-side">
        <div className="user">
          <div className="dropdown" tabIndex="0" onBlur={closeDropDown}>
            <span onClick={toggleMenu}>
              {user.name ? user.name : "User"} <KeyboardArrowDownSharp />
            </span>
            <div className="dropdown-content">
              <ul>
                <li>Logged in with {user.email ? user.email : "your email"}</li>
                <li
                  className="logout"
                  onClick={() => {
                    clearAuthTokens();
                    localStorage.removeItem("currentActiveTabIndex");
                    localStorage.removeItem("currentSubmenu");
                    localStorage.clear();
                    history.push("/login");
                    window.location.reload(true);
                  }}
                >
                  Logout
                </li>
              </ul>
            </div>
          </div>
          <div className="avatar">{user.name ? user.name[0] : "User"}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
