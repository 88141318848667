const moduleOrder = [
  "kyc_and_onboarding",
  "core_banking",
  "aml_and_compliance",
  "payments",
  "ppi_wallet",
  "ppi_full_kyc",
  "financial_services",
  "lending",
];

export default moduleOrder;
