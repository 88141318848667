import React, { useState, useEffect, useContext, useRef } from "react";
import Select from "react-select";
import OnboardProdContext from "../../../contexts/OnboardProdContext/OnboardProdContext";
import { randomUUID } from "../../../services/randomUUID";

// react select import
import makeAnimated from "react-select/animated";

// styles import
import "./ModuleProviderProd.scss";

const animatedComponents = makeAnimated();

let resultForModuleSecretFilter;

const ModuleProviderProd = ({ moduleProviderMerge }) => {
  // hardcoded mva provider dropdown
  let mvaProviderDetails = [
    {
      accountName: "IDFC Bank",
      label: "IDFC Bank 2 (D8606)",
      bankCode: "IDFB",
      value: "10170578606",
      accountNumber: "10170578606",
      ifscCode: "IDFB0080151",
      eCollectCode: "D8606",
      alias: "decentro_account_idfc_2",
    },
    {
      accountName: "IDFC",
      label: "IDFC Bank 1 (D9535)",
      bankCode: "IDFB",
      value: "10139953666",
      accountNumber: "10139953666",
      ifscCode: "IDFB0080101",
      eCollectCode: "D9535",
      alias: "decentro_account_idfc_1",
    },
    {
      accountName: "DBS",
      label: "DBS",
      bankCode: "DBSS",
      value: "8827210000008167",
      accountNumber: "8827210000008167",
      ifscCode: "DBSS0IN0811",
      eCollectCode: 9365,
      alias: "decentro_dbs_account_1",
    },
    {
      accountName: "ICICI",
      label: "ICICI",
      value: "313205000647",
      bankCode: "icic",
      accountNumber: "313205000647",
      ifscCode: "ICIC0003132",
      eCollectCode: 462530,
      alias: "decentro_account_icici_2",
    },
    {
      accountName: "Yesbank Lender Escrow",
      label: "Yesbank Lender Escrow",
      bankCode: "yesb",
      value: "002266200001920",
      accountNumber: "002266200001920",
      ifscCode: "YESB0000022",
      eCollectCode: 462510,
      alias: "decentro_account_ybl_2",
    },

    {
      accountName: "Yesbank Vender Escrow",
      label: "Yesbank Vender Escrow",
      bankCode: "yesb",
      value: "002266200001930",
      accountNumber: "002266200001930",
      ifscCode: "YESB0000022",
      eCollectCode: 462511,
      alias: "decentro_account_ybl_3",
    },
    {
      accountName: "Axis Escrow",
      label: "Axis Escrow",
      bankCode: "utib",
      value: "922020047762308",
      accountNumber: "922020047762308",
      ifscCode: "UTIB0000009",
      eCollectCode: 9365,
      alias: "decentro_account_axis_2",
    },
  ];

  // ^ context
  const {
    companyModulesApi,
    companyDetailsResponse,
    moduleProviderLoading,
    setModuleSecretsSummary,
    goToProvider,
    goToSummary,
    setProviderIDSummaryCode,
    // ----- -----
    configureMVA,
  } = useContext(OnboardProdContext);

  // ^ state variables
  const [modulesInputData, setModulesInputData] = useState(moduleProviderMerge);
  // ^ prepaid account ID state
  const [prepaidAccountID, setPrepaidAccountID] = useState(null);
  const [prepaidAccountIDError, setPrepaidAccountIDError] = useState(null);

  // * mva check state
  const [enableMva, setEnableMva] = useState(true);
  // * btn validation state
  const [moduleBtnValidation, setModuleBtnValidation] = useState(false);
  // * mva btn validation state
  const [mvaCheckBox, setMvaCheckBox] = useState(false);
  const [mvaDetails, setMvaDetails] = useState([]);
  const [mvaBtnValidation, setMvaBtnValidation] = useState(false);

  const prepaidAccountHandler = (event) => {
    let integerRegex = /^\d+$/;
    setPrepaidAccountID(event.target.value);

    if (event.target.value.match(integerRegex)) {
      setPrepaidAccountIDError(false);
    } else {
      setPrepaidAccountIDError(true);
    }
  };

  const mvaValidationHandler = (event) => {
    setMvaCheckBox(event.target.checked);
  };

  const mvaProviderHandler = (event) => {
    setMvaDetails(event);
  };

  useEffect(() => {
    // * btn validation filters
    const moduleChecked = modulesInputData.filter(
      (moduleCheckedDetail) => moduleCheckedDetail.moduleActiveChecked === true
    );

    if (moduleChecked.length > 0) {
      setModuleBtnValidation(true);
    } else {
      setModuleBtnValidation(false);
    }

    // * module & provider payload
    const moduleSecretFilter = modulesInputData.filter(
      (moduleCheckedDetail) => {
        if (moduleCheckedDetail.code === "kyc_and_onboarding") {
          return moduleCheckedDetail.moduleActiveChecked === true;
        } else {
          return moduleCheckedDetail.moduleActiveChecked === true;
        }
      }
    );

    if (prepaidAccountID && !prepaidAccountIDError) {
      resultForModuleSecretFilter = moduleSecretFilter.map((moduleDetail) => {
        return {
          code: moduleDetail.code,
          secret: randomUUID(),
          // ! to be edited later
          prepaid_credits_account_id: +prepaidAccountID,
        };
      });

      // * Setting the prepaid_credits_account_id to context for summary
      setProviderIDSummaryCode(+prepaidAccountID);
    } else {
      resultForModuleSecretFilter = moduleSecretFilter.map((moduleDetail) => {
        return {
          code: moduleDetail.code,
          secret: randomUUID(),
        };
      });
    }
  }, [modulesInputData, prepaidAccountID]);

  // ! kept for debugging purposes only
  const goToProviderTab = () => {
    let singleCallResultForModuleSecretFilter = resultForModuleSecretFilter;

    companyModulesApi(singleCallResultForModuleSecretFilter);

    // * for summary Tab
    setModuleSecretsSummary(singleCallResultForModuleSecretFilter);

    // & If only KYC selected
    if (
      resultForModuleSecretFilter.length === 1 &&
      resultForModuleSecretFilter[0].code === "kyc_and_onboarding"
    ) {
      // * move to summary tab
      goToSummary();
    } else {
      // * move to provider tab
      goToProvider();
    }
  };

  // & mva validation
  useEffect(() => {
    if (mvaDetails.length !== 0 && !!mvaCheckBox) {
      setMvaBtnValidation(true);
    } else {
      setMvaBtnValidation(false);
    }
  }, [mvaDetails, mvaCheckBox]);

  const submitModuleProviderDetails = () => {
    let singleCallResultForModuleSecretFilter = resultForModuleSecretFilter;

    companyModulesApi(singleCallResultForModuleSecretFilter);

    // * for summary Tab
    setModuleSecretsSummary(singleCallResultForModuleSecretFilter);

    if (mvaBtnValidation) {
      // * Below commented code is for single MVA creation legacy code
      // let { bankCode, ifscCode, accountNumber, eCollectCode } = mvaDetails;

      // let payloadData = {
      //   provider_code: bankCode,
      //   ifsc: ifscCode,
      //   account_number: accountNumber,
      //   designated_prefix: eCollectCode.toString(),
      // };

      // & MVA API call
      // configureMVA(payloadData);

      // * Below code refers to multiple MVA creation logic
      mvaDetails.map((details) => {
        let { bankCode, ifscCode, accountNumber, eCollectCode } = details;

        let payloadData = {
          provider_code: bankCode,
          ifsc: ifscCode,
          account_number: accountNumber,
          designated_prefix: eCollectCode.toString(),
        };

        // & MVA API call
        configureMVA(payloadData);
        return details;
      });
    }
  };

  return (
    <div className="module-provider-prod-form">
      <h2 className="form-heading">Fill company details to continue</h2>

      <div className="module-selection">
        {/* company name block */}
        <div className="company-container">
          <p className="company-label">Company</p>
          <p className="company-name">
            {companyDetailsResponse?.fullName || "NA"}
          </p>
        </div>

        <div className="module-selection-container">
          <h3 className="select-modules-heading">Select Modules</h3>
          {/* select modules */}
          <div className="select-modules-container">
            {moduleProviderMerge.map((module) => {
              return (
                module.providerDetails && (
                  <div key={module.name} className="module-select-container">
                    {/* module checkbox */}
                    <div className="module-checkbox-container">
                      <input
                        onChange={(event) => {
                          module.moduleCheck = event.target.checked;
                          module.moduleActiveChecked = event.target.checked;
                          setModulesInputData([...moduleProviderMerge]);
                        }}
                        type="checkbox"
                        id={module.moduleCode}
                        name={module.moduleCode}
                        checked={module.moduleCheck}
                      />

                      <p className="module-heading">{module.name}</p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>

        {/* mva creation block on check */}
        {enableMva && (
          <div className="mva-creation-container">
            <div className="mva-heading-container">
              <input type="checkbox" onChange={mvaValidationHandler} />
              <p className="mva-heading">
                Do you want to create MVA for this customer ?
              </p>
            </div>

            {/* mva dropdown */}
            <div className="mva-select-container">
              <div className="mva-provider-dropdown-container">
                <p className="mva-provider-heading">Provider</p>
                <Select
                  // className="basic-single"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  onChange={mvaProviderHandler}
                  isDisabled={!mvaCheckBox}
                  options={mvaProviderDetails}
                  placeholder="Select Provider"
                />
              </div>
            </div>
          </div>
        )}

        {/* prepaid_credits_account_id field */}
        <div class="module-input-container">
          <div class="text-field-wrapper">
            <h3 className="module-textfield-label" htmlFor="prepaid-account-id">
              Prepaid Account ID
            </h3>
            <div class="prepaid-field-container">
              <input
                type="text"
                onChange={prepaidAccountHandler}
                name="prepaidAccountIDInput"
                id="prepaid-account-id"
                className="module-textfield"
              />
            </div>
            {prepaidAccountIDError && (
              <p className="error-prepaid-message">
                Enter a valid prepaid account ID
              </p>
            )}
          </div>
        </div>

        <div className="company-details-submit button-container">
          <button
            className={`company-details-submit 
            ${moduleBtnValidation ? "active" : ""}
            `}
            disabled={!moduleBtnValidation}
            onClick={submitModuleProviderDetails}
            // !kept for debugging purposes only
            // onClick={goToProviderTab}
          >
            {moduleProviderLoading ? "Loading..." : "Save & Continue"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleProviderProd;
