import React, { createContext, useState } from "react";
import { APIConfig } from "../services/apiConfiguration";
import axios from "axios";
import apiEndpointList from "../config/modules/customer_management/endpoint";

const MasterSearchContext = createContext();
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export const MasterSearchProvider = ({ children }) => {
  // context state's'
  const [moduleType, setModuleType] = useState("collections");
  const [searchType, setSearchType] = useState("decentro_urn");

  const [searchDetail, setSearchDetail] = useState({
    detail: [],
    loading: false,
    error: false,
  });

  // resetMasterSearch
  const resetMasterSearch = () => {
    setSearchDetail({ detail: [], loading: false, error: false });
  };

  // search api call
  const masterSearchUrn = (payloadValue) => {
    setSearchDetail({
      ...searchDetail,
      detail: [],
      loading: true,
    });

    APIConfig.API_Client.post(
      apiEndpointList.MASTER_SEARCH_TRANSACTION.baseUrl + apiEndpointList.MASTER_SEARCH_TRANSACTION.endpoint + "/" + `${moduleType}/decentro-urn`,
      {
        cancelToken: source.token,
        decentro_urn: payloadValue,
      }
    )
      .then((response) => {
        setSearchDetail({
          ...searchDetail,
          detail: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        setSearchDetail({
          detail: [],
          loading: false,
          error: true,
        });
      });
  };

  // search api call
  const masterSearchBankReference = (payloadValue) => {
    setSearchDetail({
      ...searchDetail,
      detail: [],
      loading: true,
    });
    APIConfig.API_Client.post(
      apiEndpointList.MASTER_SEARCH_TRANSACTION.baseUrl + apiEndpointList.MASTER_SEARCH_TRANSACTION.endpoint + "/" + `${moduleType}/bank-reference`,
      // APIConfig.BASE_URL +
      // apiEndpointList.MASTER_SEARCH_TRANSACTION + "/" + `${moduleType}/bank-reference`,
      {
        cancelToken: source.token,
        bank_reference_number: payloadValue,
      }
    )
      .then((response) => {
        setSearchDetail({
          ...searchDetail,
          detail: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        setSearchDetail({
          detail: [],
          loading: false,
          error: true,
        });
      });
  };

  return (
    <MasterSearchContext.Provider
      value={{
        setModuleType,
        moduleType,
        searchType,
        setSearchType,
        masterSearchUrn,
        masterSearchBankReference,
        searchDetail,
        resetMasterSearch
      }}
    >
      {children}
    </MasterSearchContext.Provider>
  );
};

export default MasterSearchContext;
