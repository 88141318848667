import React, { useContext } from "react";
// component imports
import CreditsManagementSummary from "../SortTableSummary/CreditsManagementSummary/CreditsManagementSummary";
import EditCredits from "./EditCredits/EditCredits";
// context imports
import CreditsActionContext from "../../contexts/CreditsActionContext";
// styles imports
import "./CreditsManagement.scss";

const CreditsManagement = () => {
  const { cardType } = useContext(CreditsActionContext);

  const CREDIT_MANAGEMENT_CARD = {
    editCredits: <EditCredits />,
    allCredits: <CreditsTableSummaryCard />,
  };

  return (
    <div>
      <div>{CREDIT_MANAGEMENT_CARD[cardType]}</div>
    </div>
  );
};

const CreditsTableSummaryCard = () => {
  return (
    <div className="credits-management-page">
      <div className="credits-management-content">
        <div className="credits-management">
          <h1 className="heading">Credits Management</h1>
        </div>
        <div className="credits-management-card">
          <CreditsManagementSummary />
        </div>
      </div>
    </div>
  );
};

export default CreditsManagement;
