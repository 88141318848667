import React from "react";
// component imports
import BalanceRow from "../BalanceRow/BalanceRow";
import BalanceRowStaging from "../BalanceRow/BalanceRowStaging";
// utilities imports
import accountBalanceData from "../../../utilities/accountBalancesProviders/commonEnvAccounts";
import productionAccountBalanceData from "../../../utilities/accountBalancesProviders/productionBankAccounts";
// styles imports
import "../AccountBalances/AccountBalances.scss";

const AccountBalances = () => {
  // refresh's the current page
  const refreshAccountBalance = () => {
    window.location.reload();
  };
  return (
    <div className="account-balances-page">
      <div className="account-balances-content">
        <div className="account-balances">
          <h1 className="heading">Account Balances</h1>
          <button
            onClick={refreshAccountBalance}
            className={`balances-refresh-btn`}
            type="button"
          >
            <span>
              <img src="/images/refresh-icon.svg" alt="refresh icon" />
            </span>
            Refresh Balances
          </button>
        </div>
        <div className="account-balances-card">
          <BalancesTable />
        </div>
      </div>
    </div>
  );
};

export default AccountBalances;

const BalancesTable = () => {
  // common balance data for default env
  let balanceTableBodyComponent = (
    <tbody>
      {accountBalanceData.map(
        (detail, index) =>
          detail.account && (
            <BalanceRowStaging
              key={index}
              bankName={detail.bank}
              accountType={detail.accountType}
              accountNumber={detail.account}
              provider={detail.provider}
            />
          )
      )}
    </tbody>
  );

  // production balance data for default env
  let balanceTableBodyProductionComponent = (
    <tbody>
      {productionAccountBalanceData.map(
        (detail, index) =>
          detail.account && (
            <BalanceRow
              key={index}
              bankName={detail.bank}
              accountType={detail.accountType}
              accountNumber={detail.account}
              provider={detail.provider}
            />
          )
      )}
    </tbody>
  );

  // Rendering the balance table based on env
  const envBasedRender =
    process.env.REACT_APP_REGISTER_CLIENT_ID === "decentro_prod"
      ? balanceTableBodyProductionComponent
      : balanceTableBodyComponent;

  const dynamicAverageHeader =
    process.env.REACT_APP_REGISTER_CLIENT_ID === "decentro_prod" ? (
      <th>30 Days Running Average</th>
    ) : (
      ""
    );

  return (
    <>
      <table className="account-balances-table">
        <thead>
          <tr className="account-balances-table__header">
            <th>Provider</th>
            <th>Account Type</th>
            <th>Account Number</th>
            <th>Last Updated</th>
            <th>Balance</th>
            {dynamicAverageHeader}
          </tr>
        </thead>
        {envBasedRender}
      </table>
    </>
  );
};
