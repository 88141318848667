const bankProviderList = [
  {
    bank_name: "ICICI BANK",
    bank_code: "icic",
  },
  {
    bank_name: "YES BANK",
    bank_code: "yesb",
  },
  {
    bank_name: "AXIS BANK",
    bank_code: "utib",
  },
  {
    bank_name: "DBS BANK",
    bank_code: "dbss",
  },
  {
    bank_name: "IDFC BANK",
    bank_code: "idfb",
  },
];

export default bankProviderList;
