import React from "react";

//component-import
import BulkCBARecord from "./CBARecord/BulkCBARecord";
import SingleCBARecord from "./CBARecord/SingleCBARecord";
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";


const AccountDetails = () => {

  return (
    <React.Fragment>
     <TabComponentWrapper
        heading="Consumer Bank Account Record"
        tabs={[
          {
            className: "single-details",
            label: "Single",
            value: "single",
            component: <SingleCBARecord />,
          },
          {
            className: "bulk-details",
            label: "Bulk",
            value: "bulk",
            component: <BulkCBARecord />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default AccountDetails;
