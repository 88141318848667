import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//component-import
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
import ResponseDetails from "../../../UI/ResponseDetails/ResponseDetails";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import ListChargeback from "../ListChargeback/ListChargeback";

//API-imports
import {
  APIConfig,
  APIConfigFormData,
} from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../services/randomUUID";

//styles-import
import "../../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../../utilities/scrollIntoView";

let currentDate = new Date();
const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

const initialValues = {
  provider_code: "",
  received_on: "",
  file: null,
};

const validationSchema = Yup.object({
  file: Yup.mixed().required("File is required"),
  //! Legacy :: Updated to allow all file type allowed
  // .test(
  //   "fileType",
  //   "Only CSV files are allowed",
  //   (value) => value && value.type === "text/csv"
  // ),
  provider_code: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  received_on: Yup.string().max(currentDate),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BulkAddChargeback = () => {
  const [providerList, setProviderList] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const file = document.getElementById("file").files[0];
      const formData = new FormData();
      formData.set("file", file);
      formData.append("reference_id", randomUUID());
      formData.append("provider_code", values.provider_code.label);
      formData.append("received_on", values.received_on);

      action.setSubmitting(true);
      APIConfigFormData.API_Client.post(
        apiEndpointList.BULK_ADD_CHARGEBACK.baseUrl +
          apiEndpointList.BULK_ADD_CHARGEBACK.endpoint,
        formData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          const fileInput = document.getElementById("file");
          if (fileInput) {
            fileInput.value = "";
          }
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getProviderList = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_PROVIDERS_LIST.baseUrl +
        apiEndpointList.FETCH_PROVIDERS_LIST.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((provider) => ({
          value: provider.id,
          label: provider.four_character_bank_code.toLowerCase(),
        }));
        setProviderList(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load provider list"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getProviderList();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("file", file);
  };

  const handleDateSelect = (e) => {
    const date = e.target.value;
    if (!date || new Date(date) <= currentDate)
      formik.setFieldValue("received_on", date);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-header">
            <div
              className="ui-back-btn"
              onClick={() => {
                ReactDOM.render(
                  <ListChargeback />,
                  document.getElementById("chargeback")
                );
              }}
            >
              <img src="/images/back.svg" alt="back-icon" />
              <p className="ui-heading">Bulk Add Chargeback</p>
            </div>
          </div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="provider_code"
                name="provider_code"
                onChange={(selectedOption) =>
                  formik.setFieldValue("provider_code", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("provider_code", true)}
                value={formik.values.provider_code}
                options={providerList}
                noOptionsMessage={() => "No such provider exists"}
                label="Provider Code"
                required={true}
                isLoading={isLoading}
                placeholder="Select Provider"
                isformatOptionLabel={true}
              />
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="date"
                  id="received_on"
                  name="received_on"
                  onChange={handleDateSelect}
                  value={formik.values.received_on}
                  max={currentDate.toISOString().split("T")[0]}
                />
                <label htmlFor="received_on" className="ui-label">
                  {"Received On"}{" "}
                </label>
              </div>
            </div>
            <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  // accept=".csv"
                  onChange={handleFileUpload}
                  required={true}
                />
                <label htmlFor="file" className="ui-file-label">
                  {"Upload File"} <span className="required-field">*</span>
                </label>
                {/* ! Allowed for all file type // && formik.values.file.type !== */}
                {/* "text/csv" */}
                {formik.touched.file &&
                formik.errors.file &&
                !formik.values.file ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>
            </div>
            <div className="chargeback-details-submit ui-button-container">
              <button
                className={`chargeback-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default BulkAddChargeback;
