import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { isLoggedIn } from "axios-jwt";
// context imports
import { modulesContext } from "../../contexts/modulesContext";
import { CallbackActionProvider } from "../../contexts/CallbackActionContext";
import { CreditsManagementProvider } from "../../contexts/CreditsActionContext";
import { IPManagementProvider } from "../../contexts/IPManagementActionContext.js";
import { UserActionProvider } from "../../contexts/UserActionContext";
import { CustomerManagementProvider } from "../../contexts/CustomerManagementActionContext.js";
// import { IPWhitelistedActionProvider } from "../../contexts/IPWhitelistedActionContext";
// component imports
import Header from "../../components/Header/Header";
import TabPanel from "../../components/TabPanel/TabPanel.js";
import SubmenuBar from "../../components/SubmenuBar/SubmenuBar";
import OnboardCustomer from "../../components/OnboardCustomer/OnboardCustomer";
import AllCustomers from "../../components/AllCustomers/AllCustomers";
import ManageCustomers from "../../components/ManageCustomers/ManageCustomers.js";
import CallbackConfiguration from "../../components/CallbackConfiguration/CallbackConfiguration";
import CreditsManagement from "../../components/CreditsManagement/CreditsManagement";
import ConfigureMvaCard from "../../components/ConfigureMVA/ConfigureMvaCard/ConfigureMvaCard";
import UserManagement from "../../components/UserManagement/UserManagement";
import AccountBalances from "../../components/AccountManagement/AccountBalances/AccountBalances";
import CreditConsumption from "../../components/CreditsConsumption/CreditsConsumption";
import { LogoutModal } from "../../components/Shared/LogoutModal/LogoutModal";
import MarkAsFailure from "../../components/TransactionManagement/MarkAsFailureTransaction/MarkAsFailure.js";
import MarkAsSuccess from "../../components/TransactionManagement/MarkAsSuccessTransaction/MarkAsSuccess.js";
import RecordTransaction from "../../components/TransactionManagement/RecordTransaction/RecordTransaction.js";
import RemitterWhitelist from "../../components/TransactionManagement/RemitterWhitelisting/RemitterWhitelisting.js";
import SIDConfiguration from "../../components/SIDConfiguration/SIDConfiguration.js";
import UnloadAccount from "../../components/TransactionManagement/UnloadAccount/UnloadAccount.js";
import AccountDetails from "../../components/AccountManagement/AccountDetails/AccountDetails.js";
import UploadProviderStatement from "../../components/TransactionManagement/UploadProviderStatement/UploadProviderStatement.js";
import BulkProcessing from "../../components/BulkProcessManagement/BulkProcessing/BulkProcessing.js";
import ListChargeback from "../../components/TransactionManagement/ListChargeback/ListChargeback.js";
import SettlementCycle from "../../components/SettlementManagement/SettlementCycle.js";
import InvokeSettlement from "../../components/SettlementManagement/InvokeSettlement/InvokeSettlement.js";
import PauseSettlement from "../../components/SettlementManagement/PauseSettlement/PauseSettlement.js";
import AddSettlement from "../../components/SettlementManagement/AddSettlement/AddSettlement.js";
import IPManagement from "../../components/IPManagement/IPManagement.js";
import GenerateReports from "../../components/ManageReports/GenerateReports/GenerateReports.js";
import ValidateBankAccount from "../../components/ValidateBankAccount/ValidateBankAccount.js";
// import IPWhitelisted from "../../components/IPWhitelisted/IPWhitelisted";
// styles imports
import "./Dashboard.scss";

const Dashboard = () => {
  const location = useLocation();
  const page = location.pathname.split("/")[3];
  const basePage = location.pathname.split("/")[2];
  const [currentModuleData, setCurrentModuleData] = useState({
    module: "manage-merchant",
    display_name: "Manage Merchant",
    path: "/dashboard/manage-merchant/onboard-merchant",
    submenus: [
      {
        module: "onboard-merchant",
        display_name: "Onboard Merchant",
        path: "/dashboard/manage-merchant/onboard-merchant",
        submenus: [],
        icon_path: "/images/onboard-customer-icon.svg",
      },
      {
        module: "all-merchants",
        display_name: "All Merchants",
        path: "/dashboard/manage-merchant/all-merchants",
        submenus: [],
        icon_path: "/images/all-customers-icon.svg",
      },
      {
        module: "update-merchant",
        display_name: "Update Merchant",
        path: "/dashboard/manage-merchant/update-merchant",
        submenus: [],
        icon_path: "/images/manage-customer-icon.svg",
      },
      {
        module: "callback-configuration",
        display_name: "Callback Configuration",
        path: "/dashboard/manage-merchant/callback-configuration",
        submenus: [],
        icon_path: "/images/callback-configuration-icon.svg",
      },
      // {
      //   module: "provider-callbacks",
      //   display_name: "Provider Callbacks",
      //   path: "/dashboard/callback_configuration/provider-callbacks",
      //   submenus: [],
      //   icon_path: "/images/provider-callback-active.svg",
      // },
      {
        module: "credits-management",
        display_name: "Credits Management",
        path: "/dashboard/manage-merchant/credits-management",
        submenus: [],
        icon_path: "/images/credits-management-icon.svg",
      },
      {
        module: "configure-mva",
        display_name: "Configure MVA",
        path: "/dashboard/manage-merchant/configure-mva",
        submenus: [],
        icon_path: "/images/configure-mva-icon.svg",
      },
      {
        module: "user-management",
        display_name: "User Management",
        path: "/dashboard/manage-merchant/user-management",
        submenus: [],
        icon_path: "/images/user-management-icon.svg",
      },
      {
        module: "credits-consumption",
        display_name: "Credits Consumption",
        path: "/dashboard/manage-merchant/credits-consumption",
        submenus: [],
        icon_path: "/images/credits-consumption-icon.svg",
      },
      // {
      //   module: "master-search",
      //   display_name: "Master Search",
      //   path: "/dashboard/manage-merchant/master-search",
      //   submenus: [],
      //   icon_path: "/images/master-search-icon.svg",
      // },
      {
        module: "sid-configuration",
        display_name: "SID Configuration",
        path: "/dashboard/manage-merchant/sid-configuration",
        submenus: [],
        icon_path: "/images/sid_config.svg",
      },
      {
        module: "manage-ips",
        display_name: "Manage IPs",
        path: "/dashboard/manage-merchant/manage-ips",
        submenus: [],
        icon_path: "/images/ipwhitelist-active.svg",
      },
      {
        module: "validate-bank-account",
        display_name: "Validate Bank Account",
        path: "/dashboard/manage-merchant/validate-bank-account",
        submenus: [],
        icon_path: "/images/validate-bank-account-icon.svg",
      },
    ],
  });

  // for showing LogoutModal
  const [showModal, setShowModal] = useState(false);

  let logoutTimer;

  function startAutoLogout() {
    // Start timer only after user is logged in.
    if (isLoggedIn()) {
      logoutTimer = setTimeout(logout, 60 * 60 * 1000); // 60 minutes in milliseconds"
    }
  }
  function logout() {
    setShowModal(true);
  }
  // call auto-logout function only when loggedIn changes.
  useEffect(() => {
    startAutoLogout();
  }, [isLoggedIn()]);

  return (
    <React.Fragment>
      {/* Logout Modal */}
      {showModal && <LogoutModal />}

      <div className="dashboard">
        <modulesContext.Provider
          value={{ currentModuleData, setCurrentModuleData }}
        >
          <Header />
          <TabPanel />
          <Route path="/dashboard">
            <Redirect to="/dashboard/manage-merchant/onboard-merchant" />
            {(page === "onboard-merchant" && basePage === "manage-merchant") ||
            (page === "all-merchants" && basePage === "manage-merchant") ||
            (page === "update-merchant" && basePage === "manage-merchant") ||
            (page === "callback-configuration" &&
              basePage === "manage-merchant") ||
            (page === "credits-management" && basePage === "manage-merchant") ||
            (page === "configure-mva" && basePage === "manage-merchant") ||
            (page === "user-management" && basePage === "manage-merchant") ||
            (page === "credits-consumption" &&
              basePage === "manage-merchant") ||
            (page === "sid-configuration" && basePage === "manage-merchant") ||
            (page === "manage-ips" && basePage === "manage-merchant") ||
            (page === "validate-bank-account" &&
              basePage === "manage-merchant") ||
            // page === "manage-ips" ||
            // page === "provider-callbacks" ||
            // page === "master-search" ||
            (page === "account-balances" && basePage === "manage-account") ||
            (page === "account-details" && basePage === "manage-account") ||
            (page === "generate" && basePage === "reports") ||
            (page === "mark-as-failure" && basePage === "manage-transaction") ||
            (page === "mark-as-success" && basePage === "manage-transaction") ||
            (page === "bulk-processing" && basePage === "manage-transaction") ||
            (page === "record-transaction" &&
              basePage === "manage-transaction") ||
            (page === "remitter-whitelist" &&
              basePage === "manage-transaction") ||
            (page === "unload-account" && basePage === "manage-transaction") ||
            (page === "provider-statement" &&
              basePage === "manage-transaction") ||
            (page === "list-chargeback" && basePage === "manage-transaction") ||
            (page === "settlement-cycle" && basePage === "manage-settlement") ||
            (page === "invoke-settlement" &&
              basePage === "manage-settlement") ||
            (page === "pause-settlement" && basePage === "manage-settlement") ||
            (page === "add-settlement" && basePage === "manage-settlement") ? (
              <Redirect to={location.pathname} />
            ) : (
              <Redirect to="/dashboard/manage-merchant/onboard-merchant" />
            )}
          </Route>
          <React.Fragment>
            <div className="submenu-and-content">
              <SubmenuBar />
              <div className="content">
                <Route path="/dashboard/manage-merchant/onboard-merchant">
                  <OnboardCustomer />
                </Route>
                <Route path="/dashboard/manage-merchant/all-merchants">
                  <AllCustomers />
                </Route>
                <Route path="/dashboard/manage-merchant/update-merchant">
                  <CustomerManagementProvider>
                    <ManageCustomers />
                  </CustomerManagementProvider>
                </Route>
                <Route path="/dashboard/manage-merchant/callback-configuration">
                  <CallbackActionProvider>
                    <CallbackConfiguration />
                  </CallbackActionProvider>
                </Route>
                <Route path="/dashboard/manage-merchant/credits-management">
                  <CreditsManagementProvider>
                    <CreditsManagement />
                  </CreditsManagementProvider>
                </Route>
                <Route path="/dashboard/manage-merchant/validate-bank-account">
                  <ValidateBankAccount/>
                </Route>
                <Route path="/dashboard/manage-merchant/configure-mva">
                  <ConfigureMvaCard />
                </Route>
                <Route path="/dashboard/manage-merchant/user-management">
                  <UserActionProvider>
                    <UserManagement />
                  </UserActionProvider>
                </Route>
                <Route path="/dashboard/manage-merchant/credits-consumption">
                  <CreditConsumption />
                </Route>
                {/* <Route path="/dashboard/manage-merchant/master-search">
                  <MasterSearchProvider>
                    <MasterSearch />
                  </MasterSearchProvider>
                </Route> */}
                <Route path="/dashboard/manage-merchant/manage-ips">
                  <IPManagementProvider>
                    <IPManagement />
                  </IPManagementProvider>
                </Route>
                <Route path="/dashboard/manage-account/account-balances">
                  <AccountBalances />
                </Route>
                <Route path="/dashboard/reports/generate">
                  <GenerateReports />
                </Route>
                <Route path="/dashboard/manage-transaction/mark-as-failure">
                  <MarkAsFailure />
                </Route>
                <Route path="/dashboard/manage-transaction/mark-as-success">
                  <MarkAsSuccess />
                </Route>
                <Route path="/dashboard/manage-transaction/record-transaction">
                  <RecordTransaction />
                </Route>
                <Route path="/dashboard/manage-transaction/remitter-whitelist">
                  <RemitterWhitelist />
                </Route>
                <Route path="/dashboard/manage-merchant/sid-configuration">
                  <SIDConfiguration />
                </Route>
                <Route path="/dashboard/manage-transaction/unload-account">
                  <UnloadAccount />
                </Route>
                <Route path="/dashboard/manage-account/account-details">
                  <AccountDetails />
                </Route>
                <Route path="/dashboard/manage-transaction/provider-statement">
                  <UploadProviderStatement />
                </Route>
                <Route path="/dashboard/manage-transaction/bulk-processing">
                  <BulkProcessing />
                </Route>
                <Route path="/dashboard/manage-settlement/settlement-cycle">
                  <SettlementCycle />
                </Route>
                <Route path="/dashboard/manage-transaction/list-chargeback">
                  <ListChargeback />
                </Route>
                <Route path="/dashboard/manage-settlement/invoke-settlement">
                  <InvokeSettlement />
                </Route>
                <Route path="/dashboard/manage-settlement/pause-settlement">
                  <PauseSettlement />
                </Route>
                <Route path="/dashboard/manage-settlement/add-settlement">
                  <AddSettlement />
                </Route>
              </div>
            </div>
          </React.Fragment>
        </modulesContext.Provider>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
