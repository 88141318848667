import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import SnackbarMessage from "../../components/SnackbarMessage/SnackbarMessage";
import { moduleMapping } from "../../utilities/moduleMapping";
import moduleOrder from "../../utilities/moduleOrder";
import "./CustomerDetailsSummary.scss";

function CustomerDetailsSummary(props) {
  const [success, setSuccess] = useState(false);
  const [companyDetailsActive, setCompanyDetailsActive] = useState("active");
  const [height, setHeight] = useState("0px");

  const [moduleProvidersActive, setModuleProvidersActive] = useState("active");
  const [moduleProviderHeight, setModuleProviderHeight] = useState("0px");

  const [planDetailsActive, setPlanDetailsActive] = useState("active");
  const [planDetailsHeight, setPlanDetailsHeight] = useState("0px");

  const [mvaDetailsActive, setMvaDetailsActive] = useState("active");
  const [mvaDetailsHeight, setMvaDetailsHeight] = useState("0px");

  const [credentialsActive, setCredentialsActive] = useState("active");
  const [credentialsHeight, setCredentialsHeight] = useState("0px");

  const [filterFlag, setFilterFlag] = useState(false);

  const content = useRef(null);
  const moduleContent = useRef(null);
  const planDetailsContent = useRef(null);
  const credentialsContent = useRef(null);
  const mvaDetailsContent = useRef(null);

  const filteredModulesProvidersMap = props.moduleAndProvidersMap
    .filter((item) => {
      if (item.moduleCheckbox === true && !!item.moduleSecret) return item;
      return false;
    })
    .sort(function (a, b) {
      const tempKey1 = a.moduleCode;
      const tempKey2 = b.moduleCode;
      return moduleOrder.indexOf(tempKey1) - moduleOrder.indexOf(tempKey2);
    });

  useEffect(() => {
    if (
      !!!filterFlag &&
      filteredModulesProvidersMap.length == props.selectedModuleData.length
    ) {
      if (filteredModulesProvidersMap.length > 0) {
        for (let i = 0; i < filteredModulesProvidersMap.length - 1; i++) {
          let tempProvider =
            filteredModulesProvidersMap[i]?.selectedProvider
              ?.moduleProviderId || "";
          if (tempProvider != undefined) {
            for (let j = i + 1; j < filteredModulesProvidersMap.length; j++) {
              let tempProviderAhead =
                filteredModulesProvidersMap[j]?.selectedProvider
                  ?.moduleProviderId || "";
              if (tempProviderAhead != undefined) {
                if (tempProvider.toString() == tempProviderAhead.toString()) {
                  filteredModulesProvidersMap[j].selectedProvider = {};
                }
              }
            }
          }
        }
      }
      setFilterFlag(true);
    }
  }, [filterFlag]);

  useEffect(() => {
    if (credentialsActive == "active") {
      document.getElementsByClassName("credentials-copy")[0].style.display =
        "block";
    } else {
      document.getElementsByClassName("credentials-copy")[0].style.display =
        "none";
    }
  }, [credentialsActive]);

  useEffect(() => {
    if (mvaDetailsActive == "active") {
      document.getElementsByClassName("mva-copy")[0].style.display = "block";
    } else {
      document.getElementsByClassName("mva-copy")[0].style.display = "none";
    }
  }, [mvaDetailsActive]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        window.location.assign("/dashboard/manage-merchant/onboard-merchant");
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    if (
      document.getElementsByClassName("company-details-content")[0] != null &&
      document.getElementsByClassName("company-details-content")[0] != undefined
    ) {
      setHeight(
        `${
          document.getElementsByClassName("company-details-content")[0]
            .scrollHeight
        }px`
      );
      setModuleProviderHeight(
        `${
          document.getElementsByClassName("modules-content")[0].scrollHeight
        }px`
      );
      setPlanDetailsHeight(
        `${document.getElementsByClassName("plans-content")[0].scrollHeight}px`
      );
      setMvaDetailsHeight(
        `${document.getElementsByClassName("mva-content")[0].scrollHeight}px`
      );
      setCredentialsHeight(
        `${
          document.getElementsByClassName("credentials-content")[0].scrollHeight
        }px`
      );
    }
  }, []);

  function toggleAccordion() {
    setCompanyDetailsActive(companyDetailsActive === "" ? "active" : "");
    setHeight(
      companyDetailsActive === "active"
        ? "0px"
        : `${content.current.scrollHeight}px`
    );
  }

  function toggleModuleDetailsAccordion() {
    setModuleProvidersActive(moduleProvidersActive === "" ? "active" : "");
    setModuleProviderHeight(
      moduleProvidersActive === "active"
        ? "0px"
        : `${moduleContent.current.scrollHeight}px`
    );
  }

  function togglePlanDetailsAccordion() {
    setPlanDetailsActive(planDetailsActive === "" ? "active" : "");
    setPlanDetailsHeight(
      planDetailsActive === "active"
        ? "0px"
        : `${planDetailsContent.current.scrollHeight}px`
    );
  }

  function toggleCredentialsDetailsAccordion() {
    setCredentialsActive(credentialsActive === "" ? "active" : "");
    setCredentialsHeight(
      credentialsActive === "active"
        ? "0px"
        : `${credentialsContent.current.scrollHeight}px`
    );
  }

  function toggleMvaDetailsAccordion() {
    setMvaDetailsActive(mvaDetailsActive === "" ? "active" : "");
    setMvaDetailsHeight(
      mvaDetailsActive === "active"
        ? "0px"
        : `${mvaDetailsContent.current.scrollHeight}px`
    );
  }

  const [filteredCompanyDetails, setFilteredCompanyDetails] = React.useState({
    "Company Name": props.companyDetails.fullName,
    Email: props.companyDetails.emailId,
    "Mobile Number": props.companyDetails.mobileNumber
      ? props.companyDetails.mobileNumber
      : "NA",
    Website: props.companyDetails.website ? props.companyDetails.website : "NA",
    "Company ID": props.companyDetails.companyId,
  });

  const [filteredModulesData, setFilteredModulesData] = React.useState({});

  // & to update beneficiary account number
  const beneficiaryAccountNumberDetail =
    document.getElementById("customerHiddenData").dataset
      .beneficiaryAccountNumberDummyMoney;

  function copyClip(event, dataReferenceClass) {
    event.stopPropagation();
    const elTable = document.querySelector("." + dataReferenceClass);

    let range, sel;

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();
    ReactDOM.render(
      <SnackbarMessage msgtype="Success" msg="Copied to Clipboard" />,
      document.getElementById("snackbar")
    );
  }

  // ^ Mapping as per Bd requirement for display name and order
  const [mappingFlag, setMappingFlag] = useState(false);

  useEffect(() => {
    if (!mappingFlag && Object.keys(filteredModulesProvidersMap).length !== 0) {
      filteredModulesProvidersMap.forEach((moduleData) => {
        moduleMapping.forEach((moduleInputData) => {
          if (
            moduleData.moduleCode.toString() === moduleInputData.code.toString()
          ) {
            moduleData.defaultName = moduleInputData[moduleData.moduleCode];
          }
        });
      });
      setMappingFlag(!mappingFlag);
    }
  }, [mappingFlag]);

  // ^ Mapping as per module provider
  const [mappingModuleFlag, setMappingModuleFlag] = useState(false);

  useEffect(() => {
    if (
      !mappingModuleFlag &&
      Object.keys(props.selectedModuleData).length !== 0
    ) {
      props.selectedModuleData.forEach((moduleData) => {
        moduleMapping.forEach((moduleInputData) => {
          if (moduleData.code.toString() === moduleInputData.code.toString()) {
            moduleData.defaultName = moduleInputData[moduleData.code];
          }
        });
      });
      setMappingModuleFlag(!mappingModuleFlag);
    }
  }, [mappingModuleFlag]);

  // ^ Mapping as per plan mapping
  const [mappingPlanFlag, setMappingPlanFlag] = useState(false);

  useEffect(() => {
    if (
      !mappingPlanFlag &&
      Object.keys(props.companyAPIMeterData).length !== 0
    ) {
      props.companyAPIMeterData.forEach((moduleData) => {
        moduleMapping.forEach((moduleInputData) => {
          if (
            moduleData.moduleCode.toString() === moduleInputData.code.toString()
          ) {
            moduleData.defaultName = moduleInputData[moduleData.moduleCode];
          }
        });
      });
      setMappingPlanFlag(!mappingPlanFlag);
    }
  }, [mappingPlanFlag]);

  return (
    <React.Fragment>
      <div className="customer-summary-container">
        {success ? (
          <div className="onboarding-success-container">
            <div className="onboarding-success-contents">
              <img src="/images/success-tick.svg" alt="success-icon" />
              <p>Onboarding Successful!</p>
              <p>Now, proceed to plan mapping and complete the setup.</p>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div className="top-header-action-bar">
              <h2>Review your company details</h2>
              <div
                className="onboard-copy-button"
                onClick={(event) => {
                  copyClip(event, "universal-hidden-table");
                }}
              >
                <img src="/images/copy-btn.svg" alt="copy icon" />
              </div>
            </div>
            <div className="customer-summary-content" id="summaryContent">
              <div className="customer-basic-details">
                <div className="accordion-section">
                  <div
                    className={`accordion ${companyDetailsActive}`}
                    onClick={() => toggleAccordion()}
                  >
                    <h2 className="accordion-title">Company Details</h2>
                    <img
                      className={`expand-btn ${companyDetailsActive}`}
                      src="/images/expand.svg"
                      alt="expand accordion arrow"
                    />
                  </div>
                  <div
                    ref={content}
                    className="company-details-content accordion-content"
                    style={{ maxHeight: `${height}` }}
                  >
                    <div className="row">
                      <div className="label">Company Name:</div>
                      <div className="label-value">
                        {props.companyDetails.fullName}
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Email:</div>
                      <div className="label-value">
                        {props.companyDetails.emailId}
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Mobile Number:</div>
                      <div className="label-value">
                        {props.companyDetails.mobileNumber
                          ? props.companyDetails.mobileNumber
                          : "NA"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">Website:</div>
                      <div className="label-value">
                        {props.companyDetails.website
                          ? props.companyDetails.website
                          : "NA"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-module-details">
                <div className="accordion-section">
                  <div
                    className={`accordion ${moduleProvidersActive}`}
                    onClick={() => toggleModuleDetailsAccordion()}
                  >
                    <h2 className="accordion-title">Module Provider</h2>
                    <img
                      className={`expand-btn ${moduleProvidersActive}`}
                      src="/images/expand.svg"
                      alt="expand accordion arrow"
                    />
                  </div>
                  <div
                    ref={moduleContent}
                    className="modules-content accordion-content"
                    style={{ maxHeight: `${moduleProviderHeight}` }}
                  >
                    {props.selectedModuleData.map((item, index) => {
                      return (
                        <div className="row">
                          <div className="label">{item.defaultName}</div>
                          <div className="label-value">{item.secret}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="customer-plan-details">
                <div className="accordion-section">
                  <div
                    className={`accordion ${planDetailsActive}`}
                    onClick={() => togglePlanDetailsAccordion()}
                  >
                    <h2 className="accordion-title">Plan Mapping</h2>
                    <img
                      className={`expand-btn ${planDetailsActive}`}
                      src="/images/expand.svg"
                      alt="expand accordion arrow"
                    />
                  </div>
                  <div
                    ref={planDetailsContent}
                    className="plans-content accordion-content"
                    style={{ maxHeight: `${planDetailsHeight}` }}
                  >
                    {
                      <div className="row">
                        <div className="label">Module Code</div>
                        <div className="label">Threshold Percentage</div>
                        <div className="label">Tax Rate</div>
                        <div className="label">Max Usable Credits</div>
                        <div className="label">Plan Period</div>
                      </div>
                    }
                    {props.companyAPIMeterData.map((item, index) => {
                      return (
                        <div className="row">
                          <div className="label">{item.defaultName}</div>
                          <div className="label-value">
                            {item.renewalThresholdPercentage}
                          </div>
                          <div className="label-value">
                            {item.plan.item.taxRate}
                          </div>
                          <div className="label-value">
                            {item.maxUsableCredits}
                          </div>
                          <div className="label-value">{item.plan.period}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="customer-credentials-details">
                <div className="accordion-section">
                  <div
                    className={`accordion ${credentialsActive}`}
                    onClick={() => toggleCredentialsDetailsAccordion()}
                  >
                    <h2 className="accordion-title">Credentials</h2>
                    <div
                      className="onboard-copy-button credentials-copy"
                      onClick={(event) => {
                        copyClip(event, "credentials-table-data");
                      }}
                    >
                      <img src="/images/copy-btn.svg" alt="copy icon" />
                    </div>
                    <img
                      className={`expand-btn ${credentialsActive}`}
                      src="/images/expand.svg"
                      alt="expand accordion arrow"
                    />
                  </div>
                  <div
                    ref={credentialsContent}
                    className="credentials-content accordion-content"
                    style={{ maxHeight: `${credentialsHeight}` }}
                  >
                    <table className="credentials-table-data">
                      <tr className="row">
                        <td className="label">Client ID</td>
                        <td className="label-value">
                          {props.companySecretInputData.client_id}
                        </td>
                      </tr>
                      <tr className="row">
                        <td className="label">Client Secret</td>
                        <td className="label-value">
                          {props.companySecretInputData.client_secret}
                        </td>
                      </tr>
                      {filteredModulesProvidersMap.length > 0
                        ? filteredModulesProvidersMap.map((item, index) => {
                            return (
                              <>
                                <tr className="row">
                                  <td className="label">
                                    {`${item?.code} Module Secret`}{" "}
                                  </td>
                                  <td className="label-value">
                                    {item?.moduleSecret || "-"}
                                  </td>
                                </tr>
                                {item.selectedProvider
                                  ? Object.keys(item.selectedProvider).length >
                                      0 && (
                                      <tr className="row">
                                        <td className="label">{`${item.selectedProvider.moduleProvider} Provider Secret`}</td>
                                        <td className="label-value">
                                          {item?.selectedProvider
                                            ?.providerSecret || "-"}
                                        </td>
                                      </tr>
                                    )
                                  : null}
                              </>
                            );
                          })
                        : null}
                      {/* {props.selectedModuleData.map((item, index) => {
                        return (
                          <tr className="row">
                            <td className="label">{item.code}</td>
                            <td className="label-value">{item.secret}</td>
                          </tr>
                        );
                      })}
                      {props.successSecretDetails.map((item, index) => {
                        return (
                          <tr className="row">
                            <td className="label">{item.moduleProvider}</td>
                            <td className="label-value">
                              {item.providerSecret}
                            </td>
                          </tr>
                        );
                      })} */}
                    </table>
                    {/* <div className="row">
                      <div className="label">client_id</div>
                      <div className="label-value">
                        {props.companySecretInputData.client_id}
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">client_secret</div>
                      <div className="label-value">
                        {props.companySecretInputData.client_secret}
                      </div>
                    </div>
                    {props.selectedModuleData.map((item, index) => {
                      return (
                        <div className="row">
                          <div className="label">{item.code}</div>
                          <div className="label-value">{item.secret}</div>
                        </div>
                      );
                    })}
                    {props.successSecretDetails.map((item, index) => {
                      return (
                        <div className="row">
                          <div className="label">{item.moduleProvider}</div>
                          <div className="label-value">
                            {item.providerSecret}
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                </div>
              </div>
              {/* Hidden Universal Copy Table */}
              <div className="universal-hidden-table" id="universalHiddenTable">
                <table className="credentials-table-data">
                  <tr className="row">
                    <td className="label">Particulars</td>
                    <td className="label-value">Credentials</td>
                  </tr>
                  <tr className="row">
                    <td className="label">Documentation URL</td>
                    <td className="label-value">
                      <a href="https://docs.decentro.tech/">
                        https://docs.decentro.tech/
                      </a>
                    </td>
                  </tr>
                  <tr className="row">
                    <td className="label">Client ID</td>
                    <td className="label-value">
                      {props.companySecretInputData.client_id}
                    </td>
                  </tr>
                  <tr className="row">
                    <td className="label">Client Secret</td>
                    <td className="label-value">
                      {props.companySecretInputData.client_secret}
                    </td>
                  </tr>
                  {filteredModulesProvidersMap.length > 0 &&
                    filteredModulesProvidersMap.map((item, index) => {
                      return (
                        <>
                          <tr className="row">
                            <td className="label">
                              {`${item?.code} Module Secret`}{" "}
                            </td>
                            <td className="label-value">
                              {item?.moduleSecret || "-"}
                            </td>
                          </tr>
                          {item.selectedProvider
                            ? Object.keys(item?.selectedProvider).length >
                                0 && (
                                <tr className="row">
                                  <td className="label">{`${item.selectedProvider.moduleProvider} Provider Secret`}</td>
                                  <td className="label-value">
                                    {item?.selectedProvider?.providerSecret ||
                                      "-"}
                                  </td>
                                </tr>
                              )
                            : null}
                        </>
                      );
                    })}
                  {/* Business account number removed */}

                  {/* <tr className="row">
                    <td className="label">Business Account Number:</td>
                    <td className="label-value">
                      {props?.mvaResponseDetails?.mva_account_number}
                    </td>
                  </tr> */}
                  <tr className="row">
                    <td className="label">Commission Debit Account Number:</td>
                    <td className="label-value">
                      {
                        props?.mvaResponseDetails?.data
                          ?.commissionDebitAccountNumber
                      }
                    </td>
                  </tr>
                  <tr className="row">
                    <td className="label">Commission Credit Account Number:</td>
                    <td className="label-value">
                      {
                        props?.mvaResponseDetails?.data
                          ?.commissionCreditAccountNumber
                      }
                    </td>
                  </tr>
                  <tr className="row">
                    <td className="label">Treasure Account Number:</td>
                    <td className="label-value">
                      {beneficiaryAccountNumberDetail}
                    </td>
                  </tr>
                </table>
              </div>
              {/*MVA Details */}
              <div className="mva-basic-details">
                <div className="accordion-section">
                  <div
                    className={`accordion ${mvaDetailsActive}`}
                    onClick={() => toggleMvaDetailsAccordion()}
                  >
                    <h2 className="accordion-title">MVA Details</h2>
                    <div
                      className="onboard-copy-button mva-copy"
                      onClick={(event) => {
                        copyClip(event, "mva-table-data");
                      }}
                    >
                      <img src="/images/copy-btn.svg" alt="copy icon" />
                    </div>
                    <img
                      className={`expand-btn ${mvaDetailsActive}`}
                      src="/images/expand.svg"
                      alt="expand accordion arrow"
                    />
                  </div>
                  <div
                    ref={mvaDetailsContent}
                    className="mva-content accordion-content"
                    style={{ maxHeight: `${mvaDetailsHeight}` }}
                  >
                    {Object.keys(props.mvaResponseDetails).length > 0 ? (
                      <p className="accordion-info success-info">
                        MVA Configured and Dummy Money added successfully!
                      </p>
                    ) : (
                      <p className="accordion-info error-info">
                        MVA Configuration failure. Please try again!
                      </p>
                    )}
                    <table className="mva-table-data">
                      {/* <tr className="row">
                        <td className="label">Business Account Number:</td>
                        <td className="label-value">
                          {props?.mvaResponseDetails?.mva_account_number}
                        </td>
                      </tr> */}
                      <tr className="row">
                        <td className="label">
                          Commission Debit Account Number
                        </td>
                        <td className="label-value">
                          {
                            props?.mvaResponseDetails?.data
                              ?.commissionDebitAccountNumber
                          }
                        </td>
                      </tr>
                      <tr className="row">
                        <td className="label">
                          Commission Credit Account Number
                        </td>
                        <td className="label-value">
                          {
                            props?.mvaResponseDetails?.data
                              ?.commissionCreditAccountNumber
                          }
                        </td>
                      </tr>
                      <tr className="row">
                        <td className="label">Treasure Account Number</td>
                        <td className="label-value">
                          {beneficiaryAccountNumberDetail}
                        </td>
                      </tr>
                    </table>

                    {/* <div className="row">
                      <div className="label">Business Account Number</div>
                      <div className="label-value">
                        {props?.mvaResponseDetails?.mva_account_number}
                      </div>
                    </div>
                    <div className="row">
                      <div className="label">
                        Commission Debit Account Number
                      </div>
                      <div className="label-value">
                        {
                          props?.mvaResponseDetails?.data
                            ?.commissionDebitAccountNumber
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="label">
                        Commission Credit Account Number
                      </div>
                      <div className="label-value">
                        {
                          props?.mvaResponseDetails?.data
                            ?.commissionCreditAccountNumber
                        }
                      </div>
                    </div>

                    <div className="row">
                      <div className="label">Treasure Account Number</div>
                      <div className="label-value">
                        {beneficiaryAccountNumberDetail}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="company-details-submit button-container"
              id="submitBtn"
            >
              <button
                className={`company-details-submit active`}
                onClick={() => {
                  setSuccess(true);
                }}
              >
                Done
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}

export default CustomerDetailsSummary;
