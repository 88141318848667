import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// component imports
import ModulesList from "../ModulesList/ModulesList";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
// context imports
import CreditManagementContext from "../../../contexts/CreditManagementContext";
import CreditsActionContext from "../../../contexts/CreditsActionContext";
// styles imports
import "./AvailableModulesList.scss";
import { Modal } from "../EditCredits/Modal/Modal";
import { randomUUID } from "../../../services/randomUUID";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

const AvailableModulesList = () => {
  // ^ context
  const { allCredits, tableEditData } = useContext(CreditsActionContext);
  const {
    editCreditsApi,
    showModal,
    openModal,
    // resetCreditDropdown,
    // editType,
  } = useContext(CreditManagementContext);

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const [subscribedModules, setSubscribedModules] = useState("");
  //  to disable submit button
  const [isDisabled, setIsDisabled] = useState(true);
  //  Payload data for otp verification
  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });
  // QR for OTP
  const [qrBase, setQrBase] = useState("");
  // For TOTP modal and QR Modal Toggle
  const [showTotp, setShowTotp] = useState(false);

  // Loading
  const [isLoading, setIsLoading] = useState(false);

  const backToAllCredits = () => {
    allCredits();
  };

  useEffect(() => {
    let editDetail;
    if (!!subscribedModules) {
      editDetail = subscribedModules.filter((item) => {
        return (
          !!item.module_code &&
          !!item.max_usable_credits &&
          item.creditInputError === false
        );
      });

      if (editDetail.length > 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [subscribedModules]);

  const submitEditCreditDetails = () => {
    const editDetail = subscribedModules.filter((item) => {
      return (
        !!item.module_code &&
        !!item.max_usable_credits &&
        item.creditInputError === false
      );
    });

    if (editDetail.length === 0) {
      ReactDOM.render(
        <SnackbarMessage msgtype="Error" msg={"No Changes Found !"} />,
        document.getElementById("snackbar")
      );
    } else {
      let filteredData;

      filteredData = editDetail.map((detail) => {
        return {
          module_code: detail.module_code,
          max_usable_credits:
            detail.operationFunction === "add"
              ? detail.maxUsableCredits + +detail.max_usable_credits
              : detail.maxUsableCredits - +detail.max_usable_credits,
        };
      });

      // if (editType.function === "add") {
      //   filteredData = editDetail.map((detail) => {
      //     return {
      //       module_code: detail.module_code,
      //       max_usable_credits:
      //         detail.maxUsableCredits + +detail.max_usable_credits,
      //     };
      //   });
      // }

      // if (editType.function === "subtract") {
      //   filteredData = editDetail.map((detail) => {
      //     return {
      //       module_code: detail.module_code,
      //       max_usable_credits:
      //         detail.maxUsableCredits - +detail.max_usable_credits,
      //     };
      //   });
      // }

      const payload = {
        company_id: tableEditData.id,
        api_meter_details: filteredData,
      };

      editCreditsApi(payload);
      resetEditCreditsOnSubmit();
      // resetCreditDropdown();
    }
  };

  const resetEditCreditsOnSubmit = () => {
    setSubscribedModules(
      subscribedModules.map((data) => {
        return { ...data, module_code: "", max_usable_credits: "" };
      })
    );
  };

  const updateHandler = () => {
    const companyInput = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };

    // * Generating OTP
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      companyInput
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          //  Setting data to verify OTP
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: companyInput.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        // Open OTP Modal
        showModal();
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  return (
    <div className="available-modules-container">
      <div className="available-modules-header">
        <div onClick={backToAllCredits} className="header-container">
          <img src="/images/back.svg" alt="back-icon" />
          <h1 className="heading">
            Account Details{" "}
            <span className="company-name-highlight">
              {tableEditData.companyName}
            </span>{" "}
          </h1>
        </div>
        <div className="update-btn-container">
          {/* <button
            onClick={submitEditCreditDetails}
            className={`manage-credit-btn ${!isDisabled && "active-credit-btn"}`}
            type="button"
            disabled={isDisabled}
          >
            Apply Changes
          </button> */}
          <button
            onClick={updateHandler}
            className={`manage-credit-btn ${
              !isDisabled && !isLoading && "active-credit-btn"
            }`}
            type="button"
            disabled={isDisabled}
          >
            {isLoading ? "Loading..." : "Update"}
          </button>
        </div>
      </div>
      <div className="subscribed-module-list-container">
        <div className="credits-management-divider"></div>
        <ModulesList
          subscribedModules={subscribedModules}
          setSubscribedModules={setSubscribedModules}
        />
      </div>
      {openModal ? (
        <Modal
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          submitEditCreditDetails={submitEditCreditDetails}
        />
      ) : null}
    </div>
  );
};

export default AvailableModulesList;
