import React from "react";
import { useContext } from "react";
//component
import AddNewUser from "../UserManagement/AddNewUser/AddNewUser";
import EditNewUser from "../UserManagement/EditNewUser/EditNewUser";
//context
import UserActionContext from "../../contexts/UserActionContext";
//styles import
import "./AddUser.scss";

function AddUser({ children }) {
  const { tableEditRow, userSummary } = useContext(UserActionContext);
  const backToSummary = () => {
    userSummary();
  };

  return (
    <>
      <div className="add-user-page">
        <div className="add-user-content">
          <div className="add-user-card">
            {tableEditRow.edit && (
              <div className="back-btn" onClick={backToSummary}>
                <img src="/images/back.svg" alt="back-icon" />
                <h1 className="heading">
                  <span className="company-name-highlight">
                    Edit User Details
                  </span>
                </h1>
              </div>
            )}
            {!tableEditRow.edit && (
              <div className="back-btn" onClick={backToSummary}>
                <img src="/images/back.svg" alt="back-icon" />
                <p className="heading">Add New User</p>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
