import React from "react";
import Select from "react-select";

//component-import
import ToolTip from "../ToolTip/ToolTip";

//styles-import
import "./TextField.scss";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #cbcbcb",
    borderRadius: "10px",
    padding: "5px",
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
  }),

  option: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),

  valueContainer: (provided) => ({
    ...provided,
    maxHeight: "84px",
    overflowY: "auto", 
    fontSize: "14px",
  }),
};

const formatOptionLabel = ({ label, value }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <span style={{ wordWrap: "break-word", marginRight: "10px" }}>{label}</span>
    <span style={{ float: "right" }}>{value}</span>
  </div>
);

const TextFieldSelect = ({
  id,
  name,
  onChange,
  onBlur,
  isLoading,
  value,
  options,
  placeholder,
  label,
  noOptionsMessage,
  required,
  isToolTip,
  isformatOptionLabel,
  isMulti = false,
  isClearable = true,
  isError = false,
  disabled = false,
}) => {

  const handleChange = (selectedOptions) => {
    if (isMulti && selectedOptions) {
      selectedOptions = [selectedOptions.pop(), ...selectedOptions];
    }
    onChange(selectedOptions);
  };

  // Define a function for noOptionsMessage to handle empty options
  const handleNoOptionsMessage = () => {
    if (isError) {
      return "API failed to fetch"; // Handle API failure message
    }
    if (!options || options.length === 0) {
      return "No options available"; // Handle empty options array
    }
    return "No options found"; // Handle when search query yields no result
  };

  return (
    <div className={`ui-form-input-section ui-form-content-input${isMulti ? "-multi-select" : ""}`}>
      <Select
        id={id}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={!isError ? placeholder : "API failed to fetch"}
        value={value}
        options={options}
        styles={customStyles}
        noOptionsMessage={handleNoOptionsMessage}
        isSearchable
        isClearable={isClearable}
        isLoading={!isError && isLoading}
        isDisabled={isError || isLoading}
        formatOptionLabel={isformatOptionLabel ? formatOptionLabel : null}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        disabled={disabled}
      />
      <label className="ui-label" htmlFor={id}>
        {label}{" "}
        <ToolTip
          text={isToolTip}
          requiredField={required}
          isToolTip={isToolTip}
        >
          {isToolTip && (
            <div className="ui-info-icon">
              <img src="/images/info-icon.svg" alt="" />
            </div>
          )}
        </ToolTip>
      </label>
    </div>
  );
};

export default TextFieldSelect;
