import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import modules from "../../config/Setup/modules.json";
import { modulesContext } from "../../contexts/modulesContext";
import "./Tabs.scss";

const Tabs = () => {
  const location = useLocation();
  const [latestPath, setLatestPath] = useState("");
  const [modulesData, setModulesData] = useState(modules.modules);
  const [tabActive, setTabActive] = useState("0");
  const { currentModuleData, setCurrentModuleData } =
    useContext(modulesContext);
  const toggleTab = (index) => {
    setTabActive(index);
    localStorage.setItem("currentActiveTabIndex", index);
  };

  useEffect(() => {
    setLatestPath(location.pathname);
    if (localStorage.getItem("currentActiveTabIndex") === null) {
      localStorage.setItem("currentActiveTabIndex", "0");
      localStorage.setItem(
        "currentSubmenu",
        JSON.stringify({
          module: "manage-merchant",
          display_name: "Manage Merchant",
          path: "/dashboard/manage-merchant/onboard-merchant",
          submenus: [
            {
              module: "onboard-merchant",
              display_name: "Onboard Merchant",
              path: "/dashboard/manage-merchant/onboard-merchant",
              submenus: [],
              icon_path: "/images/onboard-customer-icon.svg",
            },
            {
              module: "all-merchants",
              display_name: "All Merchants",
              path: "/dashboard/manage-merchant/all-merchants",
              submenus: [],
              icon_path: "/images/all-customers-icon.svg",
            },
            {
              module: "update-merchant",
              display_name: "Update Merchant",
              path: "/dashboard/manage-merchant/update-merchant",
              submenus: [],
              icon_path: "/images/manage-customer-icon.svg",
            },
            {
              module: "callback-configuration",
              display_name: "Callback Configuration",
              path: "/dashboard/manage-merchant/callback-configuration",
              submenus: [],
              icon_path: "/images/callback-configuration-icon.svg",
            },
            // {
            //   module: "provider-callbacks",
            //   display_name: "Provider Callbacks",
            //   path: "/dashboard/callback_configuration/provider-callbacks",
            //   submenus: [],
            //   icon_path: "/images/provider-callback-active.svg",
            // },
            {
              module: "credits-management",
              display_name: "Credits Management",
              path: "/dashboard/manage-merchant/credits-management",
              submenus: [],
              icon_path: "/images/credits-management-icon.svg",
            },
            {
              module: "configure-mva",
              display_name: "Configure MVA",
              path: "/dashboard/manage-merchant/configure-mva",
              submenus: [],
              icon_path: "/images/configure-mva-icon.svg",
            },
            {
              module: "user-management",
              display_name: "User Management",
              path: "/dashboard/manage-merchant/user-management",
              submenus: [],
              icon_path: "/images/user-management-icon.svg",
            },
            // {
            //   module: "master-search",
            //   display_name: "Master Search",
            //   path: "/dashboard/manage-merchant/master-search",
            //   submenus: [],
            //   icon_path: "/images/master-search-icon.svg",
            // },
            {
              module: "credits-consumption",
              display_name: "Credits Consumption",
              path: "/dashboard/manage-merchant/credits-consumption",
              submenus: [],
              icon_path: "/images/credits-consumption-icon.svg",
            },
            {
              module: "sid-configuration",
              display_name: "SID Configuration",
              path: "/dashboard/manage-merchant/sid-configuration",
              submenus: [],
              icon_path: "/images/sid_config.svg",
            },
            {
              module: "manage-ips",
              display_name: "Manage IPs",
              path: "/dashboard/manage-merchant/manage-ips",
              submenus: [],
              icon_path: "/images/ipwhitelist-active.svg",
            },
            {
              module: "validate-bank-account",
              display_name: "Validate Bank Account",
              path: "/dashboard/manage-merchant/validate-bank-account",
              submenus: [],
              icon_path: "/images/validate-bank-account-icon.svg",
            },
          ],
        })
      );
    } else {
      toggleTab(localStorage.getItem("currentActiveTabIndex"));
      updateSubmenus(JSON.parse(localStorage.getItem("currentSubmenu")));
    }
  }, []);

  useEffect(() => {
    setLatestPath(location.pathname);
  }, [tabActive]);

  const updateSubmenus = (item) => {
    setCurrentModuleData(item);
    localStorage.setItem("currentSubmenu", JSON.stringify(item));
  };

  return (
    <div className="menu-tabs">
      {modulesData.map((item, index) => {
        return (
          <div
            key={index}
            className={tabActive == index ? "tab active" : "tab"}
          >
            <NavLink
              className={tabActive == index ? "tab-link active" : "tab-link"}
              to={item.path}
              data-path={item.path}
              data-reference={item.module}
              data-item={JSON.stringify(item)}
              onClick={() => {
                toggleTab(index);
                updateSubmenus(item);
              }}
            >
              {item.display_name}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
