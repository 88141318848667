import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

//component-import
import ListChargebackDataTable from "../ListChargeback/ListChargebackDataTable/ListChargebackDataTable";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import ComponentWrapper from "../../../UI/ComponentWrapper/ComponentWrapper";
import BulkAddChargeback from "../BulkAddChargeback/BulkAddChargeback";
import BulkUpdateChargeback from "../BulkUpdateChargeback/BulkUpdateChargeback";
import CopyButton from "../../../UI/CopyButton/CopyButton";

//API-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

//styles-import
import "./ListChargeback.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ListChargeback = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [chargebackTypeOptions, setChargebackTypeOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);

  const BulkRecordHeaders = useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.id ? row.row.original.id : "-"}
              </div>
            ),
          },
          {
            Header: "Chargeback URN",
            accessor: "chargeback_urn",
            sort: true,
            Cell: (row) => (
              <div  style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {row.row.original.chargeback_urn !== null ? (
                <>
                  {row.row.original.chargeback_urn}
                  <div style={{ marginTop: "5px" }}>
                    <CopyButton text={row.row.original.chargeback_urn} />
                  </div>
                </>
              ) : (
                "-"
              )}
              </div>
            ),
          },
          {
            Header: "Company Name",
            accessor: "company_common_name",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.company_common_name !== null
                  ? row.row.original.company_common_name
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Chargeback Type",
            accessor: "chargeback_type",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.chargeback_type !== null
                  ? row.row.original.chargeback_type
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "chargeback_status",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.chargeback_status !== null
                  ? row.row.original.chargeback_status
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Chargeback UTR",
            accessor: "chargeback_utr",
            sort: true,
            Cell: (row) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {row.row.original.chargeback_utr !== null ? (
                  <>
                    {row.row.original.chargeback_utr}
                    <div style={{ marginTop: "5px" }}>
                      <CopyButton text={row.row.original.chargeback_utr} />
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </div>
            ),
          },
          {
            Header: "Resolution Last Date",
            accessor: "last_date_for_resolution",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.last_date_for_resolution
                  ? row.row.original.last_date_for_resolution
                  : "NaT"}
              </div>
            ),
          },
          {
            Header: "Created On",
            accessor: "created_on",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.created_on
                  ? row.row.original.created_on
                  : "NaT"}
              </div>
            ),
          },
          {
            Header: "Decentro Company URN",
            accessor: "decentro_company_urn",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.decentro_company_urn !== null
                  ? row.row.original.decentro_company_urn
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Received On",
            accessor: "received_on",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.received_on
                  ? row.row.original.received_on
                  : "NaT"}
              </div>
            ),
          },
          {
            Header: "Raised On",
            accessor: "raised_on",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.raised_on
                  ? row.row.original.raised_on
                  : "NaT"}
              </div>
            ),
          },
          {
            Header: "Action Taken On",
            accessor: "action_taken_on",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.action_taken_on
                  ? row.row.original.action_taken_on
                  : "NaT"}
              </div>
            ),
          },
          {
            Header: "Auto Approved",
            accessor: "is_auto_approved",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.is_auto_approved ? "True" : "False"}
              </div>
            ),
          },
          {
            Header: "Transaction Log URN",
            accessor: "action_decentro_company_transaction_log_urn",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original
                  .action_decentro_company_transaction_log_urn !== null
                  ? row.row.original.action_decentro_company_transaction_log_urn
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Initiated Asset URN",
            accessor: "initiated_asset_urn",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.initiated_asset_urn !== null
                  ? row.row.original.initiated_asset_urn
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Action Asset URN",
            accessor: "action_asset_urn",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.action_asset_urn !== null
                  ? row.row.original.action_asset_urn
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Comments",
            accessor: "comments",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.comments !== null
                  ? row.row.original.comments
                  : "-"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  const getChargebackType = () => {
    APIConfig.API_Client.post(
      apiEndpointList.CHARGEBACK_TYPE.baseUrl +
        apiEndpointList.CHARGEBACK_TYPE.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((type) => ({
          value: type.id,
          label: type.name,
        }));
        setChargebackTypeOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load Chargeback Type Options"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getChargebackType();
  }, []);

  return (
    <React.Fragment>
      <div className="list-chargeback-page" id="chargeback">
        <div className="list-chargeback-content">
          <div className="list-chargeback">
            <h1 className="heading">Chargeback Records</h1>
            <div className="chargeback-options">
              <button
                className={"bulk-add-chargeback-btn"}
                onClick={() => {
                  ReactDOM.render(
                    <ComponentWrapper component={<BulkAddChargeback />} />,
                    document.getElementById("chargeback")
                  );
                }}
              >
                Bulk Add Chargeback
              </button>
              {/* <button
            className={"bulk-update-chargeback-btn"}
            onClick={() => {
              ReactDOM.render(
                <ComponentWrapper
                component={<BulkUpdateChargeback />}
              />,
              document.getElementById("chargeback")
              )
            }}
          >
            Bulk Update Chargeback
          </button> */}
            </div>
          </div>
          <div className="list-chargeback-card">
            <ListChargebackDataTable
              columns={BulkRecordHeaders}
              companyOptions={companyOptions}
              chargebackTypeOptions={chargebackTypeOptions}
              isOptionsLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListChargeback;
