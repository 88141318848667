import React from "react";
import ReactDOM from "react-dom";

// component imports
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// style imports
import "./IPManagementSuccessDialog.scss";

function IPManagementSuccessDialog(props) {
  const closeSuccessDialog = () => {
    ReactDOM.unmountComponentAtNode(
      document.getElementById("add-customer-components")
    );
  };

  function copyExecutionId() {
    const executionId = props.data.execution_id;
    navigator.clipboard.writeText(executionId).then(
      () => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={"Execution ID copied to clipboard"}
          />,
          document.getElementById("snackbar")
        );
      },
      (err) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to copy Execution ID"}
          />,
          document.getElementById("snackbar")
        );
      }
    );
  }

  return (
    <React.Fragment>
      <Dialog
        className="customer-config-success"
        PaperProps={{ style: { borderRadius: 10 } }}
        open={true}
        aria-labelledby="form-dialog-title"
      >
        <div className="close-success-popup">
          <CloseIcon onClick={closeSuccessDialog} />
        </div>

        <DialogContent>
          <div className="dialog-content-container">
            <div className="success-message">
              <img src="/images/onsuccess-tick.svg" alt="success-icon" />
              <h3>IPs successfully updated!</h3>
            </div>
            <div className="success-account-details">
              <table className="configure-ip-data">
                <tbody>
                  <tr>
                    <td className="detail-heading">Message</td>
                    <td className="detail-value title-case">
                      {props.data.message}
                    </td>
                  </tr>
                  <tr>
                    <td className="detail-heading">Execution ID</td>
                    <td className="detail-value title-case">
                      {props.data.execution_id}{" "}
                      <button
                        className="copy-execution-id-details"
                        onClick={copyExecutionId}
                        type="button"
                      >
                        <img src="/images/copy-icon.svg" alt="copy-icon" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="success-btn-container">
              <button onClick={closeSuccessDialog} className="success-btn-done">
                Done
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default IPManagementSuccessDialog;
