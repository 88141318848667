import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import subMonths from "date-fns/subMonths";

export const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const dateValues = {
  dateFormat: "dd MMM yy",
  dateBefore: "2020-01-0",
  dateEnd: 30,
};
export const Locale = {
  sunday: "Sun",
  monday: "Mon",
  tuesday: "Tue",
  wednesday: "Wed",
  thursday: "Thu",
  friday: "Fri",
  saturday: "Sat",
  ok: "Apply",
  formattedMonthPattern: "MMM yyyy",
};
export const Ranges = [
  {
    label: "7 Days",
    value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
  },
  {
    label: "1 Month",
    value: [startOfDay(subMonths(new Date(), 1)), endOfDay(new Date())],
  },
  {
    label: "3 Months",
    value: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  },
];
