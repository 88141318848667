import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// api config imports
import axios, { all } from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// component imports
import ModulesList from "../ModulesList/ModulesList";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
// context imports
import CustomerManagementActionContext from "../../../contexts/CustomerManagementActionContext";
// styles imports
import "./AvailableModulesList.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const AvailableModulesList = () => {
  // ^ context
  const { allCustomers, tableEditData } = useContext(
    CustomerManagementActionContext
  );
  // State to store all company details
  const [allCompanyData, setAllCompanyData] = useState([]);
  // State for Subscribed Modules
  const [subscribedModules, setSubscribedModules] = useState("");

  const backToallCustomers = () => {
    allCustomers();
  };

  useEffect(() => {
    getAllCompanyData();
  }, []);

  const getAllCompanyData = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_COMPANY_DETAILS.baseUrl +
        apiEndpointList.FETCH_COMPANY_DETAILS.endpoint,
      {
        company_id: parseInt(tableEditData.id),
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.length === 0) {
          ReactDOM.render(
            <SnackbarMessage msgtype="error" msg={"No Data Found"} />,
            document.getElementById("snackbar")
          );
        } else {
          setAllCompanyData(response.data);
        }
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="error" msg={"Something went wrong!"} />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <div className="available-modules-container">
      <div className="available-modules-header">
        <div onClick={backToallCustomers} className="header-container">
          <img src="/images/back.svg" alt="back-icon" />
          <h1 className="heading">
            Update Details for{" "}
            <span className="company-name-highlight">
              {tableEditData && tableEditData.companyName}
            </span>{" "}
          </h1>
        </div>
      </div>
      <div className="subscribed-module-list-container">
        <div className="customer-management-divider"></div>
        {allCompanyData.length > 0 ? (
          <ModulesList
            subscribedModules={subscribedModules}
            setSubscribedModules={setSubscribedModules}
            allCompanyData={allCompanyData}
          />
        ) : (
          <div>No Data found</div>
        )}
      </div>
    </div>
  );
};

export default AvailableModulesList;
