import React from "react";

//component-import
import SIDConfigure from "./SIDConfigure/SIDConfigure";
import ComponentWrapper from "../../UI/ComponentWrapper/ComponentWrapper";

const SIDConfiguration = () => {

  return (
    <React.Fragment>
    <ComponentWrapper
      heading="SID Configuration"
      component={<SIDConfigure />}
    />
  </React.Fragment>
  );
};

export default SIDConfiguration;
