import React from "react";

//styles-import
import "./ComponentWrapper.scss";

const ComponentWrapper = ({ heading, component }) => {
  return (
    <React.Fragment>
      <div className="component-page">
        <div className="component-content">
          <div className="component">
            <h1 className="heading">{heading}</h1>
          </div>
          <div className="component-form">{component}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComponentWrapper;
