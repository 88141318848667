import React, { useState, useContext, useEffect } from "react";
import modules from "../../config/Setup/modules.json";
import { NavLink } from "react-router-dom";
import { modulesContext } from "../../contexts/modulesContext";
import "./SubmenuBar.scss";

const SubmenuBar = () => {
  const { currentModuleData } = useContext(modulesContext);
  const [submenuCollapse, setSubmenuCollapse] = useState(false);
  return (
    <div
      className={
        submenuCollapse
          ? "submenu-wrapper collapsed-submenu"
          : "submenu-wrapper extended-submenu"
      }
    >
      <div className="submenu-bar">
        <div>
          <ul className="nav-primary">
            {currentModuleData != undefined &&
              currentModuleData != null &&
              currentModuleData.submenus != undefined &&
              currentModuleData.submenus.map((item, index) => {
                return (
                  <li
                    key={index}
                    // onMouseOver={() => {
                    //   if (submenuCollapse == true) setSubmenuCollapse(false);
                    // }}
                    // onMouseLeave={() => {
                    //   if (submenuCollapse == false) setSubmenuCollapse(true);
                    // }}
                    // onClick={() => {
                    //   setSubmenuCollapse(true);
                    // }}
                  >
                    <NavLink to={item.path} activeClassName="active">
                      <img className="module-icon" src={item.icon_path} />
                      {item.display_name}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SubmenuBar;
