import React from "react";

//component-import
import ComponentWrapper from "../../../UI/ComponentWrapper/ComponentWrapper";
import PauseSettlementInternal from "./PauseSettlementInternal/PauseSettlementInternal";
// import FeatureUnavailable from "../../../UI/FeatureUnavailable/FeatureUnavailable";

const PauseSettlement = () => {
  // if (process.env.REACT_APP_ENV_NAME !== "qa") {
  //   return (
  //     <React.Fragment>
  //       <ComponentWrapper
  //         heading="Pause Settlement"
  //         component={<FeatureUnavailable />}
  //       />
  //     </React.Fragment>
  //   );
  // }

  return (
    <React.Fragment>
      <ComponentWrapper
        heading="Pause Settlement"
        component={<PauseSettlementInternal />}
      />
    </React.Fragment>
  );
};

export default PauseSettlement;
