import React, { useState, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";
import DownloadButton from "../../../../UI/DownloadButton/DownloadButton";

//API-imports
import { APIConfigFormData } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import { randomUUID } from "../../../../services/randomUUID";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//REGEX-import
import { REGEXP } from "../../../../utilities/validators/inputValidators";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  file: null,
  purpose: "",
};

const { alphanumericWithWhitespaceRegex } = REGEXP;
const validationSchema = Yup.object({
  file: Yup.mixed()
    .required("File is required")
    .test(
      "fileType",
      "Only CSV files are allowed",
      (value) => value && value.type === "text/csv"
    ),
  purpose: Yup.string()
    .min(5)
    .max(50)
    .matches(alphanumericWithWhitespaceRegex)
    .required("Purpose message is required"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BulkUnloadAccount = () => {
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const file = document.getElementById("file").files[0];
      const formData = new FormData();
      formData.set("file", file);
      formData.append("reference_id", randomUUID());
      formData.append("purpose", values.purpose);
      formData.append("action", "bulk_unload");

      action.setSubmitting(true);
      APIConfigFormData.API_Client.post(
        apiEndpointList.BULK_ACTION.baseUrl +
          apiEndpointList.BULK_ACTION.endpoint,
        formData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          const fileInput = document.getElementById("file");
          if (fileInput) {
            fileInput.value = "";
          }
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("file", file);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-header">
            <h2 className="ui-form-title">Fill details to continue</h2>
            <DownloadButton
              fileSrc="/files/unload_account.csv"
              downloadedFilename="UnloadAccount.csv"
              text="Sample File"
            />
          </div>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose"
                name="purpose"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose}
                touched={formik.touched.purpose}
                error={formik.errors.purpose}
                placeholder="Enter Purpose Message"
                label="Purpose Message"
                required={true}
                disabled={false}
                maxLength={50}
                isToolTip={true ? "Min 5 characters" : null}
              />
            </div>
            <div className="ui-form-inputs-section">
              <div className={`ui-form-input-section ui-form-content-input`}>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".csv"
                  onChange={handleFileUpload}
                  required={true}
                />
                <label htmlFor="file" className="ui-file-label">
                  {"Upload CSV File"} <span className="required-field">*</span>
                </label>
                {formik.values.file &&
                formik.values.file.type !== "text/csv" ? (
                  <img
                    src="/images/invalid-input.svg"
                    className="ui-invalid-file-type"
                    alt="invalid-input"
                  />
                ) : null}
              </div>
            </div>
            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default BulkUnloadAccount;
