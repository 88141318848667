import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

//component-import
import BulkProcessingDataTable from "../BulkProcessing/BulkProcessingDataTable/BulkProcessingDataTable";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import CopyButton from "../../../UI/CopyButton/CopyButton";

//API-imports
import axios from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";

//styles-import
import "./BulkProcessing.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const BulkProcessing = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [processingTypeOptions, setProcessingTypeOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);

  const BulkRecordHeaders = useMemo(
    () => [
      {
        Header: "_",
        hideHeader: false,
        columns: [
          {
            Header: "Decentro Company URN",
            accessor: "decentro_company_urn",
            sort: true,
            Cell: (row) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {row.row.original.decentro_company_urn}
                <div style={{ marginTop: "5px" }}>
                  <CopyButton text={row.row.original.decentro_company_urn} />
                </div>
              </div>
            ),
          },
          {
            Header: "Company Name",
            accessor: "company_common_name",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.company_common_name !== null
                  ? row.row.original.company_common_name
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Type",
            accessor: "type",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.type !== null ? row.row.original.type : "-"}
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "status",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.status !== null
                  ? row.row.original.status
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Total Count",
            accessor: "total_count",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.total_count !== null
                  ? row.row.original.total_count
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Processed Count",
            accessor: "processed_count",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.processed_count !== null
                  ? row.row.original.processed_count
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Success Count",
            accessor: "success_count",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.success_count !== null
                  ? row.row.original.success_count
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Failure Count",
            accessor: "failure_count",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.failure_count !== null
                  ? row.row.original.failure_count
                  : "-"}
              </div>
            ),
          },
          {
            Header: "Created On",
            accessor: "created_on",
            sort: true,
            Cell: (row) => (
              <div style={{ textAlign: "center" }}>
                {row.row.original.created_on
                  ? row.row.original.created_on
                  : "NaT"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );


  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  const getProcessingType = () => {
    APIConfig.API_Client.post(
      apiEndpointList.BULK_PROCESSING_TYPE.baseUrl +
        apiEndpointList.BULK_PROCESSING_TYPE.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((type) => ({
          value: type.id,
          label: type.name,
        }));
        setProcessingTypeOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to load Processing Type Options"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getProcessingType();
  }, []);

  return (
    <React.Fragment>
      <div className="bulk-processing-page">
        <div className="bulk_processing-content">
          <div className="bulk-processing">
            <h1 className="heading">Bulk Process Records</h1>
          </div>
          <div className="bulk-processing-card">
            <BulkProcessingDataTable
              columns={BulkRecordHeaders}
              companyOptions={companyOptions}
              processingTypeOptions={processingTypeOptions}
              isOptionsLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BulkProcessing;
