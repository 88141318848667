import React, { useContext } from "react";
//context import
import UserActionContext from "../../contexts/UserActionContext";
//component for edit and udpate
import AddUser from "../AddUser/AddUser";
import UserManagementDataTable from "../UserManagementDataTable/UserManagementDataTable";
// style imports
import "./UserManagement.scss";
import AddNewUser from "./AddNewUser/AddNewUser";
import EditNewUser from "./EditNewUser/EditNewUser";

function UserManagement() {
  const { cardType, tableEditRow, userSummary } = useContext(UserActionContext);
  const backToSummary = () => {
    userSummary();
  };
  const USER_MANAGEMENT_CARD = {
    addUser: <AddNewUser />,
    editUser: <EditNewUser />,
    userSummary: <UserManagementSummaryCard />,
  };

  return (
    <div>
      {cardType === "userSummary" ? (
        <div>{USER_MANAGEMENT_CARD[cardType]}</div>
      ) : (
        <AddUser>
          <div>{USER_MANAGEMENT_CARD[cardType]}</div>
        </AddUser>
      )}
    </div>
  );
}
const UserManagementSummaryCard = () => {
  const { addUser } = useContext(UserActionContext);
  const addNewUser = () => {
    // editUser();
    addUser();
  };
  return (
    <div className="user-management-page">
      <div className="user-management-content">
        <div className="user-management">
          <h1 className="heading">User Management</h1>
          <button
            onClick={addNewUser}
            className={`configure-user-btn`}
            type="button"
          >
            <span>+</span> Add User
          </button>
        </div>
        <div className="user-management-card">
          <UserManagementDataTable />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
