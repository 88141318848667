import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
// Axios Imports
import axios from "axios";
// Components
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import ResponseDetails from "./ResponseDetails/ResponseDetails";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";
import TextFieldInput from "../../UI/TextField/TextFieldInput";
// Formik and Yup Imports
import { useFormik } from "formik";
import * as Yup from "yup";
// Utils
import apiEndpointList from "../../config/modules/customer_management/endpoint";
import { REGEXP } from "../../utilities/validators/inputValidators";
import { APIConfig } from "../../services/apiConfiguration";
import { scrollIntoView } from "../../utilities/scrollIntoView";
import { keepDesiredProperties } from "../../utilities/keepDesiredProperties";

const ValidateAccountForm = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const responseRef = useRef(null);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const validations = ["Penniless", "Pennydrop", "Hybrid"];
  const validationTypes = validations.map((value) => ({
    value: value.toLowerCase(),
    label: value,
  }));

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  //   Calling provider options API
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
      apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {
        "api_common_name": "CORE_BANKING_MONEY_TRANSFER_VALIDATE_BANK_ACCOUNT"
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setIsLoading(false);
        const options = response.data.map((value) => ({
          label: value.bank_name,
          value: value.bank_code,
        }));
        setProviderApiData(options);
      })
      .catch((error) => {
        setIsLoading(false);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong while fetching Providers!"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  //   Fetching CVA Providers
  useEffect(() => {
    cvaProvider()
  }, []);

  const initialValues = {
    purpose_message: "",
    reference_id: "",
    bank_provider: "",
    validation_type: "",
    beneficiary_account_number: "",
    beneficiary_ifsc: "",
  };

  const { messageRegex, referenceIdRegex, accountNumberRegex, ifscRegex } =
    REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    bank_provider: Yup.mixed().required("Bank Provider is required"),
    validation_type: Yup.mixed().when("bank_provider", {
      is: (bank_provider) => bank_provider?.value === "idfb",
      then: () => Yup.mixed().required("Validation Type is required"),
      otherwise: () => Yup.mixed().notRequired(),
    }),
    purpose_message: Yup.string()
      .matches(messageRegex)
      .required("It's a required field"),
    reference_id: Yup.string().required().matches(referenceIdRegex),
    beneficiary_account_number: Yup.string()
      .matches(accountNumberRegex)
      .required("Account Number is required"),
    beneficiary_ifsc: Yup.string()
      .matches(ifscRegex)
      .required("IFSC is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: values.reference_id,
        purpose_message: values.purpose_message,
        validation_type: values.validation_type.value,
        beneficiary_details: {
          account_number: values.beneficiary_account_number,
          ifsc: values.beneficiary_ifsc,
        },
      };
      //   const providerCode = providerApiData.label;
      const providerCode = formik?.values?.bank_provider?.value;
      validateAccountAPI(payloadData, providerCode, action);
    },
  });

  // Validate Account API call
  const validateAccountAPI = (payload, provider_code, action) => {
    action.setSubmitting(true);

    APIConfig.API_Client.post(
      apiEndpointList.VALIDATE_BANK_ACCOUNT.baseUrl +
      apiEndpointList.VALIDATE_BANK_ACCOUNT.endpoint,
      payload,
      { headers: { provider_code } },
      { cancelToken: source.token }
    )
      .then((response) => {
        action.resetForm();
        const RenderProps = ["status", "decentroTxnId", "accountStatus", "beneficiaryName", "bankReferenceNumber"]
        const renderData = keepDesiredProperties(response.data, RenderProps)
        setResponseDetails(renderData);
        setShowResponseDetails(true);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.error?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      })
      .finally(() => {
        action.setSubmitting(false);
        if (responseRef.current) {
          scrollIntoView(responseRef.current, { behavior: "smooth" });
        }
      });
  };


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                onChange={(selectedOption) => {
                  formik.setFieldValue("bank_provider", selectedOption);
                }}
                onBlur={() => formik.setFieldTouched("bank_provider", true)}
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Select Provider"
                required={true}
                placeholder="Bank provider"
                isformatOptionLabel={false}
                isLoading={isLoading}
              />
              <TextFieldInput
                id="reference_id"
                name="reference_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reference_id}
                touched={formik.touched.reference_id}
                error={formik.errors.reference_id}
                placeholder="Enter reference ID"
                label="Reference ID"
                required={true}
                disabled={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="purpose_message"
                name="purpose_message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.purpose_message}
                touched={formik.touched.purpose_message}
                error={formik.errors.purpose_message}
                placeholder="Enter message"
                label="Message"
                required={true}
                disabled={false}
                isToolTip={"The purpose of this request"}
              />
              <TextFieldInput
                id="beneficiary_account_number"
                name="beneficiary_account_number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_account_number}
                touched={formik.touched.beneficiary_account_number}
                error={formik.errors.beneficiary_account_number}
                placeholder="Beneficiary account number"
                label="Beneficiary Account Number"
                required={true}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="beneficiary_ifsc"
                name="beneficiary_ifsc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.beneficiary_ifsc}
                touched={formik.touched.beneficiary_ifsc}
                error={formik.errors.beneficiary_ifsc}
                placeholder="Enter IFSC"
                label="Beneficiary IFSC"
                required={true}
              />
              {formik?.values?.bank_provider?.value === "idfb" && (
                <TextFieldSelect
                  id="validation_type"
                  name="validation_type"
                  onChange={(selectedOption) => {
                    formik.setFieldValue("validation_type", selectedOption);
                  }}
                  onBlur={() => formik.setFieldTouched("validation_type", true)}
                  value={formik.values.validation_type}
                  options={validationTypes}
                  noOptionsMessage={() => "No Validation Type Exists"}
                  label="Validation Type"
                  required={true}
                  placeholder="Select validation type"
                  isformatOptionLabel={false}
                  isToolTip={"Either Penniless, Pennydrop, or Hybrid"}
                />
              )}
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                // className="submit-btn active"
                className={`submit-btn ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : ""
                  }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default ValidateAccountForm;
