import React from "react";

//component-import
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";
import CompanyInvoke from "./SettlementBatchJob/CompanyInvoke";
import SettlementBatchJob from "./SettlementBatchJob/SettlementBatchJob";

const InvokeSettlement = () => {

  return (
    <React.Fragment>
      <TabComponentWrapper
        heading="Invoke Settlement"
        tabs={[
          {
            className: "company-invoke",
            label: "Company",
            value: "company",
            component: <CompanyInvoke />,
          },
          {
            className: "settlement-cycle",
            label: "Settlement Cycle",
            value: "settlement cycle",
            component: <SettlementBatchJob />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default InvokeSettlement;
