import React from "react";
import ConfigureMvaDetails from "../ConfigureMvaDetails/ConfigureMvaDetails";
// styles imports
import "./ConfigureMvaCard.scss";
const ConfigureMvaCard = () => {
  return (
    <div className="configure-mva-page">
      <div className="configure-mva-content">
        <div className="configure-mva">
          <h1 className="heading">Configure MVA</h1>
        </div>
        <div className="configure-mva-card">
          <ConfigureMvaDetails />
        </div>
      </div>
    </div>
  );
};

export default ConfigureMvaCard;
