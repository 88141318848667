import React from "react";

//styles-import
import "./ResponseDetails.scss";
// utils
import transformToTitleCase from "../../../utilities/transformToTitleCase";
import CopyButton from "../../../UI/CopyButton/CopyButton";


const ResponseDetails = ({ data, onClick }) => {
  const imgSrc =
    data?.status === "success" || data?.status === "SUCCESS"
      ? "/images/onsuccess-tick.svg"
      : "/images/onfailure-error.svg"
  const renderDataEntries = Object.entries(data || {});

  return (
    <>
      <div className="response-details-container">
        <h4 className="response-details-heading">
          <img
            src={imgSrc}
            className={`${data?.status}-status-icon`}
            alt="status-icon"
          />
          Validate Account Response Details
          <img
            src="/images/close.svg"
            className="close-response-details"
            alt="close"
            onClick={onClick}
          />
        </h4>
        <div className="response-details-divider"></div>
        <div className="response-details">
          <ul className="response-list">
            {renderDataEntries.map(([key, value]) => (
              <li key={key} className="response-list-item">
                <span className="field">{transformToTitleCase(key)}: </span>
                <span className="value">{value !== null ? value : "-"}</span>
                <CopyButton text={value || "-"} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ResponseDetails;
