import React, { useState, useEffect, useRef } from "react";
import { randomUUID } from "../../../../services/randomUUID.js";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldSelect from "../../../../UI/TextField/TextFieldSelect";
import ResponseDetails from "../../../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView.js";

const initialValues = {
  company_common_name: "",
};

const validationSchema = Yup.object({
  company_common_name: Yup.object().shape({
    value: Yup.number().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }),
  company_remitter_whitelisting: Yup.string()
    .test(
      "is boolean",
      "Value can be either 0 or 1",
      (value) => value === "0" || value === "1"
    )
    .required(),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const CompanyRemitterWhitelist = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        reference_id: randomUUID(),
        company_id: values.company_common_name.value,
        whitelisting: values.company_remitter_whitelisting === "1",
      };

      action.setSubmitting(true);
      APIConfig.API_Client.put(
        apiEndpointList.COMPANY_REMITTER_WHITELIST.baseUrl +
          apiEndpointList.COMPANY_REMITTER_WHITELIST.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          ...item,
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Fill account details to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="company_common_name"
                name="company_common_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_common_name", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_common_name", true)
                }
                value={formik.values.company_common_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company Common Name"
                required={true}
                isLoading={isLoading}
                placeholder="Select Common Name"
                isformatOptionLabel={true}
              />
              <div className={`ui-form-input-section ui-form-content-input`}>
                <label>
                  <input
                    type="radio"
                    id="enable_company_remitter_whitelisting_yes"
                    name="company_remitter_whitelisting"
                    value="1"
                    onChange={formik.handleChange}
                    checked={
                      formik.values.company_remitter_whitelisting === "1"
                    }
                  />
                  Yes{" "}
                </label>
                <label>
                  <input
                    type="radio"
                    id="disable_company_remitter_whitelisting_yes"
                    name="company_remitter_whitelisting"
                    value="0"
                    onChange={formik.handleChange}
                    checked={
                      formik.values.company_remitter_whitelisting === "0"
                    }
                  />
                  No{" "}
                </label>
                <label
                  htmlFor="company_remitter_whitelisting"
                  className="ui-label"
                >
                  {"Enable ?"} <span className="required-field">*</span>
                </label>
              </div>
            </div>
            <div className="divider"></div>

            <div className="account-details-submit ui-button-container">
              <button
                className={`account-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default CompanyRemitterWhitelist;
