import React, { useState } from "react";
// styles imports
import "./Tooltip.scss";

const Tooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(!active);
    }, props.delay || 10);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="shared-tooltip-wrapper"
      onMouseLeave={hideTip}
      onClick={showTip}
    >
      {props.children}
      {active && (
        <div className={`shared-tooltip-tip ${props.direction || "top"}`}>
          {/* @ {props.content} */}
          <strong>Example :</strong>
          <br />
          {JSON.stringify({
            ContentType: "application/json",
            abc: "xxxxxxx",
          })}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
