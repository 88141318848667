import React, { useState, useEffect, useRef } from "react";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";
import { randomUUID } from "../../services/randomUUID";

//API-imports
import axios from "axios";
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/modules/customer_management/endpoint";

//component-import
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import ResponseDetails from "../../UI/ResponseDetails/ResponseDetails";

//styles-import
import "../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../utilities/scrollIntoView";

const initialValues = {
  company_common_name: [],
  settlement_cycle_urn: "",
  action: "",
};

const validationSchema = Yup.object({
  company_common_name: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.number().required("Please select an option"),
        label: Yup.string().required("Please select an option"),
      })
    )
    .min(1, "At least one element is required"),
  settlement_cycle_urn: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }).required("Please select an option"),
  action: Yup.object().shape({
    value: Yup.string().required("Please select an option"),
    label: Yup.string().required("Please select an option"),
  }).required("Please select an option"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SettlementForm = () => {
  const [companyOptions, setCompanyOptions] = useState([""]);
  const [settlementUrnOptions, setSettlementUrnOptions] = useState([""]);
  const [isLoading, setIsLoading] = useState(true);
  const [isURNLoading, setIsURNLoading] = useState(true);
  const [responseDetails, setResponseDetails] = useState({});
  const [showResponseDetails, setShowResponseDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowResponseDetails(false);
    setResponseDetails({});
  };

  let actionDropDownValues = [
    {
      value: "ADD",
      label: "ADD",
    },
    {
      value: "REMOVE",
      label: "REMOVE",
    },
  ];

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      let payloadData;

      const extractCompanyIDtValues = (arr) => {
        return arr.map((item) => item.value);
      };

      if (values.action.label === "ADD") {
        payloadData = {
          settlement_cycle_urn: values.settlement_cycle_urn.value,
          reference_id: randomUUID(),
          add_companies: extractCompanyIDtValues(values.company_common_name),
        };
      }

      if (values.action.label === "REMOVE") {
        payloadData = {
          settlement_cycle_urn: values.settlement_cycle_urn.value,
          reference_id: randomUUID(),
          remove_companies: extractCompanyIDtValues(values.company_common_name),
        };
      }

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.SETTLEMENT_CYCLE.baseUrl +
          apiEndpointList.SETTLEMENT_CYCLE.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          console.log(
            "🚀 🚀 🚀 ~ file: SettlementForm.js:109 ~ .then ~ response:",
            response.data
          );
          setResponseDetails(response.data);
          setShowResponseDetails(true);
          action.resetForm();
        })
        .catch((error) => {
          setResponseDetails(error.response.data);
          setShowResponseDetails(true);
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  const getCompanyOptions = () => {
    setIsLoading(true);
    APIConfig.API_Client.get(
      apiEndpointList.GET_ALL_COMPANY_DETAIL.baseUrl +
        apiEndpointList.GET_ALL_COMPANY_DETAIL.endpoint,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.data.map((item) => ({
          value: item.companyId,
          label: item.commonName,
        }));

        setCompanyOptions(options);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setCompanyOptions([]);
        setIsLoading(false);
      });
  };

  const getSettlementCycleUrn = () => {
    setIsURNLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.SETTLEMENT_CYCLE_URN.baseUrl +
        apiEndpointList.SETTLEMENT_CYCLE_URN.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.settlement_cycle_urn,
          label: item.name,
        }));

        setSettlementUrnOptions(options);
        setIsURNLoading(false);
      })
      .catch((error) => {
        console.error("error while fetching companies lists :", error);
        setSettlementUrnOptions([]);
        setIsURNLoading(false);
      });
  };

  useEffect(() => {
    getCompanyOptions();
    getSettlementCycleUrn();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Fill transaction details to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              {/* urn options */}
              <TextFieldSelect
                id="settlement_cycle_urn"
                name="settlement_cycle_urn"
                onChange={(selectedOption) =>
                  formik.setFieldValue("settlement_cycle_urn", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("settlement_cycle_urn", true)
                }
                value={formik.values.settlement_cycle_urn}
                options={settlementUrnOptions}
                noOptionsMessage={() => "No such URN exists"}
                label="Settlement Cycle"
                required={true}
                isLoading={isURNLoading}
                placeholder="Select settlement cycle"
                isformatOptionLabel={false}
              />

              {/* action field */}
              <TextFieldSelect
                id="action"
                name="action"
                onChange={(selectedOption) =>
                  formik.setFieldValue("action", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("action", true)}
                value={formik.values.action}
                options={actionDropDownValues}
                noOptionsMessage={() => "No such action exists"}
                label="Action"
                required={true}
                placeholder="Select action"
                isformatOptionLabel={false}
                isClearable={false}
              />
            </div>

            <div className="ui-form-inputs-section">
              {/* company options */}
              <TextFieldSelect
                id="company_common_name"
                name="company_common_name"
                onChange={(selectedOption) =>
                  formik.setFieldValue("company_common_name", selectedOption)
                }
                onBlur={() =>
                  formik.setFieldTouched("company_common_name", true)
                }
                value={formik.values.company_common_name}
                options={companyOptions}
                noOptionsMessage={() => "No such company exists"}
                label="Company"
                required={true}
                isLoading={isLoading}
                placeholder="Select company"
                isformatOptionLabel={true}
                isMulti={true}
              />
            </div>

            <div className="transaction-details-submit ui-button-container">
              <button
                className={`transaction-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showResponseDetails && (
        <div ref={responseRef}>
          <ResponseDetails data={responseDetails} onClick={handleClick} />
        </div>
      )}
    </>
  );
};

export default SettlementForm;
