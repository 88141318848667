import React, { createContext, useState } from "react";

const CustomerManagementActionContext = createContext();

export const CustomerManagementProvider = ({ children }) => {
  // type of card state
  const [cardType, setCardType] = useState("allCustomers");
  // edit callback data state
  const [tableEditData, setTableEditData] = useState({
    id: "",
    edit: false,
    companyName: "",
  });

  // jumps to add new callback form card
  const editCustomerData = () => {
    setCardType("editCustomerData");
  };

  // jumps to add all callback table card
  const allCustomers = () => {
    setCardType("allCustomers");
    setTableEditData({ id: "", edit: false, companyName: "" });
  };

  return (
    <CustomerManagementActionContext.Provider
      value={{
        cardType,
        tableEditData,
        setTableEditData,
        editCustomerData,
        allCustomers,
      }}
    >
      {children}
    </CustomerManagementActionContext.Provider>
  );
};

export default CustomerManagementActionContext;
