import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

//styles-import
// import { matchSorter } from "match-sorter";
import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker, DatePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useFilters,
} from "react-table";
import "./ListChargebackDataTable.scss";

// assets imports
import sort from "../../../../assets/sort-icon.svg";
import sortUp from "../../../../assets/sort-up-icon.svg";
import sortDown from "../../../../assets/sort-down-icon.svg";

//components-import
import TableLoader from "../../../Shared/TableLoader/TableLoader";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import Pagination from "@material-ui/lab/Pagination";
import Select from "react-select";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";
import HandleOutsideClick from "../../../../UI/HandleOutsideClick/HandleOutsideClick";

const { afterToday } = DateRangePicker;

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #cbcbcb",
    borderRadius: "18px",
    "&:hover": {
      borderColor: "#0092ff",
      cursor: "pointer",
    },
  }),

  placeholder: (base) => ({
    ...base,
    fontSize: "13px",
  }),

  option: (provided) => ({
    ...provided,
    fontSize: "14px"
  }),

  valueContainer: (provided) => ({
    ...provided,
    maxHeight: "58px",
    overflowY: "auto", 
  }),
};

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// data table  component
function Table({
  columns,
  companyOptions,
  chargebackTypeOptions,
  isOptionsLoading,
}) {
  //table data states
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //pagination states
  const limit = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [paged, setPaged] = React.useState(1);

  //filter select states
  const [selectedCompanyNames, setSelectedCompanyNames] = React.useState([]);
  const [selectedChargebackType, setSelectedChargebackType] = React.useState([]);
  const [selectedDateRange, setSelectedDateRange] = React.useState([]);
  const [selectedResolutionDate, setSelectedResolutionDate] = React.useState("");

  //filter value states
  const [chargebackUTRFilterValue, setChargebackUTRFilterValue] = useState([]);
  const [companyFilterValue, setCompanyFilterValue] = useState([]);
  const [typeFilterValue, setTypeFilterValue] = useState([]);
  const [startDateFilterValue, setStartDateFilterValue] = useState([]);
  const [endDateFilterValue, setEndDateFilterValue] = useState([]);
  const [resolutionDateFilterValue, setResolutionDateFilterValue] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [chargebackURNFilterValue, setChargebackURNFilterValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentSearchScope, setCurrentSearchScope] = useState(false);
  const [searchReadOnly, setSearchReadOnly] = useState(true);

  //manage column states
  const [toggleColumn, setToggleColumn] = useState(false);

  const getRecordsCount = (filters = {}) => {
    const {
      chargeback_utr,
      company_list,
      chargeback_type_list,
      start_date,
      end_date,
      last_date_for_resolution,
      chargeback_urn,
    } = filters;

    const payload = {}

    if(chargeback_utr && chargeback_utr.length) {
      payload.chargeback_utr = chargeback_utr
    }
    if (company_list && company_list.length) {
      payload.company_list = company_list;
    }
    if (chargeback_type_list && chargeback_type_list.length) {
      payload.chargeback_type_list = chargeback_type_list;
    }
    if (start_date && start_date.length) {
      payload.start_date = start_date;
    }
    if (end_date && end_date.length) {
      payload.end_date = end_date;
    }
    if(last_date_for_resolution && last_date_for_resolution.length) {
      payload.last_date_for_resolution = last_date_for_resolution
    }
    if(chargeback_urn && chargeback_urn.length) {
      payload.chargeback_urn = chargeback_urn
    }

    APIConfig.API_Client.post(
      apiEndpointList.CHARGEBACK_COUNT.baseUrl +
        apiEndpointList.CHARGEBACK_COUNT.endpoint,
      payload,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const count = response.data[0]["count(*)"];
        const pageCount = Math.ceil((count * 1.0) / limit);
        setTotalPages(pageCount);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to fetch chargeback records count"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  let bulkData = [];

  const chargebackData = (page = 1, filters = {}) => {
    const offset = (page - 1) * limit;
    const {
      chargeback_utr,
      company_list,
      chargeback_type_list,
      start_date,
      end_date,
      last_date_for_resolution,
      chargeback_urn,
    } = filters;

    const payload = {
      limit,
      offset
    }

    if(chargeback_utr && chargeback_utr.length) {
      payload.chargeback_utr = chargeback_utr
    }
    if (company_list && company_list.length) {
      payload.company_list = company_list;
    }
    if (chargeback_type_list && chargeback_type_list.length) {
      payload.chargeback_type_list = chargeback_type_list;
    }
    if (start_date && start_date.length) {
      payload.start_date = start_date;
    }
    if (end_date && end_date.length) {
      payload.end_date = end_date;
    }
    if(last_date_for_resolution && last_date_for_resolution.length) {
      payload.last_date_for_resolution = last_date_for_resolution
    }
    if(chargeback_urn && chargeback_urn.length) {
      payload.chargeback_urn = chargeback_urn
    }

    APIConfig.API_Client.post(
      apiEndpointList.CHARGEBACK_DATA.baseUrl +
        apiEndpointList.CHARGEBACK_DATA.endpoint,
      payload,
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        let row = [];
        bulkData = [];
        for (let i = 0; i < response.data.length; i++) {
          row = response.data[i];
          bulkData.push(row);
        }
        setData(bulkData);
        setIsLoading(false);
      })
      .catch((error) => {
        setData([]);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={"Failed to fetch chargeback data"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  useEffect(() => {
    getRecordsCount();
    chargebackData();
  }, []);

  // useTable Props for actions
  const props = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: [
          "id",
          "decentro_company_urn",
          "received_on",
          "raised_on",
          "action_taken_on",
          "is_auto_approved",
          "action_decentro_company_transaction_log_urn",
          "initiated_asset_urn",
          "action_asset_urn",
          "comments",
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // props destructuring
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    allColumns,
  } = props;

  // pagination UI styles
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));
  const classes = useStyles();

  const filters = {
    chargeback_utr: chargebackUTRFilterValue,
    company_list: companyFilterValue,
    chargeback_type_list: typeFilterValue,
    start_date: startDateFilterValue,
    end_date: endDateFilterValue,
    last_date_for_resolution: resolutionDateFilterValue,
    chargeback_urn: chargebackURNFilterValue,
  };

  // pagination logic
  const handleChange = (event, value) => {
    setPaged(value);
    getRecordsCount(filters);
    chargebackData(value, filters);
    gotoPage(value - 1);
  };

  //no match found message
  let noDataMessage;
  if (page.length === 0) {
    noDataMessage = (
      <div className="no-data-message">
        <p>No matching data found</p>
      </div>
    );
  }

  const findISODate = (date) => {
    let ISODate = new Date(date);
    const offset = ISODate.getTimezoneOffset();
    ISODate = new Date(ISODate.getTime() - offset * 60 * 1000);
    return ISODate;
  };

  const handleDateChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    let endDate = dateRange != null ? new Date(dateRange[1]) : [];
    endDate =
      dateRange != null ? new Date(endDate.getTime() + 1 * 86400000) : [];

    const startDate =
      dateRange != null
        ? findISODate(dateRange[0]).toISOString().split("T")[0]
        : [];
    endDate =
      dateRange != null ? findISODate(endDate).toISOString().split("T")[0] : [];
    setIsFilterApplied(true);
    filters.start_date = startDate;
    filters.end_date = endDate;
    getRecordsCount(filters);
    chargebackData(1, filters);
    setPaged(1);
    setStartDateFilterValue(startDate);
    setEndDateFilterValue(endDate);
  };

  const handleResolutionDateChange = (date) => {
    setSelectedResolutionDate(date);
    const resolutionDate =
      date != null ? findISODate(date).toISOString().split("T")[0] : [];
    setIsFilterApplied(true);
    filters.last_date_for_resolution = resolutionDate;
    getRecordsCount(filters);
    chargebackData(1, filters);
    setPaged(1);
    setResolutionDateFilterValue(resolutionDate);
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    setIsFilterApplied(true);
    const val = e.target.value.length > 0 ? e.target.value : [];
    if(currentSearchScope === "Chargeback UTR")
    {
      filters.chargeback_utr = val;
      setChargebackUTRFilterValue(val);
    }

    if(currentSearchScope === "Chargeback URN")
    {
      filters.chargeback_urn = val;
      setChargebackURNFilterValue(val);
    }
    getRecordsCount(filters);
    chargebackData(1, filters);
    setPaged(1);
  };

  const ToggleSearchDropdown = () => {
    document
      .querySelector(".advanced-search-option")
      .classList.toggle("active-cv");
    document
      .querySelector(".search-filter-wrapper")
      .classList.toggle("show-border-cv");
  };

  return (
    <>
      {!isLoading || isFilterApplied ? (
        <div className="chargeback-data-table-container">
          <div className="chargeback-table-header">
            <div className="chargeback-data-table-filter">
                <div className="search-container">
                  <div className="search-filter-wrapper">
                    <div className="advanced-search-container">
                      <input
                        type="search"
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        placeholder={`Search ${currentSearchScope || ""}`}
                        readOnly={searchReadOnly}
                        className={
                          currentSearchScope === false ? "cursor-pointer" : ""
                        }
                        onClick={
                          currentSearchScope === false ? ToggleSearchDropdown : null
                        }
                      />
                      {searchValue.length === 0 && (<img
                        className="downward-icon"
                        src="/images/arrow-downward.svg"
                        alt="Arrow Down"
                        onClick={ToggleSearchDropdown}
                      />) }
                    </div>
                    <div className="advanced-search-option">
                      <div className="options">
                        <div>
                          <div
                            onClick={() => {
                              document
                                .querySelector(".advanced-search-option")
                                .classList.remove("active-cv");
                              setCurrentSearchScope("Chargeback URN");
                              document
                                .querySelector(".search-filter-wrapper")
                                .classList.toggle("show-border-cv");
                              setSearchReadOnly(false);
                            }}
                            className="option-cv"
                          >
                            Chargeback URN
                          </div>
                        </div>
                        <div>
                          <div
                            onClick={() => {
                              document
                                .querySelector(".advanced-search-option")
                                .classList.remove("active-cv");
                              setCurrentSearchScope("Chargeback UTR");
                              document
                                .querySelector(".search-filter-wrapper")
                                .classList.toggle("show-border-cv");
                              setSearchReadOnly(false);
                            }}
                            className="option-cv"
                          >
                            Chargeback UTR
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <DateRangePicker
                appearance="default"
                placeholder="Select Date Range"
                value={selectedDateRange}
                onChange={handleDateChange}
                className="date-range-picker"
                shouldDisableDate={afterToday()}
              />
              <DatePicker
                appearance="default"
                placeholder="Select Resolution Date"
                value={selectedResolutionDate}
                onChange={handleResolutionDateChange}
                className="date-picker"
              />
              <HandleOutsideClick onClickOutside={() => setToggleColumn(false)}>
                <div className="column-wrapper">
                  <button
                    className="column-button"
                    onClick={() => setToggleColumn(!toggleColumn)}
                  >
                    {/* Manage Column */}
                    Columns
                    <span>
                      <img
                        src="/images/arrow-downward.svg"
                        alt="Arrow Down"
                        className="downward-icon"
                      />
                    </span>
                  </button>
                  <div
                    onMouseLeave={() => setToggleColumn(!toggleColumn)}
                    className={toggleColumn ? "block" : "hidden"}
                  >
                    <ul className="headers-list">
                      {allColumns.map((column) => {
                        return (
                          <li key={column.id}>
                            <label htmlFor={column.id}>
                              <span>{column.Header}</span>
                              <input
                                type="checkbox"
                                {...column.getToggleHiddenProps()}
                              />
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </HandleOutsideClick>
              <div className="company-name-selector">
                <Select
                  isMulti
                  isClearable
                  isSearchable
                  isLoading={isOptionsLoading}
                  options={companyOptions}
                  value={selectedCompanyNames}
                  onChange={(selectedOption) => {
                    setSelectedCompanyNames(selectedOption);
                    const companyIds = selectedOption
                      ? selectedOption.map((company) => company.value)
                      : [];
                    setIsFilterApplied(true);
                    filters.company_list = companyIds;
                    getRecordsCount(filters);
                    chargebackData(1, filters);
                    setPaged(1);
                    setCompanyFilterValue(companyIds);
                  }}
                  placeholder="Select Company Name"
                  styles={customStyles}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="type-selector">
                <Select
                  isMulti
                  isClearable
                  isSearchable
                  isLoading={isOptionsLoading}
                  options={chargebackTypeOptions}
                  value={selectedChargebackType}
                  onChange={(selectedOption) => {
                    setSelectedChargebackType(selectedOption);
                    const chargebackTypeIds = selectedOption
                      ? selectedOption.map((type) => type.value)
                      : [];
                    setIsFilterApplied(true);
                    filters.chargeback_type_list = chargebackTypeIds;
                    getRecordsCount(filters);
                    chargebackData(1, filters);
                    setPaged(1);
                    setTypeFilterValue(chargebackTypeIds);
                  }}
                  placeholder="Select Type"
                  styles={customStyles}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="refresh-button-container">
                <img
                  src="/images/refresh-icon.svg"
                  className="refresh-table"
                  alt="refresh"
                  onClick={() => {
                    setIsLoading(true);
                    setCompanyFilterValue([]);
                    setTypeFilterValue([]);
                    setStartDateFilterValue([]);
                    setEndDateFilterValue([]);
                    setChargebackUTRFilterValue([]);
                    setResolutionDateFilterValue([]);
                    setChargebackURNFilterValue([]);
                    setSearchValue("");
                    setSearchReadOnly(true);
                    setCurrentSearchScope(false);
                    setSelectedCompanyNames([]);
                    setSelectedDateRange([]);
                    setSelectedChargebackType([]);
                    setSelectedResolutionDate("");
                    setIsFilterApplied(false);
                    getRecordsCount();
                    chargebackData();
                    setPaged(1);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="chargeback-table-container">
            <table className="rwd-chargeback-table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="rwd-chargeback-header"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return column.hideHeader === false ? null : (
                        <>
                          <th
                            className={column.className}
                            {...column.getHeaderProps(
                              column.sort ? column.getSortByToggleProps() : ""
                            )}
                          >
                            <div style={{ textWrap: "nowrap" }}>
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              {!!column.sort ? (
                                <span className="header-text">
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <div className="sort-icon">
                                        <img src={sortDown} alt="sort down" />
                                      </div>
                                    ) : (
                                      <div className="sort-icon">
                                        <img src={sortUp} alt="sort up" />
                                      </div>
                                    )
                                  ) : (
                                    <div className="sort-icon">
                                      <img src={sort} alt="sort" />
                                    </div>
                                  )}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            {/* Render the columns filter UI */}
                            {/* <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div> */}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                ))}

                {columns[0]?.showColumnFilter
                  ? headerGroups.map((headerGroup) => (
                      <tr
                        className="rwd-chargeback-table-header"
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        {headerGroup.headers.map((column) => {
                          return column.hideHeader === false ? null : (
                            <th
                              className={"actions-header"}
                              {...column.getHeaderProps()}
                            >
                              {/* Render the columns filter UI */}
                              <div>
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))
                  : null}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {noDataMessage}
          </div>

          {/* pagination */}
          {page.length > 0 ? (
            <div className={classes.root}>
              <Pagination
                count={totalPages}
                page={paged}
                onChange={handleChange}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <TableLoader />
      )}
    </>
  );
}

export default Table;
