import React, { createContext, useState } from "react";

const IPManagementActionContext = createContext();

export const IPManagementProvider = ({ children }) => {
  //manage modal state
  const [openModal, setOpenModal] = useState(false);
  // type of card state
  const [cardType, setCardType] = useState("allCompanies");
  //allCompanies
  // editIps

  // edit company IPs state
  const [tableEditData, setTableEditData] = useState({
    id: "",
    edit: false,
    companyName: "",
  });
  const showModal = () => {
    setOpenModal(true);
  };

  // jumps to add new callback form card
  const editIps = () => {
    setCardType("editIps");
  };

  // jumps to add all callback table card
  const allCompanies = () => {
    setCardType("allCompanies");
    setTableEditData({
      id: "",
      edit: false,
      companyName: "",
      pluginCreated: false,
    });
  };

  return (
    <IPManagementActionContext.Provider
      value={{
        cardType,
        tableEditData,
        setTableEditData,
        editIps,
        allCompanies,
        showModal,
        openModal,
        setOpenModal,
      }}
    >
      {children}
    </IPManagementActionContext.Provider>
  );
};

export default IPManagementActionContext;
