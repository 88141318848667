import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { moduleUpdateObject } from "../../../utilities/moduleUpdateObject";
import { ckycConsumerGroups } from "../../../utilities/ckycConsumerGroups.js";
// api config imports
import axios, { all } from "axios";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/modules/customer_management/endpoint";
// context imports
import CustomerManagementActionContext from "../../../contexts/CustomerManagementActionContext";
import ManageCustomerContext from "../../../contexts/ManageCustomersContext";
// component imports
import UpdateCompanySuccessDialog from "../UpdateCompanySuccessDialog/UpdateCompanySuccessDialog.js";
import UpdateCompanyModulesSuccessDialog from "../UpdateCompanyModulesSuccessDialog/UpdateCompanyModulesSuccessDialog.js";
import { Modal } from "../EditCustomerData/Modal/Modal";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage.js";
import TextFieldInput from "../../../UI/TextField/TextFieldInput.js";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@material-ui/core";
// styles imports
import "./ModulesList.scss";
// Utils
import { randomUUID } from "../../../services/randomUUID.js";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ModulesList = ({
  subscribedModules,
  setSubscribedModules,
  allCompanyData,
}) => {
  const [value, setValue] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { tableEditData } = useContext(CustomerManagementActionContext);
  const { refreshModuleList, showModal, openModal } = useContext(
    ManageCustomerContext
  );

  const [dataToVerify, setDataToVerify] = useState({
    reference_id: "",
    consent: true,
    initiation_decentro_txn_id: "",
  });

  const [qrBase, setQrBase] = useState("");
  const [showTotp, setShowTotp] = useState(false);
  const [isLoading, setIsLoading] = useState("Loader");
  const [isSelectionChanged, setIsSelectionChanged] = useState(false);
  const [selectedModuleChanges, setSelectedModuleChanges] = useState([]);
  const [prepaidCreditsAccountId, setPrepaidCreditsAccountId] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [subscribedGroups, setSubscribedGroups] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({
    common_name: "",
    full_name: "",
    website: "",
    mobile_number: "",
    email_id: "",
    category: "",
    bd_poc: "",
    strategic_account: 0,
    address_line: "",
    city: "",
    pincode: "",
    district: "",
    state: "",
    country: "",
    gstin: "",
    partner_id: "",
  });

  const email = JSON.parse(localStorage.getItem("user")).email;
  const google_user_token = localStorage.getItem("google_user_token");

  const getCurrentCustomerID = () => {
    setCustomerId(allCompanyData[0].customer_id);
    // for (let i = 0; i < allCompanyData.length; i++) {
    //   if (tableEditData.id == allCompanyData[i].company_id) {
    //     setCustomerId(allCompanyData[i].customer_id);
    //   }
    // }
  };

  useEffect(() => {
    if (allCompanyData.length > 0) {
      getCurrentCustomerID();
    }
  }, [allCompanyData]);

  useEffect(() => {
    if (customerId) {
      getAvailableModulesByCompany();
      getCKYCConsumerGroups();
    }
  }, [customerId]);

  useEffect(() => {
    if (subscribedModules && subscribedModules.length > 0) {
      formik.setValues({
        selectedModules: subscribedModules.map((module) => module.code),
        prepaidAccountId: prepaidCreditsAccountId || "",
      });
    }
  }, [subscribedModules, prepaidCreditsAccountId]);

  // Fetch All company specific modules
  const getAvailableModulesByCompany = () => {
    setIsLoading("Loader");
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_COMPANY_MODULE_DETAILS.baseUrl +
        apiEndpointList.FETCH_COMPANY_MODULE_DETAILS.endpoint,
      {
        external_company_id: tableEditData.id,
        external_company_name: tableEditData.companyName,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.length === 0) {
          setIsLoading("noData");
        } else {
          response.data.forEach((item) => {
            if (item.company_id === tableEditData.id) {
              setSubscribedModules(item.modules);
              setPrepaidCreditsAccountId(item.prepaid_credits_account_id);
            }
          });
          setIsLoading("showModules");
        }
      })
      .catch((error) => {});
  };

  // Fetch CKYC consumer groups
  const getCKYCConsumerGroups = () => {
    APIConfig.API_Client.post(
      apiEndpointList.FETCH_COMPANY_CKYC_CONSUMER_GROUPS.baseUrl +
        apiEndpointList.FETCH_COMPANY_CKYC_CONSUMER_GROUPS.endpoint,
      {
        customer_id: customerId,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        const apiResponse = response.data;
        const subscribedGroups = apiResponse[0]?.ckyc_group.split(",") || []; // Extract subscribed groups from API response
        const updatedConsumerGroups = ckycConsumerGroups.map((group) => ({
          ...group,
          subscribed: subscribedGroups.includes(group.name), // Update subscription status
        }));
        setSubscribedGroups(updatedConsumerGroups);
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage msgtype="error" msg={"Something went wrong!"} />,
          document.getElementById("snackbar")
        );
      });
  };

  const updateHandler = () => {
    const companyInput = {
      source: "ADMIN",
      consent: true,
      reference_id: randomUUID(),
      google_user_token,
      email,
    };

    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.GENERATE_TOTP.baseUrl +
        apiEndpointList.GENERATE_TOTP.endpoint,
      companyInput
    )
      .then((res) => {
        setQrBase("");
        setIsLoading(false);
        if (res.status === 200) {
          if (res?.data?.data?.qrCode) {
            setQrBase(res.data.data.qrCode);
          } else {
            setQrBase("");
            setShowTotp(true);
          }
          setDataToVerify((prev) => ({
            ...prev,
            reference_id: companyInput.reference_id,
            initiation_decentro_txn_id: res.data.decentroTxnId,
          }));
        }
        showModal();
      })
      .catch((e) => {
        console.error(e);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={e?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
        setIsLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    selectedModules: Yup.array()
      .min(1, "Please select at least one module")
      .required("Please select at least one module"),
    prepaidAccountId: Yup.string().required("Prepaid Account ID is required"),
  });

  const formik = useFormik({
    initialValues: {
      selectedModules: [],
      prepaidAccountId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        await validationSchema.validate(formik.values, { abortEarly: false });
        formik.setErrors({});
        updateHandler();
      } catch (error) {
        const errors = {};
        error.inner.forEach((e) => {
          errors[e.path] = e.message;
        });
        formik.setErrors(errors);
      }
    },
  });

  const updateCompanyModules = () => {
    let payload = {};
    if (isSelectionChanged) {
      // If selection is changed, update prepaid_credits_account_id for each module
      payload = {
        company_id: tableEditData.id,
        module_details: selectedModuleChanges.map(({ selected, ...rest }) => ({
          ...rest,
          prepaid_credits_account_id: parseInt(prepaidCreditsAccountId),
        })),
      };
      APIConfig.API_Client.post(
        apiEndpointList.UPDATE_COMPANY_MODULE_DETAILS.baseUrl +
          apiEndpointList.UPDATE_COMPANY_MODULE_DETAILS.endpoint,
        payload,
        { cancelToken: source.token }
      )
        .then((response) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={response.data.message || "Modules updated successfully"}
            />,
            document.getElementById("snackbar")
          );
          ReactDOM.render(
            <UpdateCompanyModulesSuccessDialog data={payload} type="modules" />,
            document.getElementById("add-customer-components")
          );
        })
        .catch((error) => {
          console.error(error);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong !"}
            />,
            document.getElementById("snackbar")
          );
        });
    } else {
      // Get the modules that are subscribed (checked and disabled)
      const subscribedModulesToUpdate = subscribedModules.filter((module) =>
        formik.values.selectedModules.includes(module.code)
      );

      // Filter out the subscribed modules that are not present in moduleUpdateObject
      const subscribedModuleDetails = subscribedModulesToUpdate.filter(
        (module) => moduleUpdateObject.some((obj) => obj.code === module.code)
      );

      payload = {
        company_id: tableEditData.id,
        module_details: subscribedModuleDetails.map((module) => ({
          code: module.code,
          prepaid_credits_account_id: parseInt(formik.values.prepaidAccountId),
        })),
      };
      APIConfig.API_Client.put(
        apiEndpointList.UPDATE_COMPANY_MODULE_DETAILS.baseUrl +
          apiEndpointList.UPDATE_COMPANY_MODULE_DETAILS.endpoint,
        payload,
        { cancelToken: source.token }
      )
        .then((response) => {
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={response.data.message || "Modules updated successfully"}
            />,
            document.getElementById("snackbar")
          );
          ReactDOM.render(
            <UpdateCompanyModulesSuccessDialog data={payload} type="prepaid" />,
            document.getElementById("add-customer-components")
          );
        })
        .catch((error) => {
          console.error(error);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="Error"
              msg={error?.response?.data?.message || "Something went wrong !"}
            />,
            document.getElementById("snackbar")
          );
        });
    }
  };

  const callUpdateApi = (updateType) => {
    if (updateType == "module") {
      updateCompanyModules();
    } else if (updateType == "company_details") {
      updateCompanyDetails(formikCompanyDetails.values);
    }
  };

  const handleSubscribe = (group) => {
    updateCKYCSubscription(group);
  };

  const updateCKYCSubscription = (groupName) => {
    const payload = {
      company_id: tableEditData.id,
      consumer_group: groupName,
    };

    APIConfig.API_Client.post(
      apiEndpointList.UPDATE_CKYC_CONSUMER_GROUP.baseUrl +
        apiEndpointList.UPDATE_CKYC_CONSUMER_GROUP.endpoint,
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        // Upon successful API response update UI
        const updatedGroups = subscribedGroups.map((group) => {
          if (group.name === groupName) {
            return { ...group, subscribed: true };
          }
          return group;
        });
        setSubscribedGroups(updatedGroups);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={`Successfully subscribed to ${groupName}`}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        console.error(error);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={`Subscription to ${groupName} failed!`}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  const handleChangePrepaidAccountId = (e) => {
    setPrepaidCreditsAccountId(e.target.value);
  };

  const handleChange = (e, item) => {
    formik.handleChange(e);

    const currentSelection = formik.values.selectedModules || [];

    const updatedSelection = e.target.checked
      ? [...currentSelection, item.code]
      : currentSelection.filter((code) => code !== item.code);

    const isChecked = e.target.checked;

    const secret = randomUUID();

    const moduleChange = {
      code: item.code,
      secret: secret,
      prepaid_credits_account_id: parseInt(prepaidCreditsAccountId),
      selected: isChecked,
    };

    if (isChecked) {
      const updatedModuleChanges = [...selectedModuleChanges, moduleChange];
      setSelectedModuleChanges(updatedModuleChanges);
    } else {
      const updatedModuleChanges = selectedModuleChanges.filter(
        (module) => module.code !== item.code
      );
      setSelectedModuleChanges(updatedModuleChanges);
    }

    const allCodesPresent = updatedSelection.every((moduleChange) =>
      updatedSelection.includes(moduleChange.code)
    );

    const isSelectionChanged = !allCodesPresent;

    setIsSelectionChanged(isSelectionChanged);
  };

  useEffect(() => {
    // Fetch company details data and prefill the form
    const fetchCompanyDetails = async () => {
      try {
        const response = await APIConfig.API_Client.post(
          apiEndpointList.FETCH_COMPANY_DETAILS_SALAD.baseUrl +
            apiEndpointList.FETCH_COMPANY_DETAILS_SALAD.endpoint,
          { company_id: parseInt(tableEditData.id) }
        );
        if (response.data.length === 0) {
          formikCompanyDetails.setValues({
            common_name: "",
            full_name: "",
            website: "",
            mobile_number: "",
            email_id: "",
            category: "",
            bd_poc: "",
            strategic_account: false,
            address_line: "",
            city: "",
            pincode: "",
            district: "",
            state: "",
            country: "",
            gstin: "",
            partner_id: "",
          });
        } else {
          setCompanyDetails(response.data[0]);
          formikCompanyDetails.setValues(response.data[0]);
        }
      } catch (error) {
        console.error(error || "Something went wrong!");
      }
    };

    if (process.env.REACT_APP_ENV_NAME == "staging") {
      if (value === "2") {
        fetchCompanyDetails();
      }
    } else {
      if (value === "3") {
        fetchCompanyDetails();
      }
    }
  }, [value]);

  const validationSchemaCompanyDetails = Yup.object().shape({
    common_name: Yup.string().required("Company Name is required"),
    full_name: Yup.string().required("Registered Name is required"),
    email_id: Yup.string().email("Invalid email").required("Email is required"),
    website: Yup.string().nullable(),
    mobile_number: Yup.string().required("Mobile Number is required"),
    address_line: Yup.string().required("Address Line is required"),
    city: Yup.string().required("City is required"),
    pincode: Yup.string().required("Pincode is required"),
    district: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    gstin: Yup.string().required("GST Number is required"),
    category: Yup.string().nullable(),
    bd_poc: Yup.string().nullable(),
    strategic_account: Yup.boolean().nullable(),
    partner_id: Yup.number()
      .integer("Partner ID must be an integer")
      .nullable(),
  });

  const formikCompanyDetails = useFormik({
    initialValues: {
      common_name: companyDetails?.common_name || "",
      full_name: companyDetails?.full_name || "",
      website: companyDetails?.website || "",
      mobile_number: companyDetails?.mobile_number || "",
      email_id: companyDetails?.email_id || "",
      category: companyDetails?.category || "",
      bd_poc: companyDetails?.bd_poc || "",
      strategic_account: companyDetails?.strategic_account === 1,
      address_line: companyDetails?.address_line || "",
      city: companyDetails?.city || "",
      pincode: companyDetails?.pincode || "",
      district: companyDetails?.district || "",
      state: companyDetails?.state || "",
      country: companyDetails?.country || "",
      gstin: companyDetails?.gstin || "",
      partner_id: companyDetails.partner_id || "",
    },
    validationSchema: validationSchemaCompanyDetails,
    onSubmit: (values) => {
      updateHandler();
    },
  });

  const updateCompanyDetails = async (formData) => {
    try {
      const {
        common_name,
        full_name,
        email_id,
        mobile_number,
        website,
        address_line,
        city,
        pincode,
        district,
        state,
        country,
        gstin,
        bd_poc,
        strategic_account,
        category,
        partner_id,
      } = formData;

      const partnerIdInt = partner_id ? parseInt(partner_id) : "";
      const payload = {
        common_name,
        full_name,
        email_id,
        mobile_number,
        website: website || "",
        address: {
          address_line: address_line || "",
          city: city || "",
          pincode: pincode || "",
          district: district || "",
          state: state || "",
          country: country || "",
        },
        gstin: gstin || "",
        bd_poc: bd_poc || "",
        strategic_account: Boolean(strategic_account) || false,
        category: category !== null ? category : "",
        partner_id: partnerIdInt,
      };

      const response = await APIConfig.API_Client.put(
        apiEndpointList.UPDATE_COMPANY_DETAILS.baseUrl +
          apiEndpointList.UPDATE_COMPANY_DETAILS.endpoint +
          `/${parseInt(tableEditData.id)}`,
        payload
      );
      ReactDOM.render(
        <SnackbarMessage
          msgtype="success"
          msg={response.data.message || "Company details updated successfully"}
        />,
        document.getElementById("snackbar")
      );
      ReactDOM.render(
        <UpdateCompanySuccessDialog data={response.data} />,
        document.getElementById("add-customer-components")
      );
    } catch (error) {
      console.error(error);
      ReactDOM.render(
        <SnackbarMessage
          msgtype="Error"
          msg={error?.response?.data?.message || "Something went wrong !"}
        />,
        document.getElementById("snackbar")
      );
    }
  };

  if (isLoading === "Loader") {
    return (
      <div className="customers-loader-container">
        <img src="/images/loader.gif" alt="loader" />
      </div>
    );
  }

  if (isLoading === "noData") {
    return <h3 className="no-modules-heading">No Modules Found</h3>;
  }

  return (
    <div className="modules-scrollable-container update-customer-section">
      {process.env.REACT_APP_ENV_NAME == "staging" ? (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="COMPANY MODULES" value="1" className="tab" />

              <Tab label="COMPANY DETAILS" value="2" className="tab" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <form onSubmit={formik.handleSubmit}>
              <div className="edit-card-list-container">
                {moduleUpdateObject.map((item, index) => (
                  <div key={index} className="edit-customers-card">
                    <label>
                      <input
                        type="checkbox"
                        name="selectedModules"
                        value={item.code}
                        checked={formik.values.selectedModules.includes(
                          item.code
                        )}
                        onChange={(e) => handleChange(e, item)}
                        disabled={subscribedModules.some(
                          (module) => module.code === item.code
                        )}
                      />
                      {item.name}
                    </label>
                    {formik.errors.selectedModules &&
                      formik.touched.selectedModules && (
                        <div className="error-message">
                          {formik.errors.selectedModules}
                        </div>
                      )}
                  </div>
                ))}
              </div>
              <div className="edit-card-list-container">
                <div
                  className="edit-customers-card accordion-text-input"
                  style={{
                    background: " #FFF",
                    borderRadius: "12px",
                  }}
                >
                  <label htmlFor="prepaidAccountId">Prepaid Account ID</label>
                  <div className={`edit-input-container`}>
                    <input
                      id="prepaidAccountId"
                      name="prepaidAccountId"
                      type="text"
                      placeholder="Enter Prepaid Account ID"
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleChangePrepaidAccountId(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.prepaidAccountId}
                      className={
                        formik.touched.prepaidAccountId &&
                        formik.errors.prepaidAccountId
                          ? "error-input"
                          : ""
                      }
                    />
                  </div>
                  {formik.errors.prepaidAccountId &&
                    formik.touched.prepaidAccountId && (
                      <div className="error-message">
                        {formik.errors.prepaidAccountId}
                      </div>
                    )}
                </div>
                <div className="update-btn">
                  <button type="submit">Update</button>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <form onSubmit={formikCompanyDetails.handleSubmit}>
              <div className="ui-form-details">
                <div className="ui-form-header">
                  <h2 className="ui-form-title">Update Company Details</h2>
                </div>
                <div className="ui-form-content">
                  <div className="form-inputs-section">
                    <div className="ui-form-content">
                      <div className="ui-form-inputs-section">
                        <TextFieldInput
                          id="common_name"
                          name="common_name"
                          onChange={formikCompanyDetails.handleChange}
                          onBlur={formikCompanyDetails.handleBlur}
                          value={formikCompanyDetails.values.common_name}
                          touched={formikCompanyDetails.touched.common_name}
                          error={formikCompanyDetails.errors.common_name}
                          placeholder="Enter Company Name"
                          label="Company Name"
                          required={true}
                          disabled={false}
                        />
                        <TextFieldInput
                          id="full_name"
                          name="full_name"
                          onChange={formikCompanyDetails.handleChange}
                          onBlur={formikCompanyDetails.handleBlur}
                          value={formikCompanyDetails.values.full_name}
                          touched={formikCompanyDetails.touched.full_name}
                          error={formikCompanyDetails.errors.full_name}
                          placeholder="Enter Registered Name"
                          label="Registered Name"
                          required={true}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="email_id"
                      name="email_id"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.email_id}
                      touched={formikCompanyDetails.touched.email_id}
                      error={formikCompanyDetails.errors.email_id}
                      placeholder="Enter Official Email"
                      label="Official Email"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="website"
                      name="website"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.website}
                      touched={formikCompanyDetails.touched.website}
                      error={formikCompanyDetails.errors.website}
                      placeholder="Enter Website"
                      label="Website"
                      required={false}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="mobile_number"
                      name="mobile_number"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.mobile_number}
                      touched={formikCompanyDetails.touched.mobile_number}
                      error={formikCompanyDetails.errors.mobile_number}
                      placeholder="Enter Mobile Number"
                      label="Mobile Number"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="address_line"
                      name="address_line"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.address_line}
                      touched={formikCompanyDetails.touched.address_line}
                      error={formikCompanyDetails.errors.address_line}
                      placeholder="Enter Address Line"
                      label="Address Line"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="city"
                      name="city"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.city}
                      touched={formikCompanyDetails.touched.city}
                      error={formikCompanyDetails.errors.city}
                      placeholder="Enter City"
                      label="City"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="pincode"
                      name="pincode"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.pincode}
                      touched={formikCompanyDetails.touched.pincode}
                      error={formikCompanyDetails.errors.pincode}
                      placeholder="Enter Pincode"
                      label="Pincode"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="district"
                      name="district"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.district}
                      touched={formikCompanyDetails.touched.district}
                      error={formikCompanyDetails.errors.district}
                      placeholder="Enter District"
                      label="District"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="state"
                      name="state"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.state}
                      touched={formikCompanyDetails.touched.state}
                      error={formikCompanyDetails.errors.state}
                      placeholder="Enter State"
                      label="State"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="country"
                      name="country"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.country}
                      touched={formikCompanyDetails.touched.country}
                      error={formikCompanyDetails.errors.country}
                      placeholder="Enter Country"
                      label="Country"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="gstin"
                      name="gstin"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.gstin}
                      touched={formikCompanyDetails.touched.gstin}
                      error={formikCompanyDetails.errors.gstin}
                      placeholder="Enter GST Number"
                      label="GST Number"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="category"
                      name="category"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.category}
                      touched={formikCompanyDetails.touched.category}
                      error={formikCompanyDetails.errors.category}
                      placeholder="Enter Category"
                      label="Category"
                      required={false}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="bd_poc"
                      name="bd_poc"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.bd_poc}
                      touched={formikCompanyDetails.touched.bd_poc}
                      error={formikCompanyDetails.errors.bd_poc}
                      placeholder="Enter BD POC"
                      label="BD POC"
                      required={false}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="partner_id"
                      name="partner_id"
                      onChange={formikCompanyDetails.handleChange}
                      value={formikCompanyDetails.values.partner_id}
                      touched={formikCompanyDetails.touched.partner_id}
                      error={formikCompanyDetails.errors.partner_id}
                      label="Partner ID"
                      placeholder="Enter Partner ID"
                      required={false}
                      disabled={false}
                    />
                  </div>
                  <div className="edit-customers-card strategic-account-checkbox">
                    <label htmlFor="strategic_account">
                      Strategic Account{" "}
                      <input
                        type="checkbox"
                        id="strategic_account"
                        checked={formikCompanyDetails.values.strategic_account}
                        onChange={(e) =>
                          formikCompanyDetails.setFieldValue(
                            "strategic_account",
                            e.target.checked
                          )
                        }
                      />
                    </label>
                  </div>

                  <div className="update-btn">
                    <button type="submit">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabContext>
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="COMPANY MODULES" value="1" className="tab" />
              <Tab label="CONSUMER GROUP" value="2" className="tab" />
              <Tab label="COMPANY DETAILS" value="3" className="tab" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <form onSubmit={formik.handleSubmit}>
              <div className="edit-card-list-container">
                {moduleUpdateObject.map((item, index) => (
                  <div key={index} className="edit-customers-card">
                    <label>
                      <input
                        type="checkbox"
                        name="selectedModules"
                        value={item.code}
                        checked={formik.values.selectedModules.includes(
                          item.code
                        )}
                        onChange={(e) => handleChange(e, item)}
                        disabled={subscribedModules.some(
                          (module) => module.code === item.code
                        )}
                      />
                      {item.name}
                    </label>
                    {formik.errors.selectedModules &&
                      formik.touched.selectedModules && (
                        <div className="error-message">
                          {formik.errors.selectedModules}
                        </div>
                      )}
                  </div>
                ))}
              </div>
              <div className="edit-card-list-container">
                <div
                  className="edit-customers-card accordion-text-input"
                  style={{
                    background: " #FFF",
                    borderRadius: "12px",
                  }}
                >
                  <label htmlFor="prepaidAccountId">Prepaid Account ID</label>
                  <div className={`edit-input-container`}>
                    <input
                      id="prepaidAccountId"
                      name="prepaidAccountId"
                      type="text"
                      placeholder="Enter Prepaid Account ID"
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleChangePrepaidAccountId(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.prepaidAccountId}
                      className={
                        formik.touched.prepaidAccountId &&
                        formik.errors.prepaidAccountId
                          ? "error-input"
                          : ""
                      }
                    />
                  </div>
                  {formik.errors.prepaidAccountId &&
                    formik.touched.prepaidAccountId && (
                      <div className="error-message">
                        {formik.errors.prepaidAccountId}
                      </div>
                    )}
                </div>
                <div className="update-btn">
                  <button type="submit">Update</button>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value="2">
            {" "}
            <div className="edit-card-list-container table-action-container">
              <div
                className="edit-customers-card accordion-text-input"
                style={{
                  background: " #FFF",
                  borderRadius: "12px",
                  marginTop: "0",
                }}
              >
                <div className="user-input">
                  <div className="user-input">
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Group</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subscribedGroups.map((group) => (
                            <tr key={group.id}>
                              <td>{group.name}</td>
                              <td
                                className={
                                  group.subscribed
                                    ? "subscribed"
                                    : "unsubscribed"
                                }
                              >
                                {group.subscribed
                                  ? "Subscribed"
                                  : "Unsubscribed"}
                              </td>
                              <td>
                                {!group.subscribed && (
                                  <span
                                    className="subscribe-btn-txt"
                                    onClick={() => handleSubscribe(group.name)}
                                  >
                                    Subscribe
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            {" "}
            <form onSubmit={formikCompanyDetails.handleSubmit}>
              <div className="ui-form-details">
                <div className="ui-form-header">
                  <h2 className="ui-form-title">Update Company Details</h2>
                </div>
                <div className="ui-form-content">
                  <div className="form-inputs-section">
                    <div className="ui-form-content">
                      <div className="ui-form-inputs-section">
                        <TextFieldInput
                          id="common_name"
                          name="common_name"
                          onChange={formikCompanyDetails.handleChange}
                          onBlur={formikCompanyDetails.handleBlur}
                          value={formikCompanyDetails.values.common_name}
                          touched={formikCompanyDetails.touched.common_name}
                          error={formikCompanyDetails.errors.common_name}
                          placeholder="Enter Company Name"
                          label="Company Name"
                          required={true}
                          disabled={false}
                        />
                        <TextFieldInput
                          id="full_name"
                          name="full_name"
                          onChange={formikCompanyDetails.handleChange}
                          onBlur={formikCompanyDetails.handleBlur}
                          value={formikCompanyDetails.values.full_name}
                          touched={formikCompanyDetails.touched.full_name}
                          error={formikCompanyDetails.errors.full_name}
                          placeholder="Enter Registered Name"
                          label="Registered Name"
                          required={true}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="email_id"
                      name="email_id"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.email_id}
                      touched={formikCompanyDetails.touched.email_id}
                      error={formikCompanyDetails.errors.email_id}
                      placeholder="Enter Official Email"
                      label="Official Email"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="website"
                      name="website"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.website}
                      touched={formikCompanyDetails.touched.website}
                      error={formikCompanyDetails.errors.website}
                      placeholder="Enter Website"
                      label="Website"
                      required={false}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="mobile_number"
                      name="mobile_number"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.mobile_number}
                      touched={formikCompanyDetails.touched.mobile_number}
                      error={formikCompanyDetails.errors.mobile_number}
                      placeholder="Enter Mobile Number"
                      label="Mobile Number"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="address_line"
                      name="address_line"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.address_line}
                      touched={formikCompanyDetails.touched.address_line}
                      error={formikCompanyDetails.errors.address_line}
                      placeholder="Enter Address Line"
                      label="Address Line"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="city"
                      name="city"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.city}
                      touched={formikCompanyDetails.touched.city}
                      error={formikCompanyDetails.errors.city}
                      placeholder="Enter City"
                      label="City"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="pincode"
                      name="pincode"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.pincode}
                      touched={formikCompanyDetails.touched.pincode}
                      error={formikCompanyDetails.errors.pincode}
                      placeholder="Enter Pincode"
                      label="Pincode"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="district"
                      name="district"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.district}
                      touched={formikCompanyDetails.touched.district}
                      error={formikCompanyDetails.errors.district}
                      placeholder="Enter District"
                      label="District"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="state"
                      name="state"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.state}
                      touched={formikCompanyDetails.touched.state}
                      error={formikCompanyDetails.errors.state}
                      placeholder="Enter State"
                      label="State"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="country"
                      name="country"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.country}
                      touched={formikCompanyDetails.touched.country}
                      error={formikCompanyDetails.errors.country}
                      placeholder="Enter Country"
                      label="Country"
                      required={true}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="gstin"
                      name="gstin"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.gstin}
                      touched={formikCompanyDetails.touched.gstin}
                      error={formikCompanyDetails.errors.gstin}
                      placeholder="Enter GST Number"
                      label="GST Number"
                      required={true}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="category"
                      name="category"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.category}
                      touched={formikCompanyDetails.touched.category}
                      error={formikCompanyDetails.errors.category}
                      placeholder="Enter Category"
                      label="Category"
                      required={false}
                      disabled={false}
                    />
                    <TextFieldInput
                      id="bd_poc"
                      name="bd_poc"
                      onChange={formikCompanyDetails.handleChange}
                      onBlur={formikCompanyDetails.handleBlur}
                      value={formikCompanyDetails.values.bd_poc}
                      touched={formikCompanyDetails.touched.bd_poc}
                      error={formikCompanyDetails.errors.bd_poc}
                      placeholder="Enter BD POC"
                      label="BD POC"
                      required={false}
                      disabled={false}
                    />
                  </div>
                  <div className="ui-form-inputs-section">
                    <TextFieldInput
                      id="partner_id"
                      name="partner_id"
                      onChange={formikCompanyDetails.handleChange}
                      value={formikCompanyDetails.values.partner_id}
                      touched={formikCompanyDetails.touched.partner_id}
                      error={formikCompanyDetails.errors.partner_id}
                      label="Partner ID"
                      placeholder="Enter Partner ID"
                      required={false}
                      disabled={false}
                    />
                  </div>
                  <div className="edit-customers-card strategic-account-checkbox">
                    <label htmlFor="strategic_account">
                      Strategic Account{" "}
                      <input
                        type="checkbox"
                        id="strategic_account"
                        checked={formikCompanyDetails.values.strategic_account}
                        onChange={(e) =>
                          formikCompanyDetails.setFieldValue(
                            "strategic_account",
                            e.target.checked
                          )
                        }
                      />
                    </label>
                  </div>

                  <div className="update-btn">
                    <button type="submit">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabContext>
      )}

      {openModal ? (
        <Modal
          showTotp={showTotp}
          setShowTotp={setShowTotp}
          qrBase={qrBase}
          dataToVerify={dataToVerify}
          callUpdateApi={callUpdateApi}
          updateType={
            value == "1" ? "module" : value == "3" ? "company_details" : ""
          }
        />
      ) : null}
    </div>
  );
};

export default ModulesList;
