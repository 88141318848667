import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";

//formik-import
import { useFormik } from "formik";
import * as Yup from "yup";

//API-imports
import { APIConfig } from "../../../../services/apiConfiguration";
import apiEndpointList from "../../../../config/modules/customer_management/endpoint";
import axios from "axios";

//component-import
import TextFieldInput from "../../../../UI/TextField/TextFieldInput";
import SnackbarMessage from "../../../SnackbarMessage/SnackbarMessage";
import CBADetails from "./CBADetails/CBADetails";

//styles-import
import "../../../../UI/TextField/TextField.scss";

//utility-import
import { scrollIntoView } from "../../../../utilities/scrollIntoView";

const initialValues = {
  cba_id: "",
};

const validationSchema = Yup.object({
  cba_id: Yup.number().positive().min(0).required("CBA ID is required"),
});

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const SingleCBARecord = () => {
  const [Details, setDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const responseRef = useRef(null);

  const handleClick = () => {
    setShowDetails(false);
    setDetails(null);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, action) => {
      const payloadData = {
        cba_id: values.cba_id.toString(),
      };

      action.setSubmitting(true);
      APIConfig.API_Client.post(
        apiEndpointList.GET_CBA_RECORD.baseUrl +
          apiEndpointList.GET_CBA_RECORD.endpoint,
        payloadData,
        { cancelToken: source.token }
      )
        .then((response) => {
          if (response?.data?.length) {
            setDetails(response.data);
            setShowDetails(true);
            action.resetForm();
          } else {
            setShowDetails(false);
            setDetails(null);
            ReactDOM.render(
              <SnackbarMessage msgtype="error" msg="No CBA Record exists" />,
              document.getElementById("snackbar")
            );
          }
        })
        .finally(() => {
          action.setSubmitting(false);
          if (responseRef.current) {
            scrollIntoView(responseRef.current, { behavior: "smooth" });
          }
        });
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <h2 className="ui-form-title">Enter CBA ID to continue</h2>
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="cba_id"
                name="cba_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cba_id}
                touched={formik.touched.cba_id}
                error={formik.errors.cba_id}
                placeholder="Enter CBA ID"
                label="CBA ID"
                required={true}
                isToolTip={true ? "Consumer Bank Account ID" : null}
              />
            </div>
            <div className="account-details-submit ui-button-container">
              <button
                className={`account-details-submit ${
                  formik.isValid && formik.dirty && !formik.isSubmitting
                    ? "active"
                    : ""
                }`}
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </form>
      {showDetails && (
        <div ref={responseRef}>
          <CBADetails data={Details} onClick={handleClick} />
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleCBARecord;
