import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// api config imports
import { APIConfig } from "../services/apiConfiguration";
import axios from "axios";
// component imports
import SnackbarMessage from "../components/SnackbarMessage/SnackbarMessage";
// context imports
import apiEndpointList from "../config/modules/customer_management/endpoint";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const ManageCustomerContext = createContext();
export const ManageCustomerProvider = ({ children }) => {
  // refresh available subscribed module list
  const [refreshModuleList, setRefreshModuleList] = useState(false);
  // credit edit Type state
  // const [editType, setEditType] = useState({
  //   displayName: "Add Credits",
  //   function: "add",
  // });
  //manage modal state
  const [openModal, setOpenModal] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };

  // reset credit edit dropdown state
  // const resetCreditDropdown = () => {
  //   setEditType({
  //     displayName: "Add Credits",
  //     function: "add",
  //   });
  // };

  // credits put(edit) request : @ edit
  const editCreditsApi = (payload) => {
    APIConfig.API_Client.put(
      apiEndpointList.COMPANY_API_METER.baseUrl +
        apiEndpointList.COMPANY_API_METER.endpoint,
      // APIConfig.BASE_URL + "/v2/internal/company_api_meter",
      payload,
      { cancelToken: source.token }
    )
      .then((response) => {
        setRefreshModuleList(!refreshModuleList);
        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={"Credits Updated successfully"}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  return (
    <ManageCustomerContext.Provider
      value={{
        editCreditsApi,
        refreshModuleList,
        setOpenModal,
        showModal,
        openModal,
      }}
    >
      {children}
    </ManageCustomerContext.Provider>
  );
};

export default ManageCustomerContext;
