import React from "react";

//component-import
import SingleInitiateReversal from "./InitiateReversal/SingleInitiateReversal";
import BulkInitiateReversal from "./InitiateReversal/BulkInitiateReversal";
import TabComponentWrapper from "../../../UI/TabComponentWrapper/TabComponentWrapper";

const MarkAsFailure = () => {
  return (
    <React.Fragment>
      <TabComponentWrapper
        heading="Mark As Failure"
        tabs={[
          {
            className: "single-reversal",
            label: "Single",
            value: "single",
            component: <SingleInitiateReversal />,
          },
          {
            className: "bulk-reversal",
            label: "Bulk",
            value: "bulk",
            component: <BulkInitiateReversal />,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default MarkAsFailure;
