import React, { useContext, useState } from "react";
// context imports
import CreditManagementContext from "../../../contexts/CreditManagementContext";
// styles imports
import "./EditCreditTypeDropdown.scss";

const EditCreditTypeDropdown = ({
  moduleDisplayName,
  subscribedModules,
  setSubscribedModules,
}) => {
  // ^ context
  // const { setEditType, editType } = useContext(CreditManagementContext);

  const [selected, setSelected] = useState({
    displayName: "Add Credits",
    function: "add",
  });
  const [isActive, setIsActive] = useState(false);

  // static values
  const searchTypeList = [
    {
      displayName: "Add Credits",
      function: "add",
    },
    {
      displayName: "Subtract Credits",
      function: "subtract",
    },
  ];

  return (
    <div className="edit-credit-type">
      <div
        className={`dropdown-btn text-dark
        ${isActive && " border-radius__invert"}`}
        onClick={(e) => setIsActive(!isActive)}
      >
        {selected.displayName}
        {selected.length !== 1 && (
          <span>
            <DownArrow isActive={isActive} />
          </span>
        )}
      </div>
      {isActive && (
        <div className="credit-type-content">
          {searchTypeList
            .filter((option) => option.function !== selected.function)
            .map((option) => (
              <div key={option.displayName}>
                {option.name !== selected.displayName && (
                  <div
                    key={option.id}
                    onClick={(e) => {
                      setSelected(option);
                      setIsActive(false);
                      // setEditType(option);

                      // Adding a key based on function operation
                      setSubscribedModules(
                        subscribedModules.map((detail) => {
                          return detail.displayName === moduleDisplayName
                            ? { ...detail, operationFunction: option.function }
                            : detail;
                        })
                      );
                    }}
                    className="dropdown-item"
                  >
                    {option.displayName}
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

function DownArrow({ isActive }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-6 w-6 dropdown-arrow ${
        isActive && "dropdown-arrow__rotate"
      }`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default EditCreditTypeDropdown;
